<template>
  <!-- <div class="p-fileupload p-fileupload-advanced p-component" v-if="isAdvanced"> -->

  <div
    style="border-radius: 3px"
    class="control-button-wide no-caret cutted-b-right p-d-flex p-flex-row dynamic-file-upload-button"
  >
    <form
      id="csv-form"
      @submit.prevent="sendFile"
      enctype="multipart/form-data"
      :disabled="disabledInput || disableMenuControls"
    >
      <span
        :class="advancedChooseButtonClass"
        @click="choose"
        @keydown.enter="choose"
        @focus="onFocus"
        @blur="onBlur"
        v-ripple
        tabindex="0"
        :disabled="disabledInput"
      >
        <input
          :disabled="disabledInput"
          ref="fileInput"
          type="file"
          @change="selectFile"
        />

        <span
          style="margin-top: 2px"
          :class="
            uploading
              ? 'p-button-icon p-button-icon-left'
              : 'p-button-icon p-button-icon-left pi pi-fw pi-plus'
          "
        ></span>
        <span
          v-if="uploading"
          class="p-d-flex p-ai-center"
          style="color: white; font-size: 15px;"
        >
          {{ t("uploading", {}, { locale: this.localLanguage }) }}:
          <b class="p-ml-2" style="color: dodgerblue"> {{ progress }}%</b>
        </span>
        <span v-if="!uploading" style="margin-top: 1px" class="p-button-label"
          >{{ t("import_button_label", {}, { locale: this.localLanguage }) }} {{ fileType }} </span
        >
      </span>

      <div style="display: none">
        <button :disabled="disabledInput" id="submit-button">
          {{ t("send", {}, { locale: this.localLanguage }) }}
        </button>
      </div>
    </form>
  </div>

  <div
    v-if="uploading && apiServiceCommand == 'advancedFile'"
    class="animated-upload p-d-flex p-ml-4 p-ai-center"
  >
    <span class="p-d-flex p-ai-center" style="color: grey; font-size: 15px;">
      {{ t("uploading", {}, { locale: this.localLanguage }) }}:
      <b class="p-ml-2" style="color: dodgerblue;"> {{ progress }}%</b>
    </span>
  </div>
</template>

<script>
import Ripple from "primevue/ripple";
import axios from "axios";

import APIService from "@/service/APIService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  props: {
    apiServiceCommand: { type: String },
    fileType: { type: String },
    disabledInput: { type: Boolean },
    disableMenuControls: {type: Boolean}
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  emits: [
    "processing",
    "fileCountAndOptimizationsUpdate",
    "success",
    "failure",
    "filename",
    "openConfirmation",
    "returnMessage"
  ],
  data() {
    return {
      localLanguage: "de",
      shiftTimesIntoTheFutureText: this.t(
        "shift_times_into_the_future",
        {},
        { locale: "de" }
      ),

      file: "",
      message: "",
      error: false,
      uploading: false,
      processing: false,
      progress: 0
    };
  },
  beforeUpdate() {
    this.configureLanguage();
  },
  mounted() {
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });

    this.configureLanguage();
  },
  unmounted() {
    this.emitter.off("LanguageStateBus");
    this.emitter.off("confirmShiftTime");
    this.emitter.off("cancelShiftTime");
  },
  methods: {
    configureLanguage() {
      this.localLanguage =
        localStorage.getItem("language") == null
          ? this.$store.state.language
          : localStorage.getItem("language");

      this.shiftTimesIntoTheFutureText = this.t(
        "shift_times_into_the_future",
        {},
        { locale: this.localLanguage }
      );
    },
    selectFile() {
      this.uploading = true;
      let file = this.$refs.fileInput.files[0];
      const allowedTypes = ["text/csv"];
      const MAX_SIZE = 500000;
      const tooLarge = file.size > MAX_SIZE;
      const fileTypeTermination = file.name.split(".").pop();
      if (
        (allowedTypes.includes(file.type) || fileTypeTermination === "csv") &&
        !tooLarge
      ) {
        this.file = file;
        this.error = false;
        this.message = "";
        document.getElementById("submit-button").click();
      } else {
        this.error = true;
        this.uploading = false;
        // this.message = tooLarge
          // ? `Too large. Max size is ${MAX_SIZE / 1000}Kb`
          // : "Only CSV files are allowed";
        this.message = tooLarge 
        ? "csv_file_is_too_big" 
        : "only_csv_files_allowed"  
        this.$emit("failure", this.message);  
      }
    },
    apiAdvancedFile(formData) {
      try {
        this.uploading = true;
        axios.defaults.withCredentials = true;
        APIService.api
          .uploadFileAndProcess(formData, this.onloadFunction)
          .then(() => {
            this.$emit("processing", false);
            this.$emit("fileCountAndOptimizationsUpdate");
            this.$toast.add({
              severity: "success",
              summary: this.message,
              detail: "File is ready for use",
              life: 2500
            });
          });
      } catch (error) {
        this.$emit("failure");
        this.message = "Something went wrong";
        this.error = true;
        this.uploading = false;

        this.$toast.add({
          severity: "error",
          summary: this.message,
          detail: "File was not uploaded",
          life: 3500
        });
        document.getElementById("csv-form").reset();
      }
    },
    apiJobsFile(formData, filename) {
      let error = false;
      this.uploading = true;
      axios.defaults.withCredentials = true;
      APIService.api
        .uploadJobsAndProcess(formData, this.onloadFunction)
        .catch(err => {
          this.$emit("failure", err.response.data.message);
          this.uploading = false;
          error = true;
          document.getElementById("csv-form").reset();
        })
        .then(() => {
          if (!error) {
            this.sleep().then(() => {
              this.$emit("processing", false);
              this.$emit("success");
              this.$emit("filename", filename, "jobs");
            });
          }
        });
    },
    apiShiftsFile(formData, filename) {
      let error = false;
      this.uploading = true;
      axios.defaults.withCredentials = true;
      APIService.api
        .uploadShiftsAndProcess(formData, this.onloadFunction)
        .catch(err => {
          this.$emit("failure", err.response.data.message);
          this.uploading = false;
          error = true;
          document.getElementById("csv-form").reset();
        })
        .then(() => {
          if (!error) {
            this.sleep().then(() => {
              this.$emit("processing", false);
              this.$emit("success");
              this.$emit("filename", filename, "shifts");
            });
          }
        });
    },
    apiResourcesFile(formData, filename) {
      let error = false;
      this.uploading = true;
      axios.defaults.withCredentials = true;
      APIService.api
        .uploadSResourcesAndProcess(formData, this.onloadFunction)
        .catch(err => {
          this.$emit("failure", err.response.data.message);
          this.uploading = false;
          error = true;
          document.getElementById("csv-form").reset();
        })
        .then(() => {
          if (!error) {
            this.sleep().then(() => {
              this.$emit("processing", false);
              this.$emit("success");
              this.$emit("filename", filename, "resources");
            });
          }
        });
    },
    apiOperationsFile(
      formData,
      filename,
      ignoreEarlierDates,
      ignoreWarnings,
      shiftTimes
    ) {
      let error = false;
      this.uploading = true;
      axios.defaults.withCredentials = true;
      APIService.api
        .uploadOperationsAndProcess(
          formData,
          this.onloadFunction,
          ignoreEarlierDates,
          shiftTimes,
          ignoreWarnings
        )
        .catch(err => {
          let errorInstructions = err.response.data.message.split("#");
          if (errorInstructions.length > 1) {
            if (errorInstructions[0] == "PROMPT") {
              this.uploading = false;
              let message = this.t(
                errorInstructions[1],
                {},
                { locale: this.localLanguage }
              );

              this.$emit("openConfirmation", {
                showConfirmDialog: true,
                confirmMessage: message,
                confirmHeaderText: this.shiftTimesIntoTheFutureText,
                formData: formData,
                filename: filename
              });
              error = true;
              this.emitter.on("confirmShiftTime", () => {
                this.apiOperationsFile(formData, filename, true, true, false);
              });
              this.emitter.on("cancelShiftTime", () => {
                this.apiOperationsFile(formData, filename, true, false, false);
              });
            } else if (errorInstructions[0] == "WARNING") {
              this.emitter.all.clear("confirmShiftTime");
              this.emitter.all.clear("cancelShiftTime");

              let finalWarningMessage = this.t(
                errorInstructions[1],
                {},
                { locale: this.localLanguage }
              );
              finalWarningMessage =
                finalWarningMessage + ": " + errorInstructions[2];

              this.$emit("returnMessage", finalWarningMessage);

              this.apiOperationsFile(formData, filename, true, true, true);

              this.uploading = false;
              error = false;
              document.getElementById("csv-form").reset();
            }
          } else {
            this.emitter.all.clear("confirmShiftTime");
            this.emitter.all.clear("cancelShiftTime");

            this.$emit("failure", err.response.data.message);
            this.uploading = false;
            error = true;
            document.getElementById("csv-form").reset();
          }
        })
        .then(() => {
          if (!error) {
            this.emitter.all.clear("confirmShiftTime");
            this.emitter.all.clear("cancelShiftTime");
            this.sleep().then(() => {
              this.$emit("processing", false);
              this.$emit("success");
              this.$emit("filename", filename, "operations");
            });
          }
        });
    },
    async sendFile() {
      const formData = new FormData();
      formData.append("file", this.file);

      if (this.apiServiceCommand == "advancedFile") {
        this.apiAdvancedFile(formData);
      } else if (this.apiServiceCommand == "jobsFile") {
        this.apiJobsFile(formData, this.file.name);
      } else if (this.apiServiceCommand == "shiftsFile") {
        this.apiShiftsFile(formData, this.file.name);
      } else if (this.apiServiceCommand == "resourcesFile") {
        this.apiResourcesFile(formData, this.file.name);
      } else if (this.apiServiceCommand == "operationsFile") {
        this.apiOperationsFile(formData, this.file.name, false, false, false);
      }
    },
    onloadFunction(e) {
      // let tl = gsap.timeline();
      this.progress = Math.round((e.loaded * 100) / e.total);
      if (e.loaded === e.total) {
        this.message = "File has been uploaded";
        this.file = "";
        this.error = false;
        document.getElementById("csv-form").reset();

        this.$emit("processing", true);
        this.sleep().then(() => {
          this.uploading = false;
        });
        // tl.to(
        //   ".animated-upload",
        //   {
        //     opacity: 0,
        //     duration: 2,
        //     ease: "Rough.easeOut"
        //   },
        //   1
        // ).then(() => {
        //   this.uploading = false;
        // });
      }
    },
    sleep() {
      return new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
    },
    choose() {
      if (!this.disabledInput) this.$refs.fileInput.click();
    }
  },
  computed: {
    advancedChooseButtonClass() {
      return [
        this.disabledInput
          ? "control-button-wide cutted-b-right p-button p-component p-fileupload-choose disabled-button"
          : "control-button-wide cutted-b-right p-button p-component p-fileupload-choose"
      ];
    }
  },
  directives: {
    ripple: Ripple
  }
};
</script>

<style></style>
