<template>
  <div v-if="!disabled" id="layout-config" :class="containerClass">
    <!-- <a
      href="#"
      class="layout-config-button cutted-t-left info-box"
      id="layout-config-button"
      @click="toggleConfigurator"
    > -->
    <a
      href="#"
      class="layout-config-button cutted-t-left info-box-red"
      id="layout-config-button"
      @click="toggleConfigurator"
    >
      <i class="pi pi-cog"></i>
    </a>
    <a href="#" class="layout-config-close" @click="hideConfigurator">
      <i class="pi pi-times"></i>
    </a>

    <div class="layout-config-content cutted-dual">
      <h5>{{ t("system_language", {}, { locale: this.localLanguage }) }}</h5>
      <div class="p-formgroup-inline">
        <div class="p-field-dropdown">
          <Dropdown
            v-model="selectedLanguage"
            :options="languages"
            optionLabel="label"
            :placeholder="currentLanguagePlaceholder"
            class="p-as-center p-mr-2 p-mt-2"
            @change="onChangeSelectedLanguage"
            style="max-width: 110px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  props: {
    layoutMode: {
      type: String,
      default: null
    },
    layoutColorMode: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      selectedLanguage: [{ label: "Deutsch", code: "de" }],
      localLanguage: "de",
      language: "de",
      currentLanguagePlaceholder: "Deutsch",
      languages: [
        { label: "Deutsch", code: "de" },
        { label: "English", code: "en" }
      ],
      active: false,
      d_layoutMode: this.layoutMode,
      d_layoutColorMode: this.layoutColorMode,
      inactiveMenusState: false
    };
  },
  watch: {
    $route() {
      if (this.active) {
        this.active = false;
        this.unbindOutsideClickListener();
      }
    },
    layoutMode(newValue) {
      this.d_layoutMode = newValue;
    },
    layoutColorMode(newValue) {
      this.d_layoutColorMode = newValue;
    }
  },
  outsideClickListener: null,
  mounted() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.selectedLanguage = this.languages.filter(
      item => item.code == this.localLanguage
    )[0];
  },
  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
  },
  methods: {
    onChangeSelectedLanguage(lang) {
      this.selectedLanguage = lang.value;

      this.localLanguage = lang.value.code;

      this.currentLanguagePlaceholder = lang.value.label;

      this.$store.dispatch("setLanguage", this.localLanguage);
      this.languageBus.emit("LanguageStateBus");
    },
    toggleConfigurator(event) {
          this.active = !this.active;
          event.preventDefault();

          if (this.active) this.bindOutsideClickListener();
          else this.unbindOutsideClickListener();
    },
    hideConfigurator(event) {
        this.active = false;
        this.unbindOutsideClickListener();
        event.preventDefault();
    },
    changeInputStyle(value) {
      this.$appState.inputStyle = value;
    },
    changeRipple(value) {
      this.$primevue.ripple = value;
    },
    changeLayout(event, layoutMode) {
      this.$emit("layout-change", layoutMode);
      event.preventDefault();
    },
    changeLayoutColor(event, layoutColor) {
      this.$emit("layout-color-change", layoutColor);
      event.preventDefault();
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = event => {
          if (this.active && this.isOutsideClicked(event)) {
            this.active = false;
          }
        };
        document.addEventListener("click", this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener("click", this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      return !(
        this.$el.isSameNode(event.target) || this.$el.contains(event.target)
      );
    }
  },
  computed: {
    containerClass() {
      return ["layout-config", { "layout-config-active": this.active }];
    },
    rippleActive() {
      return this.$primevue.ripple;
    },
    inputStyle() {
      return this.$appState.inputStyle;
    }
  }
};
</script>
