<template>
  <AxxalonTooltip
    v-if="tooltip"
    :tooltipText="tooltipSwitchKey ? tooltipArray[0] : tooltipArray[1]"
    :direction="tooltipDirection"
  >
    <Button
      :icon="mainButtonIcon"
      :disabled="disableMainButtonSwitchKey ? (disableMenuControls? true: false) : true"
      :class="mainButtonClass"
      @click="openConfirmation"
    />

    <Dialog
      :header="headerText"
      v-model:visible="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
      class="cutted-trinity-variable"
      style="--notchSize: 15px; opacity: 0.9"
    >
      <div class="confirmation-content p-d-flex p-flex-row">
        <i :class="confirmationContentIcon" style="font-size: 2rem;" />
        <span style="word-wrap: break-word;">
          {{ bodyTextLeftHalf }}
          <b v-if="specialContentText != null" style="color: limegreen">{{
            specialContentText
          }}</b>
          {{ bodyTextRightHalf }}</span
        >
      </div>
      <template #footer>
        <Button
          :label="cancelButtonLabel"
          :icon="cancelButtonIcon"
          @click="closeConfirmation"
          :class="cancelButtonClass"
        />
        <Button
          :label="confirmButtonLabel"
          :icon="confirmButtonIcon"
          @click="confirm"
          :class="confirmButtonClass"
        />
      </template>
    </Dialog>
  </AxxalonTooltip>

  <template v-if="!tooltip">
    <Button
      :icon="mainButtonIcon"
      :disabled="disableMainButtonSwitchKey ? (disableMenuControls ? true : false) : true"
      :class="mainButtonClass"
      @click="openConfirmation"
    />

    <Dialog
      :header="headerText"
      v-model:visible="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
      class="cutted-trinity-variable"
      style="--notchSize: 15px; opacity: 0.9"
    >
      <div class="confirmation-content p-d-flex p-flex-row">
        <i :class="confirmationContentIcon" style="font-size: 2rem;" />
        <span style="word-wrap: break-word;">
          {{ bodyTextLeftHalf }}
          <b v-if="specialContentText != null" style="color: limegreen">{{
            specialContentText
          }}</b>
          {{ bodyTextRightHalf }}</span
        >
      </div>
      <template #footer>
        <Button
          :label="cancelButtonLabel"
          :icon="cancelButtonIcon"
          @click="closeConfirmation"
          :class="cancelButtonClass"
        />
        <Button
          :label="confirmButtonLabel"
          :icon="confirmButtonIcon"
          @click="confirm"
          :class="confirmButtonClass"
        />
      </template>
    </Dialog>
  </template>
</template>

<script>
// import AxxalonTooltip from "@/components/Axxalon/AxxalonTooltip.vue";
export default {
  props: {
    bodyTextLeftHalf: { type: String, default: "Some content applies over" },
    specialContentText: { type: String, default: " a special value" },
    bodyTextRightHalf: { type: String, default: " and finishes with this." },
    headerText: { type: String, default: "HEADER" },
    mainButtonClass: {
      type: String,
      default: "control-button-wide cutted-b-right p-ml-2"
    },
    mainButtonIcon: { type: String },
    mainButtonLabel: { type: String },
    cancelButtonClass: { type: String, default: "p-button-text" },
    cancelButtonIcon: { type: String, default: "pi pi-times" },
    cancelButtonLabel: { type: String, default: "No" },
    confirmButtonClass: { type: String, default: "p-button-text" },
    confirmButtonIcon: { type: String, default: "pi pi-check" },
    confirmButtonLabel: { type: String, default: "Yes" },
    confirmationContentIcon: {
      type: String,
      default: "pi pi-exclamation-triangle p-mr-3"
    },
    tooltip: { type: Boolean, default: false },
    tooltipArray: { type: Array, default: new Array("", "") },
    tooltipSwitchKey: { type: Boolean },
    tooltipDirection: { type: String, default: "bottom" },
    disableMainButtonSwitchKey: { type: Boolean },
    codeToRunInside: { type: Function },
    disableMenuControls: {type: Boolean}
  },
  data() {
    return {
      displayConfirmation: false
    };
  },
  components: {
    // AxxalonTooltip
  },
  methods: {
    openConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    confirm() {
      this.codeToRunInside();
      this.displayConfirmation = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
