<template>
  <div
    class="light-blur-box"
    :class="containerClass"
    @click="onWrapperClick"
    style="overflow: hidden !important;"
  >
    <div
      v-if="isAtWelcomePages() && !loggedIn"
      class="nav-bar p-d-flex p-flex-row p-jc-between"
    >
      <div class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-row">
          <Button
            icon="pi pi-home"
            class="p-as-center p-ml-2 p-mt-2"
            @click="goHome"
          />
        </div>
      </div>
      <div class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-row">
          <Dropdown
            v-model="selectedLanguage"
            :options="languages"
            optionLabel="label"
            :placeholder="currentLanguagePlaceholder"
            class="p-as-center p-mr-2 p-mt-2"
            @change="onChangeSelectedLanguage"
            style="max-width: 110px"
          />

          <Button
            :label="signInButtonLabel"
            class="p-as-center p-mr-2 p-mt-2"
            @click="goLogin"
          />
          <Button
            :label="registerButtonLabel"
            class="p-as-center p-mr-2 p-mt-2"
            @click="goRegister"
          />
        </div>
      </div>
    </div>

    <div v-if="isAtWelcomePages() && !loggedIn" class="p-d-flex p-flex-row ">
      <VideoBackground class="video-container">
        <router-view :language="selectedLanguage" @reload="reloadConfig" />
      </VideoBackground>
    </div>

    <!-- ################################################################## -->
    <!-- ################### USER INTERFACE APPLICATION ################### -->
    <!-- ################################################################## -->
    <AppTopBar
      v-if="!isAtWelcomePages() || loggedIn"
      @menu-toggle="onMenuToggle"
      @modeChange="onModeChange"
    />
    <Toast />

    <div v-if="!isAtWelcomePages() || loggedIn" class="layout-main" style="height: 100% !important; min-height:auto !important">
      <Menubar class="p-mb-2">
        <template #start>
          <div class="p-d-flex p-flex-row">
            <AxxalonTooltip
              :tooltipText="
                selectedCSV && !selectedOptimization
                  ? tooltipOptimizationArray[0]
                  : selectedOptimization
                  ? tooltipOptimizationArray[1]
                  : tooltipOptimizationArray[2]
              "
              direction="bottom"
            >
              <Button
                :class="
                  selectedCSV && !selectedOptimization && !processing
                    ? 'play-icon tooltip p-button-rounded p-mr-2'
                    : 'play-icon tooltip p-button-rounded p-button-outlined p-mr-2'
                "
                :disabled="
                  (selectedCSV && (!selectedOptimization ? false : true)) ||
                    processing ||
                    processingRaw ||
                    processingOptimized ||
                    processingRunOptimization ||
                    processingSearch ||
                    processingSort ||
                    processingShift ||
                    processingCreation ||
                    inactiveMenusState ||
                    disableMenusOnCreation
                "
                @click="runOptimization"
              >
                <img
                  v-if="
                    !selectedCSV ||
                      (selectedCSV && selectedOptimization) ||
                      processing
                  "
                  style="width: 18px; height: auto;"
                  src="./assets/images/play-icon-black.png"
                />
                <img
                  v-if="selectedCSV && !selectedOptimization && !processing"
                  style="width: 18px; height: auto;"
                  src="./assets/images/play-icon-white.png"
                />
              </Button>
            </AxxalonTooltip>
            <AxxalonTooltip
              :tooltipText="
                isGPUAvailable ? isGPUAvailableArray[0] : isGPUAvailableArray[1]
              "
              direction="bottom"
            >
              <Button
                label="GPU"
                class="p-mr-2 p-button-outlined"
                :disabled="selectedOptimization ? false : true"
              >
              </Button>
            </AxxalonTooltip>

            <Dropdown
              v-model="selectedOptimization"
              :options="executedOptimizations"
              optionLabel="name"
              :disabled="disableMenusOnCreation || ( inactiveMenusState || optimizationsCount > 0 ? (disableMenuControls ? true : false) : true )"
              :placeholder="
                optimizationsCount > 0
                  ? selectOptimizationArray[0]
                  : selectOptimizationArray[1]
              "
              class="p-mr-2 dynamic-optimization-dropdown"
              @change="onChangeSelectedOptimization"
              style="min-width: 280px;"
            />

            <AxxalonFileUpload
              v-if="advancedMode"
              :disableMenuControls="( disableMenuControls || inactiveMenusState ) || disableMenusOnCreation"
              apiServiceCommand="advancedFile"
              @fileCountAndOptimizationsUpdate="
                updateFilesAndOptimizationsCount
              "
              @processing="setProcessing"
            />

            <AxxalonWizard
              v-if="!advancedMode"
              :disableMenuControls="disableMenuControls || inactiveMenusState"
              :disableMenuControlsOnCreation="disableMenusOnCreation"
              @createAndSelectFile="onCreateAndSelectFile"
              @disableMenusStates="onDisableMenusStates"
            />

            <!-- <AxxalonWizard
              v-if="!advancedMode"
              @updateAndSelectFile="onUpdateAndSelectFile"
            /> -->

            <!-- <span>percentage: {{ scrollPercentage }}</span> -->
          </div>
        </template>

        <template #end>
          <div class="p-d-flex p-flex-row">
            <input
              style="visibility: hidden; width: 1px"
              type="file"
              id="openCSVFile"
              name="filename"
            />

            <div
              :style="
                processing ||
                processingRaw ||
                processingOptimized ||
                processingRunOptimization ||
                processingSearch ||
                processingSort ||
                processingShift ||
                processingSnap ||
                processingCreation
                  ? 'visibility: visible'
                  : 'visibility: hidden'
              "
              class="p-d-flex p-mr-2 p-as-center"
            >
              <span
                v-if="
                  processing ||
                    (processingRaw && processingOptimized) ||
                    (processingSearch && processingSort)
                "
                class="animated-processing p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
              </span>

              <span
                v-if="
                  processingRaw &&
                    !processingOptimized &&
                    !processing &&
                    !processingSearch &&
                    !processingSort &&
                    !processingShift &&
                    !processingSnap &&
                    !processingCreation
                "
                class="animated-processing-raw p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                RAW
              </span>

              <span
                v-if="
                  processingOptimized &&
                    !processingRaw &&
                    !processing &&
                    !processingSearch &&
                    !processingSort &&
                    !processingShift &&
                    !processingSnap &&
                    !processingCreation

                "
                class="animated-processing-optimized p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                OPTIMIZED
              </span>

              <span
                v-if="processingSearch"
                class="animated-processing-search p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                SEARCH
              </span>

              <span
                v-if="processingSort"
                class="animated-processing-sort p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                SORT
              </span>

              <span
                v-if="processingShift"
                class="animated-processing-shift p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                SHIFTS
              </span>

              <span
                v-if="processingSnap"
                class="animated-processing-snap p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                SNAP
              </span>

              <span
                v-if="processingCreation"
                class="animated-processing-creation p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                CREATION
              </span>

              <span
                v-if="
                  processingRunOptimization &&
                    !processingRaw &&
                    !processingOptimized &&
                    !processingSearch &&
                    !processingSort &&
                    !processingShift &&
                    !processingSnap &&
                    !processingCreation
                "
                class="animated-processing-run-optimization p-d-flex p-ai-center no-caret"
                style="color: grey; font-size: 15px;"
              >
                {{ t("processing", {}, { locale: language }) }}
                Optimization
              </span>
            </div>
            <ProgressSpinner
              v-if="
                processing ||
                  processingRaw ||
                  processingOptimized ||
                  processingRunOptimization ||
                  processingSearch ||
                  processingSort ||
                  processingShift ||
                  processingSnap ||
                  processingCreation
              "
              style="width: 30px !important; height: 30px !important"
              class="p-d-flex p-ai-center p-mr-2 menubar-spinner animated-processing"
              strokeWidth="8"
              fill="#EEEEEE"
              animationDuration=".5s"
            />
            <Dropdown
              v-model="selectedCSV"
              :options="filesNames"
              optionLabel="name"
              :disabled="disableMenusOnCreation || ( filesCount > 0 ? (disableMenuControls ? true : false) : true )"
              :placeholder="
                filesCount > 0 ? selectFilesArray[0] : selectFilesArray[1]
              "
              @change="onChangeSelectedCSVFile"
              style="min-width: 200px;"
            />

            <!-- RELEASE ON NEXT VERSION -->
            <AxxalonConfirmDialog
              v-if="false"
              :bodyTextLeftHalf="bodyTextLeftHalfDeleteConfirm"
              :specialContentText="selectedCSV != null ? selectedCSV.name : ''"
              :bodyTextRightHalf="bodyTextRightHalfDeleteConfirm"
              :headerText="headerTextDeleteConfirm"
              mainButtonClass="control-button-wide cutted-b-right p-ml-2"
              mainButtonIcon="pi pi-trash"
              :tooltipArray="tooltipDeleteConfirmArray"
              :tooltipSwitchKey="selectedCSV != null"
              tooltipDirection="left"
              :disableMainButtonSwitchKey="selectedCSV != null"
              :codeToRunInside="confirmDeletion"
              :confirmButtonLabel="confirmButtonLabel"
              :cancelButtonLabel="cancelButtonLabel"
              :disableMenuControls="disableMenuControls"
            />
          </div>
        </template>
      </Menubar>
      <router-view
        :rawOperations="this.rawOperationsVal"
        :rawLeadTime="this.rawLeadTimeVal"
        :rawAvLeadTime="this.rawAvLeadTimeVal"
        :rawDeliveryReliability="this.rawDeliveryReliability"
        :optOperations="this.optOperationsVal"
        :optLeadTime="this.optLeadTimeVal"
        :optAvLeadTime="this.optAvLeadTimeVal"
        :optimizedDeliveryReliability="this.optimizedDeliveryReliability"
        :optimizedTreatedData="this.optimizedData"
        :rawTreatedData="this.rawData"
        :rawChartStart="this.currentRawChartStartDate"
        :rawChartEnd="this.currentRawChartEndDate"
        :originRawChartStart="this.originRawChartStartDate"
        :originRawChartEnd="this.originRawChartEndDate"
        :initialRawViewportStart="this.initialRawViewportStart"
        :initialRawViewportEnd="this.initialRawViewportEnd"
        :optimizedChartStart="this.currentOptimizedChartStartDate"
        :optimizedChartEnd="this.currentOptimizedChartEndDate"
        :originOptimizedChartStart="this.originOptimizedChartStartDate"
        :originOptimizedChartEnd="this.originOptimizedChartEndDate"
        :initialOptimizedViewportStart="this.initialOptimizedViewportStart"
        :initialOptimizedViewportEnd="this.initialOptimizedViewportEnd"
        :viewport="this.viewport"
        :isOptimizationLoaded="optimizationLoaded"
        :selectedCSVFileId="selectedCSV != null ? selectedCSV.code : ''"
        :noAnimation="noAnimation"
        @updateUpperPagination="onRawPaginationRefresh"
        @updateLowerPagination="onOptimizedPaginationRefresh"
      />
    </div>

    <AppConfig
      v-if="!isAtWelcomePages() || loggedIn"
      :disabled="disableMenusOnCreation"
      :layoutMode="layoutMode"
      :layoutColorMode="layoutColorMode"
      @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange"
    />

    <div class="p-d-flex p-flex-row">
      <AppFooter style="margin-left: 0px; width: 100%;" />
    </div>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import gsap from "gsap";
import AxxalonFileUpload from "@/components/Axxalon/AxxalonFileUpload.vue";
import AxxalonWizard from "@/components/Axxalon/AxxalonWizard/AxxalonWizard.vue";
import AxxalonConfirmDialog from "@/components/Axxalon/AxxalonConfirmDialog.vue";
import APIService from "@/service/APIService";
import moment from "moment";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const store = useStore();
    const loggedIn = ref(false);

    const userSignedIn = ref(false);

    const currentSpeed = null;

    function setSignedIn() {
      store.commit("setSignedIn");
    }

    const { t, locale } = useI18n();

    return { loggedIn, setSignedIn, userSignedIn, currentSpeed, t, locale };
  },
  components: {
    AppTopBar,
    AppConfig,
    AppFooter,
    AxxalonFileUpload,
    AxxalonWizard,
    AxxalonConfirmDialog
  },
  data() {
    return {
      disableMenusOnCreation: false,
      inactiveMenusState: false,
      selectedLanguage: [{ label: "Deutsch", code: "de" }],
      language: "de",
      languages: [
        { label: "Deutsch", code: "de" },
        { label: "English", code: "en" }
      ],
      signInButtonLabel: this.capitalize(
        this.t("sign_in", {}, { locale: "de" })
      ),
      registerButtonLabel: this.capitalize(
        this.t("register", {}, { locale: "de" })
      ),
      currentLanguagePlaceholder: "Deutsch",
      confirmButtonLabel: this.t("yes", {}, { locale: "de" }),
      cancelButtonLabel: this.t("no", {}, { locale: "de" }),

      tooltipOptimizationArray: new Array(
        this.t("run_optimization", {}, { locale: "de" }),
        this.t(
          "a_optimization_for_the_selected_file_already_exist",
          {},
          { locale: "de" }
        ),
        this.t(
          "please_select_or_import_csv_file_to_run_a_optimization",
          {},
          { locale: "de" }
        )
      ),

      isGPUAvailableArray: new Array(
        this.t("enable_gpu_processing", {}, { locale: "de" }),
        this.t("gpu_not_available", {}, { locale: "de" })
      ),

      selectOptimizationArray: new Array(
        this.t("select_a_optimization", {}, { locale: "de" }),
        this.t("no_optimizations_available", {}, { locale: "de" })
      ),

      selectFilesArray: new Array(
        this.t("select_imported_csv", {}, { locale: "de" }),
        this.t("no_files_imported", {}, { locale: "de" })
      ),

      bodyTextLeftHalfDeleteConfirm: this.t(
        "are_you_sure_you_want_to_delete",
        {},
        { locale: "de" }
      ),

      bodyTextRightHalfDeleteConfirm:
        this.t("file", {}, { locale: "de" }) + "?",
      headerTextDeleteConfirm: this.t("delete_file", {}, { locale: "de" }),
      tooltipDeleteConfirmArray: new Array(
        this.t("delete_selected_file", {}, { locale: "de" }),
        this.t("no_csv_file_selected", {}, { locale: "de" })
      ),

      rawPaginationRequest: false,
      optimizedPaginationRequest: false,

      scrollPercentage: 0,
      noAnimation: false,

      showGlass: true,
      totalRuns: 0,
      axxalonPlanLeadTime: 0,
      axxalonPlanLeadTimeReduction: 0,
      axxalonPlanAvgLeadTime: 0,
      axxalonPlanAvgLeadTimeReduction: 0,
      axxalonPlanDeliveryReliability: 0,
      crudeLeadTime: 0,
      crudeAvgLeadTime: 0,
      crudeDeliveryReliability: 0,

      rawDeliveryReliability: 0,
      optimizedDeliveryReliability: 0,
      reloadDashboard: false,
      resetDashBoard: false,
      optimizationLoaded: false,
      advancedMode: false,
      viewport: 1,
      originRawChartStartDate: "",
      originRawChartEndDate: "",
      currentRawChartStartDate: "",
      currentRawChartEndDate: "",
      rawTopViewport: -1,
      rawBottomViewport: -1,

      originOptimizedChartStartDate: "",
      originOptimizedChartEndDate: "",
      currentOptimizedChartStartDate: "",
      currentOptimizedChartEndDate: "",
      optimizedTopViewport: -1,
      optimizedBottomViewport: -1,

      rawOuterViewportStart: "",
      rawOuterViewportEnd: "",

      optimizedOuterViewportStart: "",
      optimizedOuterViewportEnd: "",

      initialRawViewportStart: "",
      initialRawViewportEnd: "",

      initialOptimizedViewportStart: "",
      initialOptimizedViewportEnd: "",

      rawData: null,
      optimizedData: null,
      optimizedTreatedData: null,
      displayConfirmation: false,

      processing: false,
      processingRaw: false,
      processingOptimized: false,
      processingRunOptimization: false,
      processingSearch: false,
      processingSort: false,
      processingShift: false,
      processingSnap: false,
      processingCreation: false,

      optimizeButton: "./assets/images/play-icon-black.png",
      rawOperationsVal: 0,
      rawLeadTimeVal: 0,
      rawAvLeadTimeVal: 0,
      optOperationsVal: 0,
      optLeadTimeVal: 0,
      optAvLeadTimeVal: 0,
      CSVFiles: [],
      filesNames: [],
      filesCount: 0,
      optimizationsCount: 0,
      progress: null,
      isGPUAvailable: false,
      selectedOptimization: null,
      selectedCSV: null,
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      executedOptimizations: [],
      snap: false,
      rawDataBackup: null,
      currentRawChartStartDateBackup: null,
      currentRawChartEndDateBackup: null,
      initialRawViewportStartBackup: null,
      initialRawViewportEndBackup: null,
      optimizedDataBackup: null,
      currentOptimizedChartStartDateBackup: null,
      currentOptimizedChartEndDateBackup: null,
      initialOptimizedViewportStartBackup: null,
      initialOptimizedViewportEndBackup: null,
      originOptimizedChartStartDateBackup: null,
      originOptimizedChartEndDateBackup: null,
      originRawChartStartDateBackup: null,
      originRawChartEndDateBackup: null,
      disableMenuControls: false,
      searchMode: false,
      loaded: false
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
    loaded: function(state) {
      console.log("LOADED STATE: " + state)
    },
    snap: function(state) {
      // if (!this.searchMode){
        this.disableMenuControls = state;
      // }
    },
    processing: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingRaw: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingOptimized: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingShift: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingSearch: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingSort: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingRunOptimization: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    processingSnap: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
      if (!this.disableMenuControls){
        this.disableMenuControls = this.snap;
      }
    },
    processingData: function(state) {
      if (!this.searchMode){
        this.disableMenuControls = state;
      }
    },
    searchMode: function(state) {
      this.disableMenuControls = state;
    }
  },
  methods: {
    onChangeSelectedLanguage(lang) {
      this.selectedLanguage = lang.value;

      this.localLanguage = lang.value.code;

      this.currentLanguagePlaceholder = lang.value.label;

      this.signInButtonLabel = this.capitalize(
        this.t("sign_in", {}, { locale: lang.value.code })
      );
      this.registerButtonLabel = this.capitalize(
        this.t("register", {}, { locale: lang.value.code })
      );

      this.$store.dispatch("setLanguage", this.localLanguage);
    },
    onDisableMenusStates(state) {
      // console.log("onDisableMenusStates:" + state)
      this.disableMenusOnCreation = state;
      this.setProcessing(state, "creation");
      // console.log("disableMenusOnCreation: " + this.disableMenusOnCreation)
      // this.inactiveMenusState = state;
      //  this.emitter.emit("resetDashboard");
      //  this.mountListenersAgain();
      // this.emitter.emit("disableMenusStates", state);
    },

    onCreateAndSelectFile(fileId) {
      this.resetCSVOptimizedKPIs();
      this.resetCSVRawKPIs();

      this.emitter.emit("resetDashboard");

      this.mountListenersAgain();

      //  DEVELOP ENV
      // this.updateFilesCount().then(() => {
      //   let currentFile = this.filesNames.filter(
      //     item => item.code == fileId.data
      //   )[0];
      //   this.selectedCSV = currentFile;
      //   console.log("currentFile:" + JSON.stringify(this.selectedCSV));
      //   this.onChangeSelectedCSVFile({
      //     value: { code: fileId.data, firstLoad: true }
      //   });
      // });

      //  PRODUCTION ENV
      this.sleep().then(() => { 
          this.updateFilesCount().then(() => {
            let currentFile = this.filesNames.filter(
              item => item.code == fileId
            )[0];
            this.selectedCSV = currentFile;
            this.onChangeSelectedCSVFile({
              value: { code: fileId, firstLoad: true }
            });
          });
      });

    },

    onUpdateAndSelectFile(fileId) {
      this.resetCSVOptimizedKPIs();
      this.resetCSVRawKPIs();

      this.emitter.emit("resetDashboard");

      //  DEVELOP ENV
      // let currentFile = this.filesNames.filter(
      //   item => item.code == fileId.data
      // )[0];
      // this.selectedCSV = currentFile;
      // this.onChangeSelectedCSVFile({
      //   value: { code: fileId.data, firstLoad: true }
      // });

      //  PRODUCTION ENV
      this.updateFilesCount().then(() => {
        let currentFile = this.filesNames.filter(
          item => item.code == fileId
        )[0];
        this.selectedCSV = currentFile;
        this.onChangeSelectedCSVFile({
          value: { code: fileId, firstLoad: false }
        });
      });
    },
    selectedCSVName() {
      return this.selectedCSV.name.toString();
    },
    onModeChange(mode) {
      this.advancedMode = mode;
    },
    openConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    confirmDeletion() {
      this.emitter.emit("disableControlStates", true);
      this.setProcessing(true);
      let fileId = this.selectedCSV.code;
      APIService.api.deleteFileById(fileId).then(() => {
        this.selectedCSV = null;
        this.selectedOptimization = null;
        this.displayConfirmation = false;

        this.emitter.emit("resetDashboard");

        this.resetCSVRawKPIs();
        this.resetCSVOptimizedKPIs();
        this.updateFilesCount();
        this.updateOptimizationsCount();
        this.setProcessing(false);
        this.emitter.emit("disableControlStates", false);
      });
    },
    resetCSVRawKPIs() {
      this.rawOperationsVal = 0;
      this.rawLeadTimeVal = 0;
      this.rawAvLeadTimeVal = 0;
    },
    resetCSVOptimizedKPIs() {
      this.optOperationsVal = 0;
      this.optLeadTimeVal = 0;
      this.optAvLeadTimeVal = 0;
    },
    setProcessing(status, type = null) {
      // let statusBefore = this.processing;
      let statusBefore;

      if (type == null) {
        statusBefore = this.processing;
      } else if (type == "raw") {
        statusBefore = this.processingRaw;
      } else if (type == "optimized") {
        statusBefore = this.processingOptimized;
      } else if (type == "run-optimization") {
        statusBefore = this.processingRunOptimization;
      } else if (type == "search") {
        statusBefore = this.processingSearch;
      } else if (type == "sort") {
        statusBefore = this.processingSort;
      } else if (type == "shift") {
        statusBefore = this.processingShift;
      } else if (type == "snap") {
        statusBefore = this.processingSnap;
      } else if (type == "creation") {
        statusBefore = this.processingCreation;
      }

      if (statusBefore != status && status == false) {
        let tl = gsap.timeline();
        if (type == null) {
          if (document.getElementById("animated-processing")) {
            tl.to(".animated-processing", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processing = status;
            });
          } else {
            this.processing = status;
          }
        } else if (type == "raw") {
          if (document.getElementById("animated-processing-raw")) {
            tl.to(".animated-processing-raw", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingRaw = status;
            });
          } else {
            this.processingRaw = status;
          }
        } else if (type == "optimized") {
          if (document.getElementById("animated-processing-raw")) {
            tl.to(".animated-processing-optimized", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingOptimized = status;
            });
          } else {
            this.processingOptimized = status;
          }
        } else if (type == "run-optimization") {
          if (document.getElementById("animated-processing-run-optimization")) {
            tl.to(".animated-processing-run-optimization", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingRunOptimization = status;
            });
          } else {
            this.processingRunOptimization = status;
          }
        } else if (type == "search") {
          if (document.getElementById("animated-processing-search")) {
            tl.to(".animated-processing-search", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingSearch = status;
            });
          } else {
            this.processingSearch = status;
          }
        } else if (type == "sort") {
          if (document.getElementById("animated-processing-sort")) {
            tl.to(".animated-processing-sort", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingSort = status;
            });
          } else {
            this.processingSort = status;
          }
        } else if (type == "shift") {
          if (document.getElementById("animated-processing-shift")) {
            tl.to(".animated-processing-shift", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingShift = status;
            });
          } else {
            this.processingShift = status;
          }
        } else if (type == "snap") {
          if (document.getElementById("animated-processing-snap")) {
            tl.to(".animated-processing-snap", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingSnap = status;
            });
          } else {
            this.processingSnap = status;
          }
        } else if (type == "creation") {
           if (document.getElementById("animated-processing-creation")) {
            tl.to(".animated-processing-creation", {
              opacity: 0,
              duration: 3,
              ease: "Rough.easeOut"
            }).then(() => {
              this.processingCreation = status;
            });
          } else {
            this.processingCreation = status;
          }
        }
      } else {
        if (type == null) {
          this.processing = status;
        } else if (type == "raw") {
          this.processingRaw = status;
        } else if (type == "optimized") {
          this.processingOptimized = status;
        } else if (type == "run-optimization") {
          this.processingRunOptimization = status;
        } else if (type == "search") {
          this.processingSearch = status;
        } else if (type == "sort") {
          this.processingSort = status;
        } else if (type == "shift") {
          this.processingShift = status;
        } else if (type == "snap") {
          this.processingSnap = status;
        } else if (type == "creation") {
          this.processingCreation = status;
        }

        // this.processing = status;
      }
    },
    updateFilesAndOptimizationsCount() {
      this.updateFilesCount();
      this.updateOptimizationsCount();
    },
    updateFilesCount() {
      // if (this.processing || this.processingRaw || this.processingOptimized) {
      //   return;
      // }
      return APIService.api
        .getAllFilesNames()
        .then(response => {
          this.filesNames = [];
          let allData = response.data;
          let processingData = JSON.parse('{ "list": [' + allData + " ] }");
          for (var i = 0; i < processingData.list.length; i++) {
            let filename = processingData.list[i].filename;
            let id = processingData.list[i]._id.$oid;
            let UUID = processingData.list[i].processUUID;
            const name = {
              name: filename,
              code: id,
              processUUID: UUID
            };

            this.filesNames.push(name);
          }
          this.filesCount = this.filesNames.length;
        })
        .catch(error => {
          // Token Expired
          if (error.message == "Request failed with status code 500") {
            localStorage.removeItem("user");
            this.$router.push({ name: "Home" });
          }
        });
    },
    updateOptimizationsCount() {
      try {
        APIService.api.getCreatedDates().then(response => {
          this.executedOptimizations = [];
          let allData = response.data;
          let processingData = JSON.parse('{ "list": [' + allData + " ] }");

          for (var i = 0; i < processingData.list.length; i++) {
            let createdDate = moment
              .utc(processingData.list[i].createdDate.$date)
              .local()
              .format("ddd YYYY-MM-DD HH:MM:SS");
            let id = processingData.list[i].fileId;
            const name = {
              name: "optimization @ " + createdDate,
              code: id
            };

            this.executedOptimizations.push(name);
          }

          this.optimizationsCount = this.executedOptimizations.length;

          if (this.selectedCSV) {
            let currentOptimization = this.executedOptimizations.filter(
              item => item.code == this.selectedCSV.code
            )[0];

            this.selectedOptimization = currentOptimization;
          }
        });
      } catch {
        console.log("Something went wrong!");
      }
    },
    onChangeUpdateOptimizeButton() {
      if (this.selectedCSV === true) {
        this.optimizeButton = "./assets/images/play-icon-black.png";
      } else this.optimizeButton = "./assets/images/play-icon-white.png";
    },

    setRawViewportDates(startDate, endDate) {
      if (
        startDate == null ||
        startDate == "" ||
        endDate == null ||
        endDate == ""
      ) {
        return;
      }
      this.currentRawChartStartDate =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.currentRawChartEndDate =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    setInitialRawViewportDates(startDate, endDate) {
      if (
        startDate == null ||
        startDate == "" ||
        endDate == null ||
        endDate == ""
      ) {
        return;
      }
      this.initialRawViewportStart =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.initialRawViewportEnd =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    setOriginRawViewportDates(startDate, endDate) {
      if (
        startDate == null ||
        startDate == "" ||
        endDate == null ||
        endDate == ""
      ) {
        return;
      }
      this.originRawViewportStart =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.originRawViewportEnd =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    setOptimizedViewportDates(startDate, endDate) {
      this.currentOptimizedChartStartDate =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.currentOptimizedChartEndDate =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    setInitialOptimizedViewportDates(startDate, endDate) {
      if (
        startDate == null ||
        startDate == "" ||
        endDate == null ||
        endDate == ""
      ) {
        return;
      }
      this.initialOptimizedViewportStart =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.initialOptimizedViewportEnd =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    setOriginOptimizedViewportDates(startDate, endDate) {
      if (
        startDate == null ||
        startDate == "" ||
        endDate == null ||
        endDate == ""
      ) {
        return;
      }
      this.originOptimizedViewportStart =
        moment(startDate.split(" ")[0])
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";

      this.originOptimizedViewportEnd =
        moment(endDate.split(" ")[0])
          // .add(1, "days")
          .format("YYYY-MM-DD HH:MM")
          .split(" ")[0] + " 00:00";
    },
    sleep() {
      return new Promise(resolve => {
        setTimeout(resolve, 80);
      });
    },
    onRawPaginationRefresh(
      newStart,
      newEnd,
      top,
      bottom,
      verticalActivated = false,
      expanded = true,
      firstLoad = false
    ) {
      let tempSelectedFile = this.selectedCSV;

      let rawOuterViewportDistance = Math.abs(
        moment
          .duration(
            moment(this.rawOuterViewportStart).diff(
              moment(this.rawOuterViewportEnd)
            )
          )
          .asDays()
      );
      let frontierToEndDistance = Math.abs(
        moment
          .duration(moment(this.rawOuterViewportEnd).diff(moment(newEnd)))
          .asDays()
      );

      let frontierToStartDistance = Math.abs(
        moment
          .duration(moment(this.rawOuterViewportStart).diff(moment(newStart)))
          .asDays()
      );

      if (
        (frontierToEndDistance / rawOuterViewportDistance < 0.35 ||
          moment(newEnd).isAfter(moment(this.rawOuterViewportEnd)) ||
          moment(newStart).isBefore(moment(this.rawOuterViewportStart)) ||
          frontierToStartDistance / rawOuterViewportDistance < 0.35 ||
          verticalActivated) &&
        !this.processingRaw
      ) {
        this.setProcessing(true, "raw");
        this.noAnimation = true;
        // this.rawPaginationRequest = true;
        APIService.api
          .getFileByFileId(
            this.selectedCSV.code,
            newStart,
            newEnd,
            top,
            bottom,
            expanded,
            firstLoad
          )
          .then(response => {
            if (response != null) {
              let data = response.data;
              let rawParsedData = JSON.parse(data.ganttDataList);

              this.rawData = rawParsedData.treatedList;

              this.chartCurrentStartDate = rawParsedData.chartCurrentStartDate;
              this.chartCurrentEndDate = rawParsedData.chartCurrentEndDate;

              this.rawOuterViewportStart = rawParsedData.outerViewportStart;
              this.rawOuterViewportEnd = rawParsedData.outerViewportEnd;

              this.rawTopViewport = rawParsedData.topViewport;
              this.rawBottomViewport = rawParsedData.bottomViewport;

              this.setRawViewportDates(
                rawParsedData.chartCurrentStartDate,
                rawParsedData.chartCurrentEndDate
              );
              this.setProcessing(false, "raw");
              this.selectedCSV = tempSelectedFile;
              // this.rawPaginationRequest = false;
            }
            this.setProcessing(false, "raw");
            this.selectedCSV = tempSelectedFile;
            // this.emitter.emit("restoreChartResourceSideBar");
            // this.rawPaginationRequest = false;
          }).then(() => {
            this.emitter.emit("restoreChartResourceSideBar", "RAW-PAGINATION");
          })
          .catch(() => {
            this.setProcessing(false, "raw");
            this.selectedCSV = tempSelectedFile;
            this.emitter.emit("restoreChartResourceSideBar");
            // this.rawPaginationRequest = false;
          });
      }
      // this.rawPaginationRequest = false;
    },
    onOptimizedPaginationRefresh(
      newStart,
      newEnd,
      top,
      bottom,
      verticalActivated = false,
      expanded = true,
      firstLoad = false
    ) {
      let tempSelectedFile = this.selectedCSV;

      let optimizedOuterViewportDistance = Math.abs(
        moment
          .duration(
            moment(this.optimizedOuterViewportStart).diff(
              moment(this.optimizedOuterViewportEnd)
            )
          )
          .asDays()
      );
      let frontierToEndDistance = Math.abs(
        moment
          .duration(moment(this.optimizedOuterViewportEnd).diff(moment(newEnd)))
          .asDays()
      );

      let frontierToStartDistance = Math.abs(
        moment
          .duration(
            moment(this.optimizedOuterViewportStart).diff(moment(newStart))
          )
          .asDays()
      );

      if (
        (frontierToEndDistance / optimizedOuterViewportDistance < 0.35 ||
          moment(newEnd).isAfter(moment(this.optimizedOuterViewportEnd)) ||
          moment(newStart).isBefore(moment(this.optimizedOuterViewportStart)) ||
          frontierToStartDistance / optimizedOuterViewportDistance < 0.35 ||
          verticalActivated) &&
        !this.processingOptimized
      ) {
        this.setProcessing(true, "optimized");
        this.noAnimation = true;
        APIService.api
          .getOptimizedFileByRawFileId(
            this.selectedCSV.code,
            newStart,
            newEnd,
            top,
            bottom,
            expanded,
            firstLoad
          )
          .then(response => {
            if (response != null) {
              let data = response.data;
              let optimizedParsedData = JSON.parse(data.ganttDataList);

              this.optimizedData = optimizedParsedData.treatedList;

              this.chartCurrentStartDate =
                optimizedParsedData.chartCurrentStartDate;
              this.chartCurrentEndDate =
                optimizedParsedData.chartCurrentEndDate;

              this.optimizedOuterViewportStart =
                optimizedParsedData.outerViewportStart;
              this.optimizedOuterViewportEnd =
                optimizedParsedData.outerViewportEnd;

              this.optimizedTopViewport = optimizedParsedData.topViewport;
              this.optimizedBottomViewport = optimizedParsedData.bottomViewport;

              this.setRawViewportDates(
                optimizedParsedData.chartCurrentStartDate,
                optimizedParsedData.chartCurrentEndDate
              );
              this.setProcessing(false, "optimized");
              this.selectedCSV = tempSelectedFile;
            }
            this.setProcessing(false, "optimized");
            this.selectedCSV = tempSelectedFile;
          }).then(() => {
            this.emitter.emit("restoreChartResourceSideBar", "OPTIMIZED-PAGINATION");
          })
          .catch(() => {
            this.setProcessing(false, "optimized");
            this.selectedCSV = tempSelectedFile;
            this.emitter.emit("restoreChartResourceSideBar", "OPTIMIZED-PAGINATION");
          });
      }
    },
    setDataOnBothChart(rawResponse, optimizedResponse){
      if (rawResponse != null && optimizedResponse != null){
        let rawParsedData = JSON.parse(rawResponse.ganttDataList);
        let rawEnd = rawParsedData.chartCurrentEndDate;

        let parsedData = JSON.parse(optimizedResponse.ganttDataList);
        let optimizedEnd = parsedData.chartCurrentEndDate;

        let rawEndDate = moment(rawEnd.split(" ")[0], 'YYYY-MM-DD HH:MM')
        let optimizedEndDate = moment(optimizedEnd.split(" ")[0], 'YYYY-MM-DD HH:MM')

        let max;

        if (rawEndDate.isAfter(optimizedEndDate)){
          max = rawEndDate.format('DD-MMM-YYYY HH:MM');
        } else {
          max = optimizedEndDate.format('DD-MMM-YYYY HH:MM');
        }

        this.setDataOnUpperChart(rawResponse, max);
        this.setDataOnLowerChart(optimizedResponse, max);
        this.emitter.emit("turnInternalChartsStartEndNull");
      } else {
        this.setDataOnUpperChart(null, 0);
        this.setDataOnLowerChart(null, 0);
      }
      
    },
    setDataOnUpperChart(response, max){
      if (response != null){
        let data = response;
        let rawParsedData = JSON.parse(data.ganttDataList);

        if (!this.snap){
          // Store old data
          this.rawDataBackup = JSON.parse(JSON.stringify(this.rawData));

          this.currentRawChartStartDateBackup = this.currentRawChartStartDate
          this.currentRawChartEndDateBackup = this.currentRawChartEndDate

          this.initialRawViewportStartBackup = this.initialRawViewportStart 
          this.initialRawViewportEndBackup = this.initialRawViewportEnd

          this.originRawChartStartDateBackup = this.originRawChartStartDate 
          this.originRawChartEndDateBackup = this.originRawChartEndDate 
        
          // console.log("-------- FAZENDO RAW BACKUPS ---------------")
          // console.log("this.currentRawChartStartDateBackup: " + this.currentRawChartStartDateBackup)
          // console.log("this.currentRawChartEndDateBackup: " + this.currentRawChartEndDateBackup)

          // Change the data to new
          this.rawData = rawParsedData.treatedList;

          this.setRawViewportDates(
            rawParsedData.chartCurrentStartDate,
            max
          );      
        } 
      } else {
          this.setRawViewportDates(
            this.currentRawChartStartDateBackup,
            this.currentRawChartEndDateBackup
          );

          this.setInitialRawViewportDates(
            this.initialRawViewportStartBackup,
            this.initialRawViewportEndBackup
          ); 

          this.setOriginRawViewportDates(
            this.originRawViewportStartBackup,
            this.originRawViewportEndBackup
          ); 

          this.rawData = this.rawDataBackup;
        }
      

    },

    setDataOnLowerChart(response, max){
      if (response != null){
          let optData = response;
          if (optData != "") {

            let parsedData = JSON.parse(optData.ganttDataList);


            if (!this.snap){
              // Store old data
              this.optimizedDataBackup = JSON.parse(JSON.stringify(this.optimizedData));

              this.currentOptimizedChartStartDateBackup = this.currentOptimizedChartStartDate
              this.currentOptimizedChartEndDateBackup = this.currentOptimizedChartEndDate

              // console.log("-------- FAZENDO OPTIMIZED BACKUPS ---------------")
              // console.log("this.currentOptimizedChartStartDateBackup: " + this.currentOptimizedChartStartDateBackup)
              // console.log("this.currentOptimizedChartEndDateBackup: " + this.currentOptimizedChartEndDateBackup)

              this.initialOptimizedViewportStartBackup = this.initialOptimizedViewportStart 
              this.initialOptimizedViewportEndBackup = this.initialOptimizedViewportEnd

              this.originOptimizedChartStartDateBackup = this.originOptimizedChartStartDate 
              this.originOptimizedChartEndDateBackup = this.originOptimizedChartEndDate 

              // Change the data to new
              this.optimizedData = parsedData.treatedList;

              this.setOptimizedViewportDates(
                parsedData.chartCurrentStartDate,
                max
              );
            } 
          } 

      }  else {
              this.setOptimizedViewportDates(
                this.currentOptimizedChartStartDateBackup,
                this.currentOptimizedChartEndDateBackup
              );

              this.setInitialOptimizedViewportDates(
                this.initialOptimizedViewportStartBackup,
                this.initialOptimizedViewportEndBackup
              ); 

              this.setOriginOptimizedViewportDates(
                this.originOptimizedViewportStartBackup,
                this.originOptimizedViewportEndBackup
              ); 

              this.optimizedData = this.optimizedDataBackup;
      }
 
    },
    onChangeSelectedCSVFile(event, fileId=null) {
      this.noAnimation = false;

      this.setProcessing(true, "raw");

      this.resetCSVOptimizedKPIs();
      this.resetCSVRawKPIs();

      this.emitter.emit("resetDashboard");

      this.optimizationLoaded = false;

      APIService.api
        .getFileByFileId(fileId == null ? (event != null ? event.value.code : '') : fileId, null, null, null, null, false, true)
        .then(response => {
          if (response != null) {
            let data = response.data;

            this.rawOperationsVal = data.operationsCount;
            this.rawLeadTimeVal = data.leadTime;
            this.rawAvLeadTimeVal = data.avgLeadTime;

            this.rawDeliveryReliability = data.deliveryReliability;

            let rawParsedData = JSON.parse(data.ganttDataList);

            this.rawData = rawParsedData.treatedList;

            this.originRawChartStartDate = rawParsedData.chartStartDate;
            this.originRawChartEndDate = rawParsedData.chartEndDate;

            // this.chartCurrentStartDate = rawParsedData.chartCurrentStartDate;
            // this.chartCurrentEndDate = rawParsedData.chartCurrentEndDate;

            this.rawOuterViewportStart = rawParsedData.outerViewportStart;
            this.rawOuterViewportEnd = rawParsedData.outerViewportEnd;

            this.rawTopViewport = rawParsedData.topViewport;
            this.rawBottomViewport = rawParsedData.bottomViewport;

            if (this.initialRawViewportStart == "") {
              this.initialRawViewportStart =
                rawParsedData.chartCurrentStartDate;
            }

            if (this.initialRawViewportEnd == "") {
              this.initialRawViewportEnd = rawParsedData.chartCurrentEndDate;
            }

            // if (this.initialOuterOptimizedViewportStart == "") {
            //   this.initialOuterOptimizedViewportStart =
            //     rawParsedData.outerViewportStart;
            // }

            // if (this.initialOuterOptimizedViewportEnd == "") {
            //   this.initialOuterOptimizedViewportEnd =
            //     rawParsedData.outerViewportEnd;
            // }

            this.setRawViewportDates(
              rawParsedData.chartCurrentStartDate,
              rawParsedData.chartCurrentEndDate
            );

            this.emitter.emit("AxxalonDashboardAnimateUpperChart");
            this.setProcessing(false, "raw");

            if (!event.value.firstLoad || event.value.firstLoad == null) {
              this.setProcessing(true, "optimized");
              APIService.api
                .getOptimizedFileByRawFileId(
                  this.selectedCSV.code,
                  this.initialRawViewportStart,
                  this.initialRawViewportEnd,
                  this.rawTopViewport,
                  this.rawBottomViewport,
                  false,
                  true
                )
                .then(response => {
                  if (response != null) {
                    this.emitter.emit("optimizationLoaded", true);
                    this.resetCSVOptimizedKPIs();
                    let optData = response.data;
                    if (optData != "") {
                      this.optOperationsVal = optData.operationsCount;
                      this.optLeadTimeVal = optData.leadTime;
                      this.optAvLeadTimeVal = optData.avgLeadTime;
                      this.optimizedDeliveryReliability =
                        optData.deliveryReliability;

                      let parsedData = JSON.parse(optData.ganttDataList);
                      this.optimizedData = parsedData.treatedList;

                      this.originOptimizedChartStartDate =
                        parsedData.chartStartDate;
                      this.originOptimizedChartEndDate =
                        parsedData.chartEndDate;

                      // this.chartCurrentStartDate = parsedData.chartCurrentStartDate;
                      // this.chartCurrentEndDate = parsedData.chartCurrentEndDate;

                      // this.optimizedOuterViewportStart = this.rawOuterViewportStart;
                      // this.optimizedOuterViewportEnd = this.rawOuterViewportEnd;

                      this.optimizedOuterViewportStart =
                        parsedData.outerViewportStart;
                      this.optimizedOuterViewportEnd =
                        parsedData.outerViewportEnd;

                      this.optimizedTopViewport = parsedData.topViewport;
                      this.optimizedBottomViewport = parsedData.bottomViewport;

                      if (this.initialOptimizedViewportStart == "") {
                        this.initialOptimizedViewportStart =
                          parsedData.chartCurrentStartDate;
                        // this.rawOuterViewportStart;
                      }

                      if (this.initialOptimizedViewportEnd == "") {
                        this.initialOptimizedViewportEnd =
                          parsedData.chartCurrentEndDate;
                        // this.rawOuterViewportEnd;
                      }

                      this.setOptimizedViewportDates(
                        // this.currentRawChartStartDate,
                        // this.currentRawChartEndDate
                        parsedData.chartCurrentStartDate,
                        parsedData.chartCurrentEndDate
                      );

                      this.optimizationLoaded = true;

                      if (this.selectedCSV) {
                        let currentOptimization = this.executedOptimizations.filter(
                          item => item.code == this.selectedCSV.code
                        )[0];
                        this.selectedOptimization = currentOptimization;
                      }
                    }

                    // this.setProcessing(false, "optimized");
                  }
                  this.setProcessing(false, "optimized");
                })
                .catch(() => {
                  this.loaded = false
                  // this.optimizationLoaded = false;
                  this.setProcessing(false, "optimized");
                  this.emitter.emit("optimizationLoaded", false);
                });
            }
          }
          this.mountListenersAgain();
          this.setProcessing(false, "raw");
        })
        .catch(() => {
          this.mountListenersAgain();
          this.setProcessing(false, "raw");
        });
    },
    onChangeSelectedOptimization(event) {
      this.noAnimation = false;
      this.resetCSVOptimizedKPIs();
      this.resetCSVRawKPIs();

      this.emitter.emit("resetDashboard");

      this.optimizationLoaded = false;

      let currentOptimization = this.filesNames.filter(
        item => item.code == event.value.code
      )[0];
      this.selectedCSV = currentOptimization;

      this.setProcessing(true, "raw");
      APIService.api
        .getFileByFileId(
          event.value["code"],
          null,
          null,
          null,
          null,
          false,
          true
        )
        .then(response => {
          if (response != null) {
            let data = response.data;

            this.rawOperationsVal = data.operationsCount;
            this.rawLeadTimeVal = data.leadTime;
            this.rawAvLeadTimeVal = data.avgLeadTime;
            this.rawDeliveryReliability = data.deliveryReliability;

            let rawParsedData = JSON.parse(data.ganttDataList);
            this.rawData = rawParsedData.treatedList;

            this.originRawChartStartDate = rawParsedData.chartStartDate;
            this.originRawChartEndDate = rawParsedData.chartEndDate;

            this.rawOuterViewportStart = rawParsedData.outerViewportStart;
            this.rawOuterViewportEnd = rawParsedData.outerViewportEnd;

            this.rawTopViewport = rawParsedData.topViewport;
            this.rawBottomViewport = rawParsedData.bottomViewport;

            if (this.initialRawViewportStart == "") {
              this.initialRawViewportStart =
                rawParsedData.chartCurrentStartDate;
            }

            if (this.initialRawViewportEnd == "") {
              this.initialRawViewportEnd = rawParsedData.chartCurrentEndDate;
            }

            this.setRawViewportDates(
              rawParsedData.chartCurrentStartDate,
              rawParsedData.chartCurrentEndDate
            );

            this.setProcessing(false, "raw");
            this.setProcessing(true, "optimized");
            APIService.api
              .getOptimizedFileByRawFileId(
                this.selectedCSV.code,
                this.initialRawViewportStart,
                this.initialRawViewportEnd,
                this.rawTopViewport,
                this.rawBottomViewport,
                false,
                true
              )
              .then(response => {
                if (response != null) {
                  this.emitter.emit("optimizationLoaded", true);
                  let optData = response.data;
                  this.optOperationsVal = optData.operationsCount;
                  this.optLeadTimeVal = optData.leadTime;
                  this.optAvLeadTimeVal = optData.avgLeadTime;
                  this.optimizedDeliveryReliability =
                    optData.deliveryReliability;

                  let parsedData = JSON.parse(optData.ganttDataList);
                  this.optimizedData = parsedData.treatedList;

                  this.originOptimizedChartStartDate =
                    parsedData.chartStartDate;
                  this.originOptimizedChartEndDate = parsedData.chartEndDate;

                  // this.optimizedOuterViewportStart = this.rawOuterViewportStart;
                  // this.optimizedOuterViewportEnd = this.rawOuterViewportEnd;

                  this.optimizedOuterViewportStart =
                    parsedData.outerViewportStart;
                  this.optimizedOuterViewportEnd = parsedData.outerViewportEnd;

                  this.optimizedTopViewport = parsedData.topViewport;
                  this.optimizedBottomViewport = parsedData.bottomViewport;

                  if (this.initialOptimizedViewportStart == "") {
                    this.initialOptimizedViewportStart =
                      parsedData.chartCurrentStartDate;
                    // this.rawOuterViewportStart;
                  }

                  if (this.initialOptimizedViewportEnd == "") {
                    this.initialOptimizedViewportEnd =
                      parsedData.chartCurrentEndDate;
                    // this.rawOuterViewportEnd;
                  }

                  this.setOptimizedViewportDates(
                    // this.currentRawChartStartDate,
                    // this.currentRawChartEndDate
                    parsedData.chartCurrentStartDate,
                    parsedData.chartCurrentEndDate
                  );

                  this.optimizationLoaded = true;
                  this.setProcessing(false, "optimized");
                }
                this.setProcessing(false, "optimized");
              }).catch(() => {
                  this.emitter.emit("optimizationLoaded", false);
                });
          }
          this.mountListenersAgain();
          this.setProcessing(false, "raw");
        });
    },

    runOptimization() {
      let completed = false;
      this.setProcessing(true, "run-optimization");
      this.emitter.emit("disableControlStates", true);
      APIService.api.checkAxxalonPlanHealth().then(() => {
        // this.processing = true;
        // this.setProcessing(true, "run-optimization");
        APIService.api
          .optimizeRawDataInAxxalonPlan(
            this.selectedCSV.code,
            this.selectedCSV.processUUID,
            this.currentRawChartStartDate,
            this.currentRawChartEndDate,
            this.rawTopViewport,
            this.rawBottomViewport
          )
          .then(response => {
            this.noAnimation = false;
            this.updateOptimizationsCount();
            let data = response.data;
            this.optOperationsVal = data.operationsCount;
            this.optLeadTimeVal = data.leadTime;
            this.optAvLeadTimeVal = data.avgLeadTime;
            this.optimizedDeliveryReliability = data.deliveryReliability;
            let parsedData = JSON.parse(data.ganttDataList);
            this.optimizedData = parsedData.treatedList;
            this.originOptimizedChartStartDate = parsedData.chartStartDate;
            this.originOptimizedChartEndDate = parsedData.chartEndDate;
            this.optimizedOuterViewportStart = this.rawOuterViewportStart;
            this.optimizedOuterViewportEnd = this.rawOuterViewportEnd;

            this.optimizedTopViewport = this.rawTopViewport;
            this.optimizedBottomViewport = this.rawBottomViewport;
            // this.optimizedOuterViewportStart = parsedData.outerViewportStart;
            // this.optimizedOuterViewportEnd = parsedData.outerViewportEnd;
            if (this.initialOptimizedViewportStart == "") {
              this.initialOptimizedViewportStart = this.currentRawChartStartDate;
              // parsedData.outerViewportStart;
            }
            if (this.initialOptimizedViewportEnd == "") {
              this.initialOptimizedViewportEnd = this.currentRawChartEndDate;
              // parsedData.outerViewportEnd;
            }

            this.setOptimizedViewportDates(
              this.currentRawChartStartDate,
              this.currentRawChartEndDate
              // parsedData.chartCurrentStartDate,
              // parsedData.chartCurrentEndDate
            );
            this.optimizationLoaded = true;
            this.setProcessing(false, "run-optimization");
            this.emitter.emit("disableControlStates", false);
            // this.processing = false;
            completed = true;
            this.$toast.add({
              severity: "success",
              summary: "Optimization executed with success!",
              detail: "Now you can check the results.",
              life: 3500
            });
          })
          .catch(error => {
            completed = false;
            this.processing = false;
            this.message = error.response.data.message;
            // this.$toast.add({
            //   severity: "error",
            //   summary: this.message,
            //   detail: "",
            //   life: 3500
            // });
          }).finally(() => {
                if (! completed){
                  console.log("Retrying, please wait...")
                  // this.processing = true;
                  this.setProcessing(true, "run-optimization");
                  setTimeout(() => {
                     this.runOptimization();
                  }, 1000 * 60 * 0.2)
                }
            });
      });
    },
    openCSVFile() {
      const fileDialogButton = document.getElementById("openCSVFile");
      fileDialogButton.click().then(() => {});
    },
    goHome() {
      this.$router.push({ name: "Home", params: { newSpeed: 0.1 } });
    },
    goLogin() {
      this.$router.push({ name: "Login" });
    },
    goRegister() {
      this.$router.push({ name: "Register" });
    },
    reloadConfig(config) {
      if (config.action == "logout") {
        this.$router.push(config.route).then(() => {
          this.showGlass = true;
        });
      } else if (config.action == "login") {
        this.$router.push(config.route);
      }
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    isAtWelcomePages() {
      if (this.$store.state.user == null) {
        return true;
      } else return false;
    },
    detectMobile() {
      return window.innerWidth <= 800 && window.innerHeight <= 600;
    },
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },
    mountListenersAgain() {
      // this.emitter.on("disableMenusStates", state => {
      //   this.disableMenuControls = state;
      // });

      this.emitter.on("setProcessing", state => {
        this.setProcessing(true, state);
      });

      this.emitter.on("unsetProcessing", state => {
        this.setProcessing(false, state);
      });


      this.languageBus.on("LanguageStateBus", () => {
        this.$forceUpdate();
      });

    this.emitter.on("optimizationLoaded", state => {
      this.optimizationLoaded = state;      
    })

      // this.emitter.on("setUpperChartData", response => {
      //   this.setDataOnUpperChart(response);
      // })

      // this.emitter.on("setLowerChartData", response => {
      //   this.setDataOnLowerChart(response);
      // })

      this.emitter.on("setBothChartData", result  => {
        if (result != null){
          this.setDataOnBothChart(result.data.rawFile, result.data.optimizedFile);
        } else {
          this.setDataOnBothChart(null, null)
        }
        
      })

      this.emitter.on("setSearchMode", state => {
        this.searchMode = state;
      })


      this.emitter.on("restoreBackupViewport", () => {
        // console.log("RESTORING BACKUPS")
        // console.log("raw")
        // console.log(this.currentRawChartStartDateBackup)
        // console.log(this.currentRawChartEndDateBackup)

        // console.log("optimized")

        // console.log(this.currentOptimizedChartStartDateBackup)
        // console.log(this.currentOptimizedChartEndDateBackup)

        this.setRawViewportDates(
          this.currentRawChartStartDateBackup,
          this.currentRawChartEndDateBackup
        );

        this.setOptimizedViewportDates(
            this.currentOptimizedChartStartDateBackup,
            this.currentOptimizedChartEndDateBackup
        );
    })


    this.emitter.on("reloadFile", fileId => {
      this.onChangeSelectedCSVFile(null, fileId)
    })
      // this.emitter.on("alterPercentageState", percentage => {
      //   this.scrollPercentage = percentage;
      // });

      this.emitter.on("updateBothVerticalHorizontalViewport", params => {
        this.rawTopViewport = params.rawTop;
        this.rawBottomViewport = params.rawBottom;
        this.currentRawChartStartDate = params.rawChartStartDate;
        this.currentRawChartEndDate = params.rawChartEndDate;

        this.optimizedTopViewport = params.optimizedTop;
        this.optimizedBottomViewport = params.optimizedBottom;
        this.currentOptimizedChartStartDate = params.optimizedChartStartDate;
        this.currentOptimizedChartEndDate = params.optimizedChartEndDate;
      });

      this.emitter.on("updateOptimizedVerticalHorizontalViewport", params => {
        this.optimizedTopViewport = params.optimizedTop;
        this.optimizedBottomViewport = params.optimizedBottom;
        this.currentOptimizedChartStartDate = params.optimizedChartStartDate;
        this.currentOptimizedChartEndDate = params.optimizedChartEndDate;
      });

      this.emitter.on("updateRawVerticalHorizontalViewport", params => {
        this.rawTopViewport = params.rawTop;
        this.rawBottomViewport = params.rawBottom;
        this.currentRawChartStartDate = params.rawChartStartDate;
        this.currentRawChartEndDate = params.rawChartEndDate;
      });

      this.emitter.on("updateUpperExpandedVerticalPagination", state => {
        this.onRawPaginationRefresh(
          this.currentRawChartStartDate,
          this.currentRawChartEndDate,
          -1,
          -1,
          true,
          state
        );
      });

      this.emitter.on("updateLowerExpandedVerticalPagination", state => {
        this.onOptimizedPaginationRefresh(
          this.currentOptimizedChartStartDate,
          this.currentOptimizedChartEndDate,
          -1,
          -1,
          true,
          state
        );
      });

      let verticalPaginationStepModulator = 2.5;

      this.emitter.on("updateUpperTopVerticalPagination", () => {
        let rawDistance = this.rawBottomViewport - this.rawTopViewport;
        this.onRawPaginationRefresh(
          this.currentRawChartStartDate,
          this.currentRawChartEndDate,
          (
            this.rawTopViewport -
            rawDistance / verticalPaginationStepModulator
          ).toFixed(0),
          (
            this.rawBottomViewport -
            rawDistance / verticalPaginationStepModulator
          ).toFixed(0),
          true
        );
      });

      this.emitter.on("updateLowerTopVerticalPagination", () => {
        let optimizedDistance =
          this.optimizedBottomViewport - this.optimizedTopViewport;
        this.onOptimizedPaginationRefresh(
          this.currentOptimizedChartStartDate,
          this.currentOptimizedChartEndDate,
          (
            this.optimizedTopViewport -
            optimizedDistance / verticalPaginationStepModulator
          ).toFixed(0),
          (
            this.optimizedBottomViewport -
            optimizedDistance / verticalPaginationStepModulator
          ).toFixed(0),
          true
        );
      });

      this.emitter.on("updateUpperBottomVerticalPagination", () => {
        let rawDistance = this.rawBottomViewport - this.rawTopViewport;

        this.onRawPaginationRefresh(
          this.currentRawChartStartDate,
          this.currentRawChartEndDate,
          (
            this.rawTopViewport +
            rawDistance / verticalPaginationStepModulator
          ).toFixed(0),
          (
            this.rawBottomViewport +
            rawDistance / verticalPaginationStepModulator
          ).toFixed(0),
          true
        );
      });

      this.emitter.on("updateLowerBottomVerticalPagination", () => {
        let optimizedDistance =
          this.optimizedBottomViewport - this.optimizedTopViewport;
        this.onOptimizedPaginationRefresh(
          this.currentOptimizedChartStartDate,
          this.currentOptimizedChartEndDate,
          (
            this.optimizedTopViewport +
            optimizedDistance / verticalPaginationStepModulator
          ).toFixed(0),
          (
            this.optimizedBottomViewport +
            optimizedDistance / verticalPaginationStepModulator
          ).toFixed(0),
          true
        );
      });
    },

    configureLanguage() {
      this.language =
        localStorage.getItem("language") != null
          ? localStorage.getItem("language")
          : "de";

      this.signInButtonLabel = this.capitalize(
        this.t("sign_in", {}, { locale: this.language })
      );
      this.registerButtonLabel = this.capitalize(
        this.t("register", {}, { locale: this.language })
      );

      this.confirmButtonLabel = this.t("yes", {}, { locale: this.language });
      this.cancelButtonLabel = this.t("no", {}, { locale: this.language });

      this.tooltipOptimizationArray = new Array(
        this.t("run_optimization", {}, { locale: this.language }),
        this.t(
          "a_optimization_for_the_selected_file_already_exist",
          {},
          { locale: this.language }
        ),
        this.t(
          "please_select_or_import_csv_file_to_run_a_optimization",
          {},
          { locale: this.language }
        )
      );

      this.isGPUAvailableArray = new Array(
        this.t("enable_gpu_processing", {}, { locale: this.language }),
        this.t("gpu_not_available", {}, { locale: this.language })
      );

      this.selectOptimizationArray = new Array(
        this.t("select_a_optimization", {}, { locale: this.language }),
        this.t("no_optimizations_available", {}, { locale: this.language })
      );

      this.selectFilesArray = new Array(
        this.t("select_imported_csv", {}, { locale: this.language }),
        this.t("no_files_imported", {}, { locale: this.language })
      );

      this.bodyTextLeftHalfDeleteConfirm = this.t(
        "are_you_sure_you_want_to_delete",
        {},
        { locale: this.language }
      );

      this.bodyTextRightHalfDeleteConfirm =
        this.t("file", {}, { locale: this.language }) + "?";
      this.headerTextDeleteConfirm = this.t(
        "delete_file",
        {},
        { locale: this.language }
      );
      this.tooltipDeleteConfirmArray = new Array(
        this.t("delete_selected_file", {}, { locale: this.language }),
        this.t("no_csv_file_selected", {}, { locale: this.language })
      );
    }
  },
  computed: {
    simpleModeInputButtonVisibility() {
      let content = this.advancedMode ? "hidden" : "visible";
      return "visibility:" + content;
    },
    advancedModeInputButtonVisibility() {
      let content = this.advancedMode ? "visible" : "hidden";
      return "visibility:" + content;
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false
        }
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light"
        }
      ];
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? "assets/layout/images/logo-white.svg"
        : "assets/layout/images/logo.svg";
    }
  },
  beforeUpdate() {
    // this.noAnimation = false;
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");

    this.loggedIn = localStorage.getItem("user") != null;
    if (this.loggedIn) {
      this.updateFilesCount();
      this.updateOptimizationsCount();
      APIService.api.deleteSentinels().then(() => {});
    }

    this.emitter.emit("showGlass", true);
    this.optimizedFileLoaded = false;

    this.configureLanguage();
  },
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },

  updated() {
    this.loggedIn = localStorage.getItem("user") != null;

    if (!this.loggedIn) {
      this.$router.push({ name: "Home" });
    }
  },

  mounted() {
    this.noAnimation = false;
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });

    // this.emitter.on("disableMenusStates", state => {
    //   console.log("ON DISABLE MENUS STATES- App.vue:" + state);
    //   // this.inactiveMenusState = state;
    //     this.disableMenuControls = state;
    // });

    // SNAP LISTENER
    this.emitter.on("snapState", state => {
      this.snap = state
    })

    this.emitter.on("restoreBackupViewport", () => {
        // console.log("RESTORING BACKUPS")
        // console.log("raw")
        // console.log(this.currentRawChartStartDateBackup)
        // console.log(this.currentRawChartEndDateBackup)

        // console.log("optimized")

        // console.log(this.currentOptimizedChartStartDateBackup)
        // console.log(this.currentOptimizedChartEndDateBackup)

        this.setRawViewportDates(
          this.currentRawChartStartDateBackup,
          this.currentRawChartEndDateBackup
        );

        this.setOptimizedViewportDates(
            this.currentOptimizedChartStartDateBackup,
            this.currentOptimizedChartEndDateBackup
        );
    })

    this.emitter.on("reloadFile", fileId => {
      this.onChangeSelectedCSVFile(null, fileId)
    })

    this.emitter.on("disableMenuControls", state => {
      // console.log("disableMenuControls: " + state)
      this.disbaleMenuControls = state;
    })

    // this.emitter.on("alterPercentageState", percentage => {
    //   this.scrollPercentage = percentage;
    // });

    this.emitter.on("updateBothVerticalHorizontalViewport", params => {
      this.rawTopViewport = params.rawTop;
      this.rawBottomViewport = params.rawBottom;
      this.currentRawChartStartDate = params.rawChartStartDate;
      this.currentRawChartEndDate = params.rawChartEndDate;

      this.optimizedTopViewport = params.optimizedTop;
      this.optimizedBottomViewport = params.optimizedBottom;
      this.currentOptimizedChartStartDate = params.optimizedChartStartDate;
      this.currentOptimizedChartEndDate = params.optimizedChartEndDate;
    });

    this.emitter.on("updateOptimizedVerticalHorizontalViewport", params => {
      this.optimizedTopViewport = params.optimizedTop;
      this.optimizedBottomViewport = params.optimizedBottom;
      this.currentOptimizedChartStartDate = params.optimizedChartStartDate;
      this.currentOptimizedChartEndDate = params.optimizedChartEndDate;
    });

    this.emitter.on("updateRawVerticalHorizontalViewport", params => {
      this.rawTopViewport = params.rawTop;
      this.rawBottomViewport = params.rawBottom;
      this.currentRawChartStartDate = params.rawChartStartDate;
      this.currentRawChartEndDate = params.rawChartEndDate;
    });

    this.emitter.on("updateUpperExpandedVerticalPagination", state => {
      this.onRawPaginationRefresh(
        this.currentRawChartStartDate,
        this.currentRawChartEndDate,
        -1,
        -1,
        true,
        state
      );
    });

    this.emitter.on("updateLowerExpandedVerticalPagination", state => {
      this.onOptimizedPaginationRefresh(
        this.currentOptimizedChartStartDate,
        this.currentOptimizedChartEndDate,
        -1,
        -1,
        true,
        state
      );
    });

    let verticalPaginationStepModulator = 2.5;

    this.emitter.on("updateUpperTopVerticalPagination", () => {
      let rawDistance = this.rawBottomViewport - this.rawTopViewport;
      this.onRawPaginationRefresh(
        this.currentRawChartStartDate,
        this.currentRawChartEndDate,
        (
          this.rawTopViewport -
          rawDistance / verticalPaginationStepModulator
        ).toFixed(0),
        (
          this.rawBottomViewport -
          rawDistance / verticalPaginationStepModulator
        ).toFixed(0),
        true
      );
    });

    this.emitter.on("updateLowerTopVerticalPagination", () => {
      let optimizedDistance =
        this.optimizedBottomViewport - this.optimizedTopViewport;
      this.onOptimizedPaginationRefresh(
        this.currentOptimizedChartStartDate,
        this.currentOptimizedChartEndDate,
        (
          this.optimizedTopViewport -
          optimizedDistance / verticalPaginationStepModulator
        ).toFixed(0),
        (
          this.optimizedBottomViewport -
          optimizedDistance / verticalPaginationStepModulator
        ).toFixed(0),
        true
      );
    });

    this.emitter.on("updateUpperBottomVerticalPagination", () => {
      let rawDistance = this.rawBottomViewport - this.rawTopViewport;
      this.onRawPaginationRefresh(
        this.currentRawChartStartDate,
        this.currentRawChartEndDate,
        (
          this.rawTopViewport +
          rawDistance / verticalPaginationStepModulator
        ).toFixed(0),
        (
          this.rawBottomViewport +
          rawDistance / verticalPaginationStepModulator
        ).toFixed(0),
        true
      );
    });

    this.emitter.on("updateLowerBottomVerticalPagination", () => {
      let optimizedDistance =
        this.optimizedBottomViewport - this.optimizedTopViewport;
      this.onOptimizedPaginationRefresh(
        this.currentOptimizedChartStartDate,
        this.currentOptimizedChartEndDate,
        (
          this.optimizedTopViewport +
          optimizedDistance / verticalPaginationStepModulator
        ).toFixed(0),
        (
          this.optimizedBottomViewport +
          optimizedDistance / verticalPaginationStepModulator
        ).toFixed(0),
        true
      );
    });

    this.emitter.emit("showGlass", true);
    this.loggedIn = localStorage.getItem("user") != null;

    this.configureLanguage();

    this.selectedLanguage = this.languages.filter(
      item => item.code == this.language
    )[0];
  },
  created() {
    this.noAnimation = false;
    this.emitter.on("setProcessing", state => {
      this.setProcessing(true, state);
    });

    this.emitter.on("unsetProcessing", state => {
      this.setProcessing(false, state);
    });

    this.loggedIn = localStorage.getItem("user") != null;
    this.language =
      localStorage.getItem("language") != null
        ? localStorage.getItem("language")
        : "de";

    if (this.loggedIn) {
      this.updateFilesCount();
      this.updateOptimizationsCount();
      APIService.api.deleteTransientCSVFiles().then(() => {});
      APIService.api.deleteSentinels().then(() => {});
    }

    if (this.loggedIn && this.$route.path != "/dashboard") {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>

<style lang="scss">
@import "./App.scss";
@import "./assets/styles/global.scss"; // For theming
.light-blur-box {
  // background-image: url("./assets/images/teste-background.jpg");
  background-color: #f2f3f7;
  // background: linear-gradient(-180deg, #fcfcfc, #cdd6e5);
  // background: linear-gradient(
  //   -180deg,
  //   #fcfcfc,
  //   rgba(205, 214, 229, 0.2),
  //   #fcfcfc
  // );
  // background: repeating-linear-gradient(
  //   -45deg,
  //   rgba(252, 252, 252, 0.01),
  //   rgba(252, 252, 252, 0.01) 10px,
  //   rgba(205, 214, 229, 0.01) 10px,
  //   rgba(205, 214, 229, 0.01) 20px
  // );
  // background: linear-gradient(
  //   45deg,
  //   #ffffff,
  //   #cdd6e5,
  //   #ffffff,
  //   #cdd6e5,
  //   #ffffff
  // );

  // background: linear-gradient(-180deg, #15baea, #58ee1c);
  // background: linear-gradient(-180deg, #afecff, #e0fbd5);
}
/////////////////////////
// CUSTOMIZING MENUBAR //
// div.p-menubar,
// a.p-menubar-button,
// ul.p-menubar-root-list,
// li.p-menuitem,
// a.p-menuitem-link,
// ul.p-submenu-list,
// span.p-ink {
//   background-color: #25528a !important;
// }

// .csv-file {
//   background-image: "./assets/images/csv-file.png";
// }

.play-icon {
  background-image: url("/src/assets/images/play-icon-black.png");
}

.logo-small-container {
  width: 25%;
  margin: 0 auto;
}

.logo-small {
  width: 50%;
  height: auto;
}

.blur-box {
  background-color: #f6f2f6;
  box-shadow: 0 0 5px 15px #f6f2f6;
}

.nav-bar {
  background: linear-gradient(-90deg, #8eafd6, #f5a742);
  background: linear-gradient(-90deg, #8cdbe9, #25528a);
  height: 50px;
  margin: 0px;
  width: 100%;
}
</style>
