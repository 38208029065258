<template>
  <div class="animated-card p-d-flex p-flex-column p-ai-strech">
    <div class="p-d-flex p-jc-center">
      <Card class="p-mt-6 no-caret" style="width: 25rem; margin-bottom: 2em;">
        <template #header>
          <div style="p-mb-2 height: 30px;">
            <!-- <img
              class="no-drag p-mt-2"
              style="border-radius: 10px;"
              alt="user header"
              src="../assets/images/axxalon-plan-experience-blue.png"
            /> -->
            <img
              class="no-drag p-mt-2"
              style="border-radius: 10px;"
              alt="user header"
              src="../assets/images/axxalon-plan-experience-presentation-red.png"
            />
          </div>
        </template>

        <template #title class="p-d-flex p-jc-center">
          {{
            capitalize(
              t("sign_in_with_credentials", {}, { locale: localLanguage })
            )
          }}</template
        >

        <template #content>
          <AxxalonInputText
            type="email"
            :placeholder="emailPlaceholder"
            iconPos="left"
            iconType="pi-envelope"
            :required="true"
            :validator="validateForm"
            :setValue="internalEmail"
            @change="updateEmailFieldValues"
            @validState="setEmailValidState"
          ></AxxalonInputText>

          <AxxalonInputText
            type="password"
            :placeholder="passwordPlaceholder"
            iconPos="left"
            :required="true"
            :validator="validateForm"
            @change="updatePasswordFieldValues"
            @validState="setPasswordValidState"
          ></AxxalonInputText>

          <div class="p-d-flex p-flex-row p-mb-1">
            <small :v-if="this.errorMessage.length > 0" class="p-error">{{
              this.errorMessage
            }}</small>
          </div>
        </template>

        <template #footer>
          <div class="p-d-flex p-flex-column">
            <Button :label="buttonLabel" class="p-as-center" @click="login" />
            <router-link class="p-mt-4 p-as-center" :to="{ name: 'Register' }">
              {{
                capitalize(
                  t("dont_have_account_yet", {}, { locale: localLanguage })
                )
              }}
              {{
                capitalize(t("register", {}, { locale: localLanguage }))
              }}.</router-link
            >
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import gsap from "gsap";
import AxxalonInputText from "@/components/Axxalon/AxxalonInputText.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    AxxalonInputText: AxxalonInputText
  },
  setup() {
    const store = useStore();

    const showPassword = ref(false);

    const { t, locale } = useI18n();
    return { store, showPassword, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      emailPlaceholder: this.capitalize(this.t("email", {}, { locale: "de" })),
      passwordPlaceholder: this.capitalize(
        this.t("password", {}, { locale: "de" })
      ),
      buttonLabel: this.capitalize(this.t("sign_in", {}, { locale: "de" })),
      internalEmail: null,
      email: "",
      password: "",
      rememberMe: false,
      errorMessage: "",
      emailValidState: false,
      passwordValidState: false,
      invalidForm: true,
      validateForm: false
    };
  },
  mounted() {
    this.internalEmail = this.$route.params.newEmail;

    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    (this.emailPlaceholder = this.capitalize(
      this.t("email", {}, { locale: this.localLanguage })
    )),
      (this.passwordPlaceholder = this.capitalize(
        this.t("password", {}, { locale: this.localLanguage })
      )),
      (this.buttonLabel = this.capitalize(
        this.t("sign_in", {}, { locale: this.localLanguage })
      ));

    let tl = gsap.timeline();
    tl.from(".animated-card", {
      x: 400,
      opacity: 0,
      duration: 0.5,
      ease: "Expo.easeOutIn"
    });
  },
  beforeUpdate() {
    this.validate = false;
    this.internalEmail = this.$route.params.newEmail;

    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.emailPlaceholder = this.capitalize(
      this.t("email", {}, { locale: this.localLanguage })
    );
    this.passwordPlaceholder = this.capitalize(
      this.t("password", {}, { locale: this.localLanguage })
    );
    this.buttonLabel = this.capitalize(
      this.t("sign_in", {}, { locale: this.localLanguage })
    );
  },

  methods: {
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },
    checkAllStates() {
      if (this.emailValidState && this.passwordValidState) {
        this.invalidForm = false;
      } else {
        this.invalidForm = true;
      }
      this.errorMessage = "";
    },
    setEmailValidState(state) {
      this.emailValidState = state;
      this.checkAllStates();
    },
    setPasswordValidState(state) {
      this.passwordValidState = state;
      this.checkAllStates();
    },
    updateEmailFieldValues(data) {
      this.email = data;
      this.checkAllStates();
    },
    updatePasswordFieldValues(data) {
      this.password = data;
      this.checkAllStates();
    },
    login() {
      this.store
        .dispatch("login", {
          username: this.email,
          password: this.password
          // rememberMe: this.rememberMe,
        })
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(err => {
          this.errorMessage = err.response.data.message;
        });
    }
    // },
  }
};
</script>

<style lang="scss" scoped></style>
