<template>
  <Button
    class="control-button-wide cutted-b-right p-button p-component p-fileupload-choose dynamic-file-upload-button"
    :label="importButtonLabel"
    icon="p-button-icon p-button-icon-left pi pi-fw pi-plus"
    :disabled="disableMenuControls || disableMenuControlsOnCreation"
    @click="openBasic"
  />

  <Dialog
    :header="dialogHeader"
    :style="{ width: '50vw' }"
    v-model:visible="displayBasic"
    contentStyle="background: linear-gradient(180deg, 
      gainsboro, white); padding: 20%;"
    class=" "
    style="`--notchSize: 15px; opacity: 0.99;width: 50vw;"
  >
    <div style="height: 180px">
      <div
        v-if="stepsVisible"
        class="p-d-flex p-flex-row p-jc-around"
        style="margin-top: -30%; width: 100%"
      >
        <div class="p-d-flex p-flex-column">
          <div class="p-d-flex p-flex-row">
            <div :class="stepsAnimationClasses[1]">
              <WizardStep
                :stepNumberDefinition="1"
                :currentStepNumber="step"
                :stepName="jobsStepName"
                stepCode="01"
              />
            </div>
            <WizardChecker :stepCheckerState="successes[1]" />
          </div>
          <AxxalonProgressSpinner
            v-if="step == 1"
            :processing="processing"
            inputWidth="25px"
            inputHeight="25px"
          />
          <div
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 120px"
            v-if="jobsFilename || (!processing && step == 1)"
          >
            <span>{{ jobsFilename }}</span>
          </div>
        </div>

        <div class="p-d-flex p-flex-column">
          <div class="p-d-flex p-flex-row">
            <div :class="stepsAnimationClasses[2]">
              <WizardStep
                :stepNumberDefinition="2"
                :currentStepNumber="step"
                :stepName="shiftsStepName"
                stepCode="02"
              />
            </div>
            <WizardChecker :stepCheckerState="successes[2]" />
          </div>
          <AxxalonProgressSpinner
            v-if="step == 2"
            :processing="processing"
            inputWidth="25px"
            inputHeight="25px"
          />
          <div
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 120px"
            v-if="shiftsFilename || (!processing && step == 2)"
          >
            <span>{{ shiftsFilename }}</span>
          </div>
        </div>

        <div class="p-d-flex p-flex-column">
          <div class="p-d-flex p-flex-row">
            <div :class="stepsAnimationClasses[3]">
              <WizardStep
                :stepNumberDefinition="3"
                :currentStepNumber="step"
                :stepName="resourcesStepName"
                stepCode="03"
              />
            </div>
            <WizardChecker :stepCheckerState="successes[3]" />
          </div>
          <AxxalonProgressSpinner
            v-if="step == 3"
            :processing="processing"
            inputWidth="25px"
            inputHeight="25px"
          />
          <div
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 120px"
            v-if="resourcesFilename || (!processing && step == 3)"
          >
            <span>{{ resourcesFilename }}</span>
          </div>
        </div>

        <div class="p-d-flex p-flex-column">
          <div class="p-d-flex p-flex-row">
            <div :class="stepsAnimationClasses[4]">
              <WizardStep
                :stepNumberDefinition="4"
                :currentStepNumber="step"
                :stepName="operationsStepName"
                stepCode="04"
              />
            </div>
            <WizardChecker :stepCheckerState="successes[4]" />
          </div>
          <AxxalonProgressSpinner
            v-if="step == 4"
            :processing="processing"
            inputWidth="25px"
            inputHeight="25px"
          />
          <div
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 120px"
            v-if="operationsFilename || (!processing && step == 4)"
          >
            <span>{{ operationsFilename }}</span>
          </div>
        </div>
      </div>

      <WizardPanel v-if="step == 0" :panelText="welcomeMessage"> </WizardPanel>

      <div :class="panelsAnimationClasses[1]">
        <WizardPanel
          v-if="step == 1 && successes[1] == null"
          :panelText="firstStepMessage"
        >
          <img class="no-caret" src="../../../assets/images/jobs.png" />
        </WizardPanel>
      </div>
      <div :class="failureAnimationClasses[1]">
        <WizardPanel
          v-if="step == 1 && (successes[1] == true || successes[1] == false)"
          :panelText="
            successes[1] == true
              ? firstPanelMessagesArray[0]
              : successes[1] == false
              ? firstPanelMessagesArray[1]
              : ''
          "
          :errorHint="errorMessage"
          :hintText="hintText"
          :success="successes[1]"
        >
          <img
            v-if="errorMessage"
            class="no-caret"
            src="../../../assets/images/jobs.png"
          />
        </WizardPanel>
      </div>

      <div :class="panelsAnimationClasses[2]">
        <WizardPanel
          v-if="step == 2 && successes[2] == null"
          :panelText="secondStepMessage"
        >
          <img
            class="no-caret"
            src="../../../assets/images/shifts.png"
            style="max-height: 230px; !important"
          />
        </WizardPanel>
      </div>
      <div :class="failureAnimationClasses[2]">
        <WizardPanel
          v-if="step == 2 && (successes[2] == true || successes[2] == false)"
          :panelText="
            successes[2] == true
              ? secondPanelMessagesArray[0]
              : successes[2] == false
              ? secondPanelMessagesArray[1]
              : ''
          "
          :errorHint="errorMessage"
          :hintText="hintText"
          :success="successes[2]"
        >
          <img
            v-if="errorMessage"
            class="no-caret"
            style="max-height: 230px; !important"
            src="../../../assets/images/shifts.png"
          />
        </WizardPanel>
      </div>

      <div :class="panelsAnimationClasses[3]">
        <WizardPanel
          v-if="step == 3 && successes[3] == null"
          :panelText="thirdStepMessage"
        >
          <img class="no-caret" src="../../../assets/images/resources.png" />
        </WizardPanel>
      </div>
      <div :class="failureAnimationClasses[3]">
        <WizardPanel
          v-if="step == 3 && (successes[3] == true || successes[3] == false)"
          :panelText="
            successes[3] == true
              ? thirdPanelMessageArray[0]
              : successes[3] == false
              ? thirdPanelMessageArray[1]
              : ''
          "
          :errorHint="errorMessage"
          :hintText="hintText"
          :success="successes[3]"
        >
          <img
            v-if="errorMessage"
            class="no-caret"
            src="../../../assets/images/resources.png"
          />
        </WizardPanel>
      </div>

      <div :class="panelsAnimationClasses[4]">
        <WizardPanel
          v-if="step == 4 && successes[4] == null"
          :panelText="fourthStepMessage"
        >
          <img class="no-caret" src="../../../assets/images/operations.png" />
        </WizardPanel>
      </div>
      <div :class="failureAnimationClasses[4]">
        <WizardPanel
          v-if="step == 4 && (successes[4] == true || successes[4] == false)"
          :panelText="
            successes[4] == true
              ? fourthPanelMessageArray[0]
              : successes[4] == false
              ? fourthPanelMessageArray[1]
              : ''
          "
          :errorHint="errorMessage"
          :hintText="hintText"
          :success="successes[4]"
        >
          <img
            v-if="errorMessage"
            class="no-caret"
            src="../../../assets/images/operations.png"
          />
        </WizardPanel>
        <AxxalonConfirmDialogMessage
          :showConfirmDialog="showConfirmDialog"
          :bodyTextLeftHalf="confirmMessage"
          :specialContentText="confirmSpecialMessage"
          bodyTextRightHalf=""
          :headerText="confirmHeaderText"
          mainButtonClass="control-button-wide cutted-b-right p-ml-2"
          :mainButtonIcon="confirmIcon"
          :tooltipArray="new Array(null)"
          :tooltipSwitchKey="null"
          tooltipDirection="left"
          :disableMainButtonSwitchKey="false"
          :codeToRunInsideIfConfirmed="confirmShiftTimesCode"
          :codeToRunInsideIfCanceled="cancelShiftTimesCode"
          :confirmButtonLabel="confirmButtonLabel"
          :cancelButtonLabel="cancelButtonLabel"
        />
      </div>

      <div :class="failureAnimationClasses[5]">
        <WizardPanel
          v-if="step == 5"
          :panelText="
            warningMessage != null
              ? fifthPanelMessageArray[0]
              : fifthPanelMessageArray[1]
          "
          :errorHint="warningMessage"
          :hintText="hintText"
          :success="true"
          :warning="warningMessage != null"
        >
          <span class="p-mt-2" style="color: dodgerblue; font-size: 10px"
            ><b>{{ compoundFilenameHeader }}</b></span
          >
          <AxxalonInputText
            type="filename"
            :placeholder="nameForTheFilesCompound"
            :inputDisabled="disabledCompositionInputText"
            :required="true"
            :validator="true"
            @validState="setCompoundValidState"
            @change="updateFilenameFieldValue"
          />

          <div class="p-d-flex p-flex-row">
            <span
              class="p-mt-1 p-mr-1"
              style="color: dodgerblue; font-size: 10px"
              ><b>{{ timeWindowHeader }}</b></span
            >
            <div
              class="p-as-center"
              style="padding: 2px; color: dodgerblue !important"
            >
              <i
                style="font-size: 12px"
                class="pi pi-info-circle p-as-center"
                @mouseover="showTimeWindowInfo"
                @mouseleave="hideTimeWindowInfo"
              >
                <OverlayPanel
                  ref="timeWindowInfo"
                  appendTo="body"
                  style="width: 45%"
                >
                  <div class="p-d-flex p-flex-column">
                    <p style="font-size: 12px">
                      <b
                        >{{ timeWindowExplanation1 }}
                        <a class="axxalon-a">{{ timeWindowExplanation2 }}</a>
                        {{ timeWindowExplanation3 }}
                        <a class="axxalon-a">{{ timeWindowExplanation4 }}</a>
                        {{ timeWindowExplanation5 }}.</b
                      >
                    </p>
                    <img
                      src="../../../assets/images/time_window_tutorial_01.png"
                      style="width: 100%; height: auto"
                    />
                    <span style="font-size: 10px"
                      ><b
                        ><a>{{ timeWindowObservation }}</a></b
                      ></span
                    >
                  </div>
                </OverlayPanel>
              </i>
            </div>
          </div>

          <div class="p-d-flex p-flex-row p-mb-2">
            <span style="color: darkgray; font-size: 10px"> 600 DEFAULT</span>
          </div>
          <!-- <div class="p-d-flex p-flex-row">
            <AxxalonInputText
              type="number"
              :placeholder="numberOfDaysPlaceholder"
              :inputDisabled="disabledCompositionInputText"
              :required="true"
              :validator="true"
              @validState="setTimeWindowValidState"
              @change="updateTimeWindowFieldValue"
            />
          </div> -->

          
          <div class="p-d-flex p-flex-row">
             <div class="p-d-flex p-flex-column" style="width: 20%"> 
                <span
                  class="p-mt-1 p-mr-1"
                  style="color: dodgerblue; font-size: 10px"
                  ><b> {{ dataManagementHeader }}</b></span>
              </div> 
              <div class="p-d-flex p-flex-column" style="width: 80%">   
                  <span class="p-mt-1" style="color: #cacaca; font-size: 9px">{{weRecommendText}}</span>
              </div>
          </div>

          <div class="p-d-flex p-flex-row">
            <div style="padding: 2px; color: dodgerblue !important">
              <RadioButton
                name="dataManagementType"
                value="originalData"
                v-model="dataManagementType"
                :disabled="forceFinishButtonDisabled"
              />
              <span
                class="p-ml-1"
                :style="
                  localLanguage == 'de' ? 'font-size: 11px' : 'font-size: 12px'
                "
              >
                {{ originalDistributionText }}</span
              >
              <i
                style="font-size: 12px"
                class="pi pi-info-circle p-as-center p-ml-1"
                @mouseover="showOriginalDataTutorial"
                @mouseleave="hideOriginalDataTutorial"
              >
                <OverlayPanel
                  ref="originalDataTutorial"
                  appendTo="body"
                  style="width: 45%"
                >
                  <div class="p-d-flex p-flex-column">
                    <p style="font-size: 12px">
                      <b>{{ originalDataDistributionTutorialText }}.</b>
                    </p>
                    <img
                      src="../../../assets/images/original_data_distribution_tutorial_02.png"
                      style="width: 100%; height: auto"
                    />
                    <span style="font-size: 10px"
                      ><b
                        ><a
                          >{{
                            originalDataDistributionTutorialObservationText
                          }}.</a
                        ></b
                      ></span
                    >
                  </div>
                </OverlayPanel>
              </i>
            </div>
            <div
              class="p-ml-2"
              style="padding: 2px; color: dodgerblue !important"
            >
              <RadioButton
                name="dataManagementType"
                value="backwardData"
                v-model="dataManagementType"
                :disabled="forceFinishButtonDisabled"
              />
              <span
                class="p-ml-1"
                :style="
                  localLanguage == 'de' ? 'font-size: 11px' : 'font-size: 12px'
                "
              >
                {{ backwardDistributionText }}</span
              >
              <i
                style="font-size: 12px"
                class="pi pi-info-circle p-as-center p-ml-1"
                @mouseover="showBackwardDataTutorial"
                @mouseleave="hideBackwardDataTutorial"
              >
                <OverlayPanel
                  ref="backwardDataTutorial"
                  appendTo="body"
                  style="width: 45%"
                >
                  <div class="p-d-flex p-flex-column">
                    <p style="font-size: 12px">
                      <b> {{ backwardDataDistributionTutorialText }}.</b>
                    </p>
                    <img
                      src="../../../assets/images/backward_data_distribution_tutorial.png"
                      style="width: 100%; height: auto"
                    />
                    <span style="font-size: 10px"
                      ><b
                        ><a
                          >{{
                            backwardDataDistributionTutorialObservationText
                          }}.</a
                        ></b
                      ></span
                    >
                  </div>
                </OverlayPanel>
              </i>
            </div>

            <div
              class="p-ml-2"
              style="padding: 2px; color: dodgerblue !important"
            >
              <RadioButton
                name="dataManagementType"
                value="forwardData"
                v-model="dataManagementType"
                :disabled="forceFinishButtonDisabled"
              />
              <span
                class="p-ml-1"
                :style="
                  localLanguage == 'de' ? 'font-size: 11px' : 'font-size: 12px'
                "
              >
                {{ forwardDistributionText }}</span
              >
              <i
                style="font-size: 12px"
                class="pi pi-info-circle p-as-center p-ml-1"
                @mouseover="showForwardDataTutorial"
                @mouseleave="hideForwardDataTutorial"
              >
                <OverlayPanel
                  ref="forwardDataTutorial"
                  appendTo="body"
                  style="width: 45%"
                >
                  <div class="p-d-flex p-flex-column">
                    <p style="font-size: 12px">
                      <b> {{ forwardDataDistributionTutorialText }}.</b>
                    </p>
                    <img
                      src="../../../assets/images/forward_distribution_tutorial.png"
                      style="width: 100%; height: auto"
                    />
                    <span style="font-size: 10px"
                      ><b
                        ><a
                          >{{
                            forwardDataDistributionTutorialObservationText
                          }}.</a
                        ></b
                      ></span
                    >
                  </div>
                </OverlayPanel>
              </i>
            </div>
          </div>
        </WizardPanel>
        <div style="width: 175px;">
          <AxxalonProgressSpinner
            v-if="step == 5"
            :processing="processing"
            :overrideText="generateFileText"
            inputWidth="25px"
            inputHeight="25px"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="p-d-flex p-flex-row p-jc-end p-mt-4">
        <Button
          v-if="step != 5"
          :label="step > 0 ? stepButtonNamesArray[0] : stepButtonNamesArray[1]"
          :icon="step > 0 ? 'pi pi-angle-left' : 'pi pi-times'"
          @click="backOrCancelEvent"
          class="p-button-text no-caret"
          @failure="onFailure"
        />

        <div :class="animationClasses[1]">
          <AxxalonFileUpload
            v-if="step == 1 && (successes[1] == null || successes[1] == false)"
            :disabledInput="disabledInputs[1]"
            apiServiceCommand="jobsFile"
            @processing="setProcessing"
            @success="onSuccess"
            @failure="onFailure"
            @filename="setFilename"
          />
        </div>

        <div :class="animationClasses[2]">
          <AxxalonFileUpload
            v-if="step == 2 && (successes[2] == null || successes[2] == false)"
            :disabledInput="disabledInputs[2]"
            apiServiceCommand="shiftsFile"
            @processing="setProcessing"
            @success="onSuccess"
            @failure="onFailure"
            @filename="setFilename"
          />
        </div>

        <div :class="animationClasses[3]">
          <AxxalonFileUpload
            v-if="step == 3 && (successes[3] == null || successes[3] == false)"
            :disabledInput="disabledInputs[3]"
            apiServiceCommand="resourcesFile"
            @processing="setProcessing"
            @success="onSuccess"
            @failure="onFailure"
            @filename="setFilename"
          />
        </div>

        <div :class="animationClasses[4]">
          <AxxalonFileUpload
            v-if="step == 4 && (successes[4] == null || successes[4] == false)"
            :disabledInput="disabledInputs[4]"
            apiServiceCommand="operationsFile"
            @processing="setProcessing"
            @success="onSuccess"
            @failure="onFailure"
            @openConfirmation="onOpenConfirmation"
            @filename="setFilename"
            @returnMessage="onWarningReturnMessage"
          />
        </div>

        <Button
          v-if="step < 5"
          class="cutted-b-right p-ml-2 no-caret"
          :label="nextButtonLabel"
          :disabled="
            successes[step] == null
              ? true
              : successes[step] == true
              ? false
              : true
          "
          iconPos="right"
          icon="pi pi-angle-right"
          @click="next"
        />

        <Button
          v-if="step == 5"
          class="cutted-b-right p-ml-2 no-caret"
          :label="finishButtonLabel"
          :disabled="!finishValidState"
          iconPos="right"
          icon="pi pi-upload"
          @click="finish"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import WizardPanel from "./WizardComponents/WizardPanel.vue";
import WizardStep from "./WizardComponents/WizardStep.vue";
import WizardChecker from "./WizardComponents/WizardChecker.vue";
import AxxalonFileUpload from "../AxxalonFileUpload.vue";
import AxxalonInputText from "../AxxalonInputText.vue";
import AxxalonProgressSpinner from "../AxxalonProgressSpinner.vue";
import AxxalonConfirmDialogMessage from "../AxxalonConfirmDialogMessage.vue";
import gsap from "gsap";
import APIService from "../../../service/APIService";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  components: {
    WizardPanel,
    WizardStep,
    WizardChecker,
    AxxalonFileUpload,
    AxxalonInputText,
    AxxalonProgressSpinner,
    AxxalonConfirmDialogMessage
  },
  emits: ["updateAndSelectFile", "createAndSelectFile",  "disableMenusStates"],
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  props: {
    disableMenuControls: {type: Boolean},
    disableMenuControlsOnCreation: {type: Boolean, default: false}
  },
  data() {
    return {
      localLanguage: "de",

      weRecommendText: this.t(
        "we_recommend",
        {},
        { locale: "de" }
      ),

      originalDataDistributionTutorialText: this.t(
        "original_data_distribution_tutorial_text",
        {},
        { locale: "de" }
      ),
      originalDataDistributionTutorialObservationText: this.t(
        "original_data_distribution_tutorial_observation_text",
        {},
        { locale: "de" }
      ),
      backwardDataDistributionTutorialText: this.t(
        "backward_data_distribution_tutorial_text",
        {},
        { locale: "de" }
      ),
      backwardDataDistributionTutorialObservationText: this.t(
        "backward_data_distribution_tutorial_observation_text",
        {},
        { locale: "de" }
      ),
      forwardDataDistributionTutorialText: this.t(
        "forward_data_distribution_tutorial_text",
        {},
        { locale: "de" }
      ),
      forwardDataDistributionTutorialObservationText: this.t(
        "forward_data_distribution_tutorial_observation_text",
        {},
        { locale: "de" }
      ),

      originalDistributionText: this.t(
        "original_distribution_text",
        {},
        { locale: "de" }
      ),
      backwardDistributionText: this.t(
        "backward_distribution_text",
        {},
        { locale: "de" }
      ),
      forwardDistributionText: this.t(
        "forward_distribution_text",
        {},
        { locale: "de" }
      ),

      dataManagementHeader: this.t(
        "data_management_header",
        {},
        { locale: "de" }
      ),

      hintText: this.t("hint", {}, { locale: "de" }),
      confirmButtonLabel: this.t("yes", {}, { locale: "de" }),
      cancelButtonLabel: this.t("no", {}, { locale: "de" }),
      importButtonLabel: this.t("import_button_label", {}, { locale: "de" }),
      dialogHeader: this.t("dialog_header", {}, { locale: "de" }),
      jobsStepName: this.t("jobs_step_name", {}, { locale: "de" }),
      shiftsStepName: this.t("shifts_step_name", {}, { locale: "de" }),
      resourcesStepName: this.t("resources_step_name", {}, { locale: "de" }),
      operationsStepName: this.t("operations_step_name", {}, { locale: "de" }),
      welcomeMessage: this.t("welcome_message", {}, { locale: "de" }),
      firstStepMessage: this.t("first_step_message", {}, { locale: "de" }),
      firstPanelMessagesArray: new Array(
        this.t("first_panel_message_0", {}, { locale: "de" }),
        this.t("first_panel_message_1", {}, { locale: "de" })
      ),
      secondStepMessage: this.t("second_step_message", {}, { locale: "de" }),
      secondPanelMessageArray: new Array(
        this.t("second_panel_message_0", {}, { locale: "de" }),
        this.t("second_panel_message_1", {}, { locale: "de" })
      ),
      thirdStepMessage: this.t("third_step_message", {}, { locale: "de" }),
      thirdPanelMessageArray: new Array(
        this.t("third_panel_message_0", {}, { locale: "de" }),
        this.t("third_panel_message_1", {}, { locale: "de" })
      ),
      fourthStepMessage: this.t("fourth_step_message", {}, { locale: "de" }),
      fourthPanelMessageArray: new Array(
        this.t("fourth_panel_message_0", {}, { locale: "de" }),
        this.t("fourth_panel_message_1", {}, { locale: "de" })
      ),
      fifthPanelMessageArray: new Array(
        this.t("fifth_panel_message_0", {}, { locale: "de" }),
        this.t("fifth_panel_message_1", {}, { locale: "de" })
      ),
      compoundFilenameHeader: this.t(
        "compound_filename_header",
        {},
        { locale: "de" }
      ),
      nameForTheFilesCompound: this.t(
        "name_for_the_files_compound",
        {},
        { locale: "de" }
      ),
      timeWindowHeader: this.t("time_window_header", {}, { locale: "de" }),
      timeWindowExplanation1: this.t(
        "time_window_explanation_1",
        {},
        { locale: "de" }
      ),
      timeWindowExplanation2: this.t(
        "time_window_explanation_2",
        {},
        { locale: "de" }
      ),
      timeWindowExplanation3: this.t(
        "time_window_explanation_3",
        {},
        { locale: "de" }
      ),
      timeWindowExplanation4: this.t(
        "time_window_explanation_4",
        {},
        { locale: "de" }
      ),
      timeWindowExplanation5: this.t(
        "time_window_explanation_5",
        {},
        { locale: "de" }
      ),
      timeWindowObservation: this.t(
        "time_window_observation",
        {},
        { locale: "de" }
      ),
      numberOfDaysPlaceholder: this.t(
        "number_of_days_placeholder",
        {},
        { locale: "de" }
      ),
      generateFileText: this.t("generate_file_text", {}, { locale: "de" }),
      stepButtonNamesArray: new Array(
        this.t("step_button_names_0", {}, { locale: "de" }),
        this.t("step_button_names_1", {}, { locale: "de" })
      ),
      nextButtonLabel: this.t("next_button_label", {}, { locale: "de" }),
      finishButtonLabel: this.t("finish_button_label", {}, { locale: "de" }),

      forceFinishButtonDisabled: false,
      dataManagementType: null,
      warningMessage: null,
      formData: null,
      filename: null,
      showConfirmDialog: null,
      confirmMessage: null,
      confirmHeaderText: null,
      confirmSpecialMessage: null,
      confirmIcon: null,
      confirmCode: null,
      timeInterval: 2, // thirty minutes
      timeWindow: 600,
      disabledCompositionInputText: false,
      compositionFilename: null,
      jobsFilename: null,
      shiftsFilename: null,
      resourcesFilename: null,
      operationsFilename: null,
      compoundFileNameValidState: false,
      timeWindowValidState: false,
      dataManagementValidState: false,
      finishValidState: false,
      errorMessage: null,
      step: 0,
      successes: [true, null, null, null, null, true],
      disabledInputs: [true, false, false, false, false, true],
      animationClasses: [null, "first", "second", "third", "fourth", "fifth"],
      panelsAnimationClasses: [
        null,
        "panelfirst",
        "panelsecond",
        "panelthird",
        "panelfourth",
        "panelfifth"
      ],
      failureAnimationClasses: [
        null,
        "failurefirst",
        "failuresecond",
        "failurethird",
        "failurefourth",
        "failurefifth"
      ],
      stepsAnimationClasses: [
        null,
        "stepfirst",
        "stepsecond",
        "stepthird",
        "stepfourth",
        "stepfifth"
      ],
      processing: false,
      bannerVisible: true,
      stepsVisible: false,
      displayBasic: false
    };
  },
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },
  mounted() {
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });
    this.configureLanguageAttributes();
  },
  beforeUpdate() {
    if (!this.disableMenuControlsOnCreation){
      this.step = 0;
      this.stepsVisible = false;
      this.jobsFilename = null;
      this.shiftsFilename = null;
      this.resourcesFilename = null;
      this.operationsFilename = null;
      if (this.step != 5) {
        APIService.api.deleteTransientCSVFiles().then(() => {});
      }

      this.configureLanguageAttributes();
    }

  },
  watch: {
    dataManagementType: function(state) {
      this.setDataManagementState(state != null);
    },
    displayBasic: function(value) {
      this.step = 0;
      this.stepsVisible = false;
      this.successes = new Array(true, null, null, null, null, null);
      if (value == false) {
        APIService.api.deleteTransientCSVFiles().then(() => {});
        (this.successes = new Array(true, null, null, null, null, true)),
          (this.disabledInputs = new Array(
            true,
            false,
            false,
            false,
            false,
            true
          ));
      }
    }
  },
  methods: {
    configureLanguageAttributes() {
      this.localLanguage =
        localStorage.getItem("language") == null
          ? this.$store.state.language
          : localStorage.getItem("language");

      this.weRecommendText  = this.t(
        "we_recommend",
        {},
        { locale: this.localLanguage }
      );

      this.originalDataDistributionTutorialText = this.t(
        "original_data_distribution_tutorial_text",
        {},
        { locale: this.localLanguage }
      );
      this.originalDataDistributionTutorialObservationText = this.t(
        "original_data_distribution_tutorial_observation_text",
        {},
        { locale: this.localLanguage }
      );
      this.backwardDataDistributionTutorialText = this.t(
        "backward_data_distribution_tutorial_text",
        {},
        { locale: this.localLanguage }
      );
      this.backwardDataDistributionTutorialObservationText = this.t(
        "backward_data_distribution_tutorial_observation_text",
        {},
        { locale: this.localLanguage }
      );
      this.forwardDataDistributionTutorialText = this.t(
        "forward_data_distribution_tutorial_text",
        {},
        { locale: this.localLanguage }
      );
      this.forwardDataDistributionTutorialObservationText = this.t(
        "forward_data_distribution_tutorial_observation_text",
        {},
        { locale: this.localLanguage }
      );

      this.originalDistributionText = this.t(
        "original_distribution_text",
        {},
        { locale: this.localLanguage }
      );
      this.backwardDistributionText = this.t(
        "backward_distribution_text",
        {},
        { locale: this.localLanguage }
      );
      this.forwardDistributionText = this.t(
        "forward_distribution_text",
        {},
        { locale: this.localLanguage }
      );

      this.dataManagementHeader = this.t(
        "data_management_header",
        {},
        { locale: this.localLanguage }
      );

      this.hintText = this.t("hint", {}, { locale: this.localLanguage });

      this.confirmButtonLabel = this.t(
        "yes",
        {},
        { locale: this.localLanguage }
      );
      this.cancelButtonLabel = this.t("no", {}, { locale: this.localLanguage });

      this.importButtonLabel = this.t(
        "import_button_label",
        {},
        { locale: this.localLanguage }
      );
      this.dialogHeader = this.t(
        "dialog_header",
        {},
        { locale: this.localLanguage }
      );
      this.jobsStepName = this.t(
        "jobs_step_name",
        {},
        { locale: this.localLanguage }
      );
      this.shiftsStepName = this.t(
        "shifts_step_name",
        {},
        { locale: this.localLanguage }
      );
      this.resourcesStepName = this.t(
        "resources_step_name",
        {},
        { locale: this.localLanguage }
      );
      this.operationsStepName = this.t(
        "operations_step_name",
        {},
        { locale: this.localLanguage }
      );
      this.welcomeMessage = this.t(
        "welcome_message",
        {},
        { locale: this.localLanguage }
      );
      this.firstStepMessage = this.t(
        "first_step_message",
        {},
        { locale: this.localLanguage }
      );
      this.firstPanelMessagesArray = new Array(
        this.t("first_panel_message_0", {}, { locale: this.localLanguage }),
        this.t("first_panel_message_1", {}, { locale: this.localLanguage })
      );
      this.secondStepMessage = this.t(
        "second_step_message",
        {},
        { locale: this.localLanguage }
      );

      this.secondPanelMessagesArray = new Array(
        this.t("second_panel_message_0", {}, { locale: this.localLanguage }),
        this.t("second_panel_message_1", {}, { locale: this.localLanguage })
      );
      this.thirdStepMessage = this.t(
        "third_step_message",
        {},
        { locale: this.localLanguage }
      );

      this.thirdPanelMessageArray = new Array(
        this.t("third_panel_message_0", {}, { locale: this.localLanguage }),
        this.t("third_panel_message_1", {}, { locale: this.localLanguage })
      );
      this.fourthStepMessage = this.t(
        "fourth_step_message",
        {},
        { locale: this.localLanguage }
      );
      this.fourthPanelMessageArray = new Array(
        this.t("fourth_panel_message_0", {}, { locale: this.localLanguage }),
        this.t("fourth_panel_message_1", {}, { locale: this.localLanguage })
      );
      this.fifthPanelMessageArray = new Array(
        this.t("fifth_panel_message_0", {}, { locale: this.localLanguage }),
        this.t("fifth_panel_message_1", {}, { locale: this.localLanguage })
      );
      this.compoundFilenameHeader = this.t(
        "compound_filename_header",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowHeader = this.t(
        "time_window_header",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowExplanation1 = this.t(
        "time_window_explanation_1",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowExplanation2 = this.t(
        "time_window_explanation_2",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowExplanation3 = this.t(
        "time_window_explanation_3",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowExplanation4 = this.t(
        "time_window_explanation_4",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowExplanation5 = this.t(
        "time_window_explanation_5",
        {},
        { locale: this.localLanguage }
      );
      this.timeWindowObservation = this.t(
        "time_window_observation",
        {},
        { locale: this.localLanguage }
      );

      (this.nameForTheFilesCompound = this.t(
        "name_for_the_files_compound",
        {},
        { locale: this.localLanguage }
      )),
        (this.numberOfDaysPlaceholder = this.t(
          "number_of_days_placeholder",
          {},
          { locale: this.localLanguage }
        ));
      this.generateFileText = this.t(
        "generate_file_text",
        {},
        { locale: this.localLanguage }
      );
      this.stepButtonNamesArray = new Array(
        this.t("step_button_names_0", {}, { locale: this.localLanguage }),
        this.t("step_button_names_1", {}, { locale: this.localLanguage })
      );
      this.nextButtonLabel = this.t(
        "next_button_label",
        {},
        { locale: this.localLanguage }
      );
      this.finishButtonLabel = this.t(
        "finish_button_label",
        {},
        { locale: this.localLanguage }
      );
    },
    onWarningReturnMessage(warning) {
      this.warningMessage = warning;
    },
    confirmShiftTimesCode() {
      this.emitter.emit("confirmShiftTime");
    },
    cancelShiftTimesCode() {
      this.emitter.emit("cancelShiftTime");
    },
    onOpenConfirmation(payload) {
      this.showConfirmDialog = !this.showConfirmDialog;

      this.confirmMessage = payload.confirmMessage.split(".")[0] + ".";
      this.confirmSpecialMessage =
        payload.confirmMessage.split(".")[1].split("?")[0] + "?";
      this.confirmHeaderText = payload.confirmHeaderText;

      this.formData = payload.formData;
      this.filename = payload.filename;
    },
    showTimeWindowInfo(event) {
      this.$refs.timeWindowInfo.toggle(event);
    },
    hideTimeWindowInfo(event) {
      this.$refs.timeWindowInfo.toggle(event);
    },

    showOriginalDataTutorial(event) {
      this.$refs.originalDataTutorial.toggle(event);
    },
    hideOriginalDataTutorial(event) {
      this.$refs.originalDataTutorial.toggle(event);
    },

    showBackwardDataTutorial(event) {
      this.$refs.backwardDataTutorial.toggle(event);
    },
    hideBackwardDataTutorial(event) {
      this.$refs.backwardDataTutorial.toggle(event);
    },

    showForwardDataTutorial(event) {
      this.$refs.forwardDataTutorial.toggle(event);
    },
    hideForwardDataTutorial(event) {
      this.$refs.forwardDataTutorial.toggle(event);
    },

    updateFilenameFieldValue(value) {
      this.compositionFilename = value;
    },
    updateTimeWindowFieldValue(value) {
      this.timeWindow = value;
    },
    setFilename(filename, filetype) {
      if (filetype == "jobs") {
        this.jobsFilename = filename;
      } else if (filetype == "shifts") {
        this.shiftsFilename = filename;
      } else if (filetype == "resources") {
        this.resourcesFilename = filename;
      } else if (filetype == "operations") {
        this.operationsFilename = filename;
      }
    },

    setDataManagementState(state) {
      this.dataManagementValidState = state;
      this.setValidState();
    },

    setCompoundValidState(state) {
      this.compoundFileNameValidState = state;
      this.setValidState();
    },
    setTimeWindowValidState(state) {
      this.timeWindowValidState = state;
      this.setValidState();
    },
    setValidState() {
      if (this.forceFinishButtonDisabled) {
        return;
      } else {
        this.finishValidState =
          this.compoundFileNameValidState &&
           this.dataManagementValidState;
          // this.timeWindowValidState &&
          // this.dataManagementValidState;
      }
    },
    onFailure(errorMessage) {
      this.errorMessage = this.t(
        errorMessage,
        {},
        { locale: this.localLanguage }
      );
      this.processing = false;
      this.successes[this.step] = false;
      this.disabledInputs[this.step] = false;
      let tl = gsap.timeline();

      let failureAnimationClass = "." + this.failureAnimationClasses[this.step];
      tl.to(failureAnimationClass, {
        x: "+=20",
        yoyo: true,
        repeat: 4,
        duration: 0.07
      });
    },
    onSuccess() {
      this.disabledInputs[this.step] = true;
      this.sleep().then(() => {
        let tl = gsap.timeline();

        let panelCurrentClass = "." + this.panelsAnimationClasses[this.step];
        let currentClass = "." + this.animationClasses[this.step];
        tl.to(panelCurrentClass, {
          opacity: 0,
          duration: 0.6,
          ease: "Rough.easeOut"
        })
          .to(currentClass, {
            opacity: 0,
            scaleX: 0,
            duration: 0.6,
            ease: "Rough.easeOut"
          })
          .then(() => {
            this.successes[this.step] = true;
            this.errorMessage = null;
            if (this.step == 4) this.step = 5;
          });
      });
    },
    setProcessing(mode) {
      this.processing = mode;
      this.disabledInputs[this.step] = true;
    },
    openBasic() {
      this.displayBasic = true;
    },
    finish() {
      if (this.finishValidState) {
        this.finishValidState = false;
        this.forceFinishButtonDisabled = true;
        this.sleep().then(() => {
          this.runFinishProcess(false);
        });
      }
    },
    runFinishProcess(retry) {
      // console.log("runFinishProcess()")
      let completed = false;
      this.finishValidState = false;
      this.processing = true;
      this.disabledCompositionInputText = true;
      // this.emitter.emit("disableControlStates", true);
       
      // this.emitter.emit("disableMenusStates", true);
      if (!retry){
        this.compositionFilename += "_composed.csv";
        this.$emit("disableMenusStates", true);
      }
      APIService.api.checkAxxalonPlanHealth().then(() => {
          APIService.api
            .processAllTransientCSVFiles(
              this.compositionFilename,
              this.timeInterval,
              600,
              this.dataManagementType
            )
            .then(fileId => {
              this.processing = false;

              this.jobsFilename = null;
              this.shiftsFilename = null;
              this.resourcesFilename = null;
              this.operationsFilename = null;

              // console.log("fileId")
              // console.log(JSON.stringify(fileId))

              let extractedFileId;
              extractedFileId = fileId["data"]["_id"]
              if (extractedFileId == undefined || extractedFileId == null){
                extractedFileId = fileId["data"]
              }
              
              // console.log("extractFieldId: " + extractedFileId)

              // this.$emit("createAndSelectFile", fileId["data"]["_id"]);
              this.$emit("createAndSelectFile", extractedFileId);

              this.displayBasic = false;
              this.disabledCompositionInputText = false;

              this.jobsFilename = null;
              this.shiftsFilename = null;
              this.resourcesFilename = null;
              this.operationsFilename = null;

              this.dataManagementType = null;
              completed = true;
              //  this.emitter.emit("disableMenusStates", false);
              this.$emit("disableMenusStates", false);
              this.emitter.emit("optimizationLoaded", false);
              this.$toast.add({
                severity: "success",
                summary: "Files processed with success!",
                detail: "Data loaded in gantt chart.",
                life: 3500
              });

              this.forceFinishButtonDisabled = false;
              return fileId;
            })
            .catch(error => {
              completed = false;
              this.processing = false;
              // this.$emit("disableMenusStates", false);
              //  this.emitter.emit("disableMenusStates", false);
              if (error.message == "Request failed with status code 400") {
                this.$toast.add({
                  severity: "error",
                  summary:
                    "axxalon-plan service unavailable, please try again in a few minutes.",
                  detail: error.message,
                  life: 3500
                });
              }

              this.forceFinishButtonDisabled = false;
            }).finally(() => {
                if (! completed){
                  console.log("Retrying, please wait...")
                  this.processing = true;
                  setTimeout(() => {
                     this.runFinishProcess(true);
                  }, 1000 * 60 * 1)
                }
            });
      });
    },
    next() {
      if (this.step < 4) {
        this.step += 1;
        if (this.step > 0) {
          this.stepsVisible = true;
        }
      }
      let tl = gsap.timeline();

      let stepCurrentClass = "." + this.stepsAnimationClasses[this.step];
      if (document.getElementById(this.stepsAnimationClasses[this.step])) {
        tl.from(stepCurrentClass, {
          opacity: 0.1,
          duration: 0.2,
          ease: "Rough.easeOut"
        });
      }

      let currentClass = "." + this.animationClasses[this.step];

      if (document.getElementById(this.animationClasses[this.step])) {
        tl.from(currentClass, {
          opacity: 0,
          duration: 0.6,
          ease: "Rough.easeOut"
        });
      }
    },
    backOrCancelEvent() {
      if (this.step > 0) {
        this.back();
      } else {
        this.closeBasic();
      }
    },
    back() {
      if (this.step > 0) {
        this.step -= 1;
        if (this.step == 0) {
          this.stepsVisible = false;
        }
      }
    },
    closeBasic() {
      this.displayBasic = false;
      this.step = 0;
      this.jobsFilename = null;
      this.shiftsFilename = null;
      this.resourcesFilename = null;
      this.operationsFilename = null;
    },
    sleep() {
      return new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
