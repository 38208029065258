<template>
  <div
    :class="
      stepCheckerState == null
        ? ''
        : 'p-d-flex p-jc-center round-circle-checked'
    "
  >
    <i
      style="color: black"
      :class="
        stepCheckerState == null
          ? ''
          : stepCheckerState == true
          ? 'pi pi-check-circle p-as-center'
          : 'pi pi-times-circle p-as-center'
      "
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    stepCheckerState: { type: Boolean }
  }
};
</script>

<style lang="scss" scoped></style>
