<template>
  <!-- directions available: top, right, left, bottom -->
  <div class="no-caret no-select" :class="mainClass">
    <slot></slot>
    <span :style="disabled ? 'opacity: 0': ''" :class="subClass">{{ tooltipText }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainClass: "",
      subClass: ""
    };
  },
  props: {
    direction: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    },
    disabled: {type: Boolean, default: false}
  },
  mounted() {
    this.mainClass = "tooltip-" + this.direction;
    this.subClass = "tooltiptext-" + this.direction;
  }
};
</script>

<style lang="scss" scoped>
///////////////////////
/* TOOLTIP RIGHT */
///////////////////////
.tooltip-right {
  position: relative;
  display: inline-block;
}

.tooltip-right .tooltiptext-right {
  visibility: hidden;
  width: 150px;
  background-color: #04cae4;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  top: -5px;
  left: 110%;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-right .tooltiptext-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #04cae4 transparent transparent;
}
.tooltip-right:hover .tooltiptext-right {
  visibility: visible;
  // opacity: 1;
  opacity: 0.8;
}

///////////////////////
/* TOOLTIP LEFT      */
///////////////////////
.tooltip-left {
  position: relative;
  display: inline-block;
}

.tooltip-left .tooltiptext-left {
  visibility: hidden;
  width: 150px;
  background-color: #04cae4;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  top: -5px;
  right: 110%;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-left .tooltiptext-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #04cae4;
}
.tooltip-left:hover .tooltiptext-left {
  visibility: visible;
  // opacity: 1;
  opacity: 0.8;
}
///////////////////////
/* TOOLTIP TOP       */
///////////////////////
.tooltip-top {
  position: relative;
  display: inline-block;
}

.tooltip-top .tooltiptext-top {
  visibility: hidden;
  width: 150px;
  background-color: #04cae4;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-top .tooltiptext-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #04cae4 transparent transparent transparent;
}

.tooltip-top:hover .tooltiptext-top {
  visibility: visible;
  // opacity: 1;
  opacity: 0.8;
}
///////////////////////
/* TOOLTIP BOTTOM    */
///////////////////////
.tooltip-bottom {
  position: relative;
  display: inline-block;
}

.tooltip-bottom .tooltiptext-bottom {
  visibility: hidden;
  width: 150px;
  background-color: #04cae4;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-bottom .tooltiptext-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #04cae4 transparent;
}

.tooltip-bottom:hover .tooltiptext-bottom {
  visibility: visible;
  // opacity: 1;
  opacity: 0.8;
}
</style>
