import axios from "axios";

// PRODUCTION ENVIRONMENT
const apiUrl = "";

// DEVELOPMENT ENVIRONMENT
// const apiUrl = "//localhost:3001";

const api = {
  getKleigedrucktData() {
    return axios.get(apiUrl + "/kleingedruckte/getCurrent");
  },
  registerUser(credentials) {
    // return axios.post(apiUrl + "/register", credentials);
    return axios.put(apiUrl + "/application-users/register", credentials);
  },
  login(credentials) {
    return axios.post(apiUrl + "/login", credentials);
  },
  // JUST FOR TEST PATH
  getDashboardData() {
    return axios.get(apiUrl + "/management/api/v1/students");
    // return axios.get(apiUrl + "/api/v1/students");
  },


    ///////////////////////
   // FILE UPLOAD API
  ////////////////////////
  uploadFileAndProcess(formData, func) {
    return axios.post(apiUrl + "/allfiles", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: func
    });
  },
  uploadJobsAndProcess(formData, func) {
    return axios.post(apiUrl + "/allfiles/jobs", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: func
    });
  },
  uploadShiftsAndProcess(formData, func) {
    return axios.post(apiUrl + "/allfiles/shifts", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: func
    });
  },
  uploadSResourcesAndProcess(formData, func) {
    return axios.post(apiUrl + "/allfiles/resources", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: func
    });
  },
  uploadOperationsAndProcess(
    formData,
    func,
    ignoreEarlierDates,
    ignoreWarnings,
    shiftTimes
  ) {
    return axios.post(apiUrl + "/allfiles/operations", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: func,
      params: {
        ignoreEarlierDates: ignoreEarlierDates,
        shiftTimes: shiftTimes,
        ignoreWarnings: ignoreWarnings
      }
    });
  },
  deleteTransientCSVFiles() {
    return axios.post(apiUrl + "/allfiles/delete-transient");
  },
  deleteSentinels() {
    return axios.post(apiUrl + "/sentinels/delete-all");
  },
  deleteTempFiles() {
    return axios.post(apiUrl + "/allfiles/delete/all-temp-files");
  },
  processAllTransientCSVFiles(
    filename,
    timeInterval,
    timeWindow,
    dataManagementType
  ) {
    return axios.get(apiUrl + "/allfiles/process-transient", {
      timeout: 3600000,
      params: {
        filename: filename,
        timeInterval: timeInterval,
        timeWindow: timeWindow,
        dataManagementType: dataManagementType
      }
    });
  },
  getAllFiles() {
    return axios.get(apiUrl + "/allfiles/raw");
  },
  getAllFilesNames() {
    return axios.get(apiUrl + "/allfiles/filesnames");
  },
  getFileByFileId(
    fileId,
    viewportLeftExtreme = null,
    viewportRightExtreme = null,
    viewportTopExtreme = null,
    viewportBottomExtreme = null,
    expanded = false,
    firstLoad = false
  ) {
    return axios.get(apiUrl + "/allfiles/" + fileId, {
      params: {
        viewportLeftExtreme:
          viewportLeftExtreme == null ? "" : viewportLeftExtreme,
        viewportRightExtreme:
          viewportRightExtreme == null ? "" : viewportRightExtreme,
        viewportTopExtreme:
          viewportTopExtreme == null ? -1 : viewportTopExtreme,
        viewportBottomExtreme:
          viewportBottomExtreme == null ? -1 : viewportBottomExtreme,
        expanded: expanded,
        firstLoad: firstLoad
      }
    });
  },
  deleteFileById(fileId) {
    return axios.put(apiUrl + "/allfiles/delete/" + fileId);
  },
  checkAxxalonPlanHealth() {
    return axios.get(apiUrl + "/axxalon-plan/health");
  },

    ///////////////////////
   // OPTIMIZATION API
  ////////////////////////
  optimizeRawDataInAxxalonPlan(
    fileId,
    processUUID,
    viewportLeftExtreme,
    viewportRightExtreme,
    viewportTopExtreme,
    viewportBottomExtreme
  ) {
    return axios.get(apiUrl + "/axxalon-plan/" + fileId, {
      timeout: 3600000,
      params: {
        processUUID: processUUID,
        viewportLeftExtreme:
          viewportLeftExtreme == null ? "" : viewportLeftExtreme,
        viewportRightExtreme:
          viewportRightExtreme == null ? "" : viewportRightExtreme,
        viewportTopExtreme:
          viewportTopExtreme == null ? -1 : viewportTopExtreme,
        viewportBottomExtreme:
          viewportBottomExtreme == null ? -1 : viewportBottomExtreme
      }
    });
  },
  getCreatedDates() {
    return axios.get(apiUrl + "/axxalon-plan/created-dates");
  },
  getOptimizedFileByRawFileId(
    fileId,
    viewportLeftExtreme,
    viewportRightExtreme,
    viewportTopExtreme,
    viewportBottomExtreme,
    expanded = false,
    firstLoad = false
  ) {
    return axios.get(apiUrl + "/axxalon-plan/allfiles/" + fileId, {
      params: {
        viewportLeftExtreme:
          viewportLeftExtreme == null ? "" : viewportLeftExtreme,
        viewportRightExtreme:
          viewportRightExtreme == null ? "" : viewportRightExtreme,
        viewportTopExtreme:
          viewportTopExtreme == null ? -1 : viewportTopExtreme,
        viewportBottomExtreme:
          viewportBottomExtreme == null ? -1 : viewportBottomExtreme,
        expanded: expanded,
        firstLoad: firstLoad
      }
    });
  },

    ///////////////////////
   // SORTING API
  ////////////////////////
  sortingInRawGantt(fileId, sortingType) {
    return axios.get(apiUrl + "/sorting/raw/" + fileId, {
      params: {
        typeOfSorting: sortingType
      }
    });
  },
  sortingInOptimizedGantt(fileId, sortingType) {
    return axios.get(apiUrl + "/sorting/optimized/" + fileId, {
      params: {
        typeOfSorting: sortingType
      }
    });
  },
  sortingInBothGantt(fileId, sortingType) {
    return axios.get(apiUrl + "/sorting/both/" + fileId, {
      params: {
        typeOfSorting: sortingType
      }
    });
  },
  unsortRawGantt(fileId) {
    return axios.get(apiUrl + "/sorting/raw/unsort/" + fileId);
  },
  usortOptimizedGantt(fileId) {
    return axios.get(apiUrl + "/sorting/optimized/unsort/" + fileId);
  },
  usortBothGantt(fileId) {
    return axios.get(apiUrl + "/sorting/both/unsort/" + fileId);
  },

    ///////////////////////
   // SHIFT API
  ////////////////////////
  hideShiftInRawGantt(fileId) {
    return axios.get(apiUrl + "/shifts/hide/raw/" + fileId);
  },
  showShiftInRawGantt(fileId) {
    return axios.get(apiUrl + "/shifts/show/raw/" + fileId);
  },
  hideShiftInOptimizedGantt(fileId) {
    return axios.get(apiUrl + "/shifts/hide/optimized/" + fileId);
  },
  showShiftInOptimizedGantt(fileId) {
    return axios.get(apiUrl + "/shifts/show/optimized/" + fileId);
  },
  hideShiftInBothGantt(fileId) {
    return axios.get(apiUrl + "/shifts/hide/both/" + fileId);
  },
  showShiftInBothGantt(fileId) {
    return axios.get(apiUrl + "/shifts/show/both/" + fileId);
  },

    ///////////////////////
   // SEARCH API
  ////////////////////////
  searchInRawGantt(fileId, searchValue) {
    return axios.get(apiUrl + "/search/raw/" + fileId, {
      params: {
        valueToSearch: searchValue
      }
    });
  },
  searchInOptimizedGantt(fileId, searchValue) {
    return axios.get(apiUrl + "/search/optimized/" + fileId, {
      params: {
        valueToSearch: searchValue
      }
    });
  },
  searchInBothGantt(fileId, searchValue) {
    return axios.get(apiUrl + "/search/both/" + fileId, {
      params: {
        valueToSearch: searchValue
      }
    });
  },
  clearRawSearchTreatedData(fileId) {
    return axios.get(apiUrl + "/search/raw/clear/" + fileId);
  },
  clearOptimizedSearchTreatedData(fileId) {
    return axios.get(apiUrl + "/search/optimized/clear/" + fileId);
  },

    ///////////////////////
   // SNAP DATA API
  ////////////////////////
  snapDataInRawGantt(fileId) {
    return axios.get(apiUrl + "/snap/raw/" + fileId);
  },
  snapDataInOptimizedGantt(fileId) {
    return axios.get(apiUrl + "/snap/optimized/" + fileId);
  },
  snapDataInBothGantt(fileId, snapMode) {
    return axios.get(apiUrl + "/snap/both/" + fileId, {
      params: {
        snapMode: snapMode
      }
    });
  },
};

export default {
  apiUrl,
  api
};
