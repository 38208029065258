<template>
  <div class=" animated-card p-d-flex p-flex-column p-ai-strech">
    <div class="p-d-flex p-jc-center">
      <Card class="p-mt-6 no-caret" style="width: 25rem; margin-bottom: 2em;">
        <template #header>
          <div style="p-mb-2 height: 30px;">
            <!-- <img
              class="no-drag p-mt-2"
              style="border-radius: 10px;"
              alt="user header"
              src="../assets/images/axxalon-plan-experience-blue.png"
            /> -->
            <img
              class="no-drag p-mt-2"
              style="border-radius: 10px;"
              alt="user header"
              src="../assets/images/axxalon-plan-experience-presentation-red.png"
            />
          </div>
        </template>

        <template #title class="p-d-flex p-jc-center">{{
          capitalize(
            t("register_for_the_experience", {}, { locale: localLanguage })
          )
        }}</template>

        <template #content>
          <AxxalonInputText
            type="text"
            :placeholder="namePlaceholder"
            iconPos="left"
            iconType="pi-user"
            :required="true"
            :dynamicErrorMessage="this.errorMessage.nameFieldError"
            :validator="true"
            @change="updateNameFieldValues"
            @validState="setNameValidState"
          ></AxxalonInputText>

          <AxxalonInputText
            type="email"
            :placeholder="emailPlaceholder"
            iconPos="left"
            iconType="pi-envelope"
            :required="true"
            :dynamicErrorMessage="this.errorMessage.emailFieldError"
            :validator="true"
            @change="updateEmailFieldValues"
            @validState="setEmailValidState"
          ></AxxalonInputText>

          <AxxalonInputText
            type="password"
            :placeholder="passwordPlaceholder"
            iconPos="left"
            :required="true"
            :dynamicErrorMessage="this.errorMessage.passwordFieldError"
            :validator="true"
            @change="updatePasswordFieldValues"
            @validState="setPasswordValidState"
          ></AxxalonInputText>
        </template>

        <template #footer>
          <div class="p-d-flex p-flex-column">
            <Button
              :label="buttonLabel"
              class="p-as-center"
              @click="register"
              :disabled="invalidForm"
            />
            <router-link class="p-mt-4 p-as-center" :to="{ name: 'Login' }">
              {{
                capitalize(
                  t("already_have_account", {}, { locale: localLanguage })
                )
              }}
              {{
                capitalize(t("register", {}, { locale: localLanguage }))
              }}.</router-link
            >
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import gsap from "gsap";
import AxxalonInputText from "@/components/Axxalon/AxxalonInputText.vue";

export default {
  components: {
    AxxalonInputText: AxxalonInputText
  },
  setup() {
    const store = useStore();

    const showPassword = ref(false);

    const { t, locale } = useI18n();

    return { store, showPassword, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      emailPlaceholder: this.capitalize(this.t("email", {}, { locale: "de" })),
      passwordPlaceholder: this.capitalize(
        this.t("password", {}, { locale: "de" })
      ),
      namePlaceholder: this.capitalize(this.t("name", {}, { locale: "de" })),
      buttonLabel: this.capitalize(this.t("register", {}, { locale: "de" })),
      name: null,
      email: null,
      password: null,
      error: null,
      errorMessage: {
        nameFieldError: null,
        emailFieldError: null,
        passwordFieldError: null
      },
      errorType: null,
      nameValidState: false,
      emailValidState: false,
      passwordValidState: false,
      invalidForm: true
    };
  },
  computed: {
    formButtonDisabled() {
      return (
        this.name === null || this.email === null || this.password === null
      );
    }
  },
  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
    this.namePlaceholder = this.capitalize(
      this.t("name", {}, { locale: this.localLanguage })
    );
    this.emailPlaceholder = this.capitalize(
      this.t("email", {}, { locale: this.localLanguage })
    );
    this.passwordPlaceholder = this.capitalize(
      this.t("password", {}, { locale: this.localLanguage })
    );
    this.buttonLabel = this.capitalize(
      this.t("register", {}, { locale: this.localLanguage })
    );
  },
  mounted() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.emailPlaceholder = this.capitalize(
      this.t("email", {}, { locale: this.localLanguage })
    );
    this.passwordPlaceholder = this.capitalize(
      this.t("password", {}, { locale: this.localLanguage })
    );

    this.buttonLabel = this.capitalize(
      this.t("register", {}, { locale: this.localLanguage })
    );

    let tl = gsap.timeline();
    tl.from(".animated-card", {
      x: -400,
      opacity: 0,
      duration: 0.5,
      ease: "Expo.easeOutIn"
    });
  },
  methods: {
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },
    checkAllStates() {
      if (
        this.nameValidState &&
        this.emailValidState &&
        this.passwordValidState
      ) {
        this.invalidForm = false;
      } else {
        this.invalidForm = true;
      }
    },
    setNameValidState(state) {
      this.nameValidState = state;
      this.checkAllStates();
    },
    setEmailValidState(state) {
      this.emailValidState = state;
      this.checkAllStates();
    },
    setPasswordValidState(state) {
      this.passwordValidState = state;
      this.checkAllStates();
    },
    updateNameFieldValues(data) {
      this.name = data;
      this.checkAllStates();
    },
    updateEmailFieldValues(data) {
      this.email = data;
      this.checkAllStates();
    },
    updatePasswordFieldValues(data) {
      this.password = data;
      this.checkAllStates();
    },

    register() {
      if (!this.invalidForm) {
        this.store
          .dispatch("register", {
            username: this.email,
            password: this.password,
            name: this.name
          })
          .then(() => {
            // this.$emit("reload", { route: "/login", action: "logout" });
            // this.emitter.emit(
            //   "from-Register.vue-to-Login.vue:setRegisteredEmail",
            //   this.email
            // );

            this.$router
              .push({ name: "Login", params: { newEmail: this.email } })
              .then(() => {
                this.$toast.add({
                  severity: "success",
                  summary: "User Registered with Success",
                  detail: "Now you can sign in.",
                  life: 3500
                });
              });
          })
          .catch(err => {
            this.error = err.response.data.message;
            this.errorMessage.emailFieldError = err.response.data.message;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
