import { createStore } from "vuex";
import APIService from "@/service/APIService";
import axios from "axios";
// import router from "../router";

const state = {
  userSignedIn: false,
  user: null,
  login: false,
  currentSpeed: null,
  files: [],
  madeRegistration: false,
  showGlass: true,
  language: "de"
};

const mutations = {
  CLEAR_USER_DATA(state) {
    // this.$router.push("/")
    location.reload();
    location.replace("/")
    // this.$router.push({ name: "Home" });
    state.user = null;
    state.loggedIn = false;
    localStorage.removeItem("user");
  },
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem("user", JSON.stringify(userData));
    axios.defaults.headers.common["Authorization"] =
      userData.headers.authorization;
  },
  SET_LANGUAGE(state, language) {
    state.language = language;
    localStorage.setItem("language", language);
  },
  SET_USER_REGISTRATION(state) {
    state.madeRegistration = true;
  },
  SET_NEW_SPEED(state, newSpeed) {
    state.currentSpeed = newSpeed;
  },
  SET_UPLOADED_FILES(state, file) {
    state.files.push(file);
  }
};

const actions = {
  isLoggedIn() {
    return !!state.user;
  },
  getSignedIn() {
    return state.userSignedIn;
  },
  login({ commit }, credentials) {
    return APIService.api.login(credentials).then(data => {
      commit("SET_USER_DATA", data);
    });
  },
  logout({ commit }) {
    commit("CLEAR_USER_DATA");
  },
  register({ commit }, credentials) {
    return APIService.api.registerUser(credentials).then(() => {
      commit("SET_USER_REGISTRATION");
    });
  },
  sendFile({ commit }, file) {
    const message = { message: file };
    return axios.post("//localhost:3000/file", message).then(() => {
      commit("SET_UPLOADED_FILES", file);
    });
  },
  updateHomeLogoSpeed({ commit }, newSpeed) {
    commit("SET_NEW_SPEED", newSpeed);
  },
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  }
};

export const getters = {
  languageState(state) {
    return state.language;
  }
};

export default createStore({
  state,
  mutations,
  actions
  // getters,
});
