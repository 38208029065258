<template>
  <div class="p-d-flex p-flex-column">
    <div class="p-d-flex p-flex-row no-caret">
      <span
        :class="iconPos == 'left' ? 'p-input-icon-left' : 'p-input-icon-right'"
        style="width: 100%;"
      >
        <i
          :v-if="iconType && type != 'password'"
          :class="iconClass"
          @click="changePasswordState"
        ></i>
        <InputText
          v-if="this.type != 'number'"
          :class="
            this.errorMessage.fieldError != null
              ? 'p-invalid'
              : inputDisabled
              ? 'p-d-flex p-mb-2 no-caret'
              : 'p-d-flex p-mb-2 with-caret'
          "
          style="width: 100%;"
          :placeholder="placeholder"
          :type="internalType"
          v-model="fieldValue"
          :disabled="inputDisabled"
          @blur="validateFieldOnBlur"
          @change="validateFieldOnChange"
          @keyup="onKeyUp"
        />
        <InputText
          v-if="this.type == 'number'"
          :class="
            this.errorMessage.fieldError != null
              ? 'p-invalid'
              : inputDisabled
              ? 'p-d-flex p-mb-2 no-caret'
              : 'p-d-flex p-mb-2 with-caret'
          "
          style="width: 100%;"
          :placeholder="placeholder"
          :type="internalType"
          v-model="fieldValue"
          :disabled="inputDisabled"
          @blur="validateFieldOnBlur"
          @change="validateFieldOnChange"
          @keyup="onKeyUp"
          :step="this.type == 'number' ? 1 : ''"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </span>
    </div>
    <div class="p-d-flex p-flex-row p-mb-1">
      <small :v-if="this.errorMessage.fieldError != null" class="p-error">{{
        this.errorMessage.fieldError
      }}</small>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  emits: ["change", "validState", "enterPressed"],
  props: {
    placeholder: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    iconPos: {
      type: String
    },
    iconType: {
      type: String
    },
    iconFunction: {
      type: Function
    },
    required: {
      type: Boolean,
      required: true
    },
    dynamicErrorMessage: {
      type: String
    },
    validator: {
      type: Boolean,
      required: false
    },
    inputDisabled: { type: Boolean },
    activateEnterKeyUp: { type: Boolean, default: false },
    resetSearchInputText: { type: Boolean },
    setValue: { type: String }
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      fieldValue: null,
      errorMessage: {
        fieldError: null
      },
      passwordIcon: "pi-eye-slash",
      passwordOpenIcon: "pi-eye",
      passwordMask: true,
      internalType: null,
      emailReg: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },

  computed: {
    iconClass() {
      const iconToUse = this.passwordMask
        ? this.passwordIcon
        : this.passwordOpenIcon;
      return this.type === "password"
        ? "pi " + iconToUse + " p-mr-2 p-d-flex p-ai-center pointer-cursor"
        : "pi " + this.iconType + " p-mr-2 p-d-flex p-ai-center no-caret";
    }
  },
  watch: {
    resetSearchInputText: function() {
      this.fieldValue = null;
    },
    setValue: function(value) {
      this.fieldValue = value;
      this.$emit("change", this.fieldValue);
    }
  },
  unmounted() {
    // this.emitter.all.clear("LanguageStateBus");
  },
  mounted() {
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
      this.validateFieldOnChange();
    });

    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
  },

  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    if (this.dynamicErrorMessage) {
      this.errorMessage.fieldError = this.dynamicErrorMessage;
    }
    if (!this.validator) return;
    this.validateFieldOnChange();
  },
  beforeMount() {
    this.internalType = this.type;
  },

  methods: {
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },
    isEmailValid: function() {
      return this.fieldValue == ""
        ? ""
        : this.emailReg.test(this.fieldValue)
        ? true
        : false;
    },
    changePasswordState() {
      if (this.type === "password") {
        this.passwordMask = !this.passwordMask;
        if (!this.passwordMask) {
          this.internalType = "text";
        } else {
          this.internalType = "password";
        }
      }
    },
    onKeyUp(event) {
      if (event.key == "Enter") {
        this.$emit("enterPressed", this.fieldValue);
      } else {
        this.$emit("change", this.fieldValue);
        this.validateFieldOnChange();
      }
    },
    validateFieldOnBlur() {
      if (!this.validator) return;
      if (
        (this.fieldValue === null || this.fieldValue.length === 0) &&
        this.required
      ) {
        this.errorMessage.fieldError = this.capitalize(
          this.t("field_cannot_be_empty", {}, { locale: this.localLanguage })
        );
        this.$emit("validState", false);
      }
    },
    validateFieldOnChange() {
      if (!this.validator) return;

      if (this.type == "number") {
        if (this.fieldValue >= 0) {
          this.errorMessage.fieldError = null;
          this.$emit("validState", true);
          return;
        } else {
          this.errorMessage.fieldError = this.capitalize(
            this.t(
              "not_valid_number_for_time_window",
              {},
              { locale: this.localLanguage }
            )
          );
          this.$emit("validState", false);
          return;
        }
      }

      if (this.fieldValue != null && this.type === "email") {
        this.fieldValue = this.fieldValue.toLowerCase();
      }
      if (
        (this.fieldValue === null || this.fieldValue.length === 0) &&
        this.required
      ) {
        this.errorMessage.fieldError = this.capitalize(
          this.t("field_cannot_be_empty", {}, { locale: this.localLanguage })
        );
        this.$emit("validState", false);
        this.$emit("validState", false);
      } else if (this.type === "password" && this.fieldValue.length < 8) {
        this.errorMessage.fieldError = this.capitalize(
          this.t(
            "the_password_need_at_least_eigth_characters",
            {},
            { locale: this.localLanguage }
          )
        );
        this.$emit("validState", false);
      } else if (this.type === "email" && !this.isEmailValid(this.fieldValue)) {
        this.errorMessage.fieldError = this.capitalize(
          this.t(
            "please_insert_valid_email",
            {},
            { locale: this.localLanguage }
          )
        );
        this.$emit("validState", false);
      } else if (this.type === "filename") {
        let blankPieces = this.fieldValue.split(" ");
        let dotPieces = this.fieldValue.split(".");

        if (blankPieces.length > 1) {
          this.errorMessage.fieldError = this.capitalize(
            this.t(
              "the_filename_cannot_contain_white_spaces",
              {},
              { locale: this.localLanguage }
            )
          );
          this.$emit("validState", false);
        } else if (dotPieces.length > 1) {
          this.errorMessage.fieldError = this.capitalize(
            this.t(
              "please_do_not_use_dots_or_file_termination",
              {},
              { locale: this.localLanguage }
            )
          );
          this.$emit("validState", false);
        } else {
          this.errorMessage.fieldError = null;
          this.$emit("validState", true);
        }
      } else if (!this.dynamicErrorMessage) {
        this.errorMessage.fieldError = null;
        this.$emit("validState", true);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
