<template>
  <div class="layout-footer p-d-flex p-jc-end">
    <div class="p-grid">
      <div class="p-d-flex p-flex-row">
        <div class="p-col-3 p-mr-4 axxelia-logo-footer">
          <!-- <img
            src="./assets/images/axxelia-logo.png"
            alt="axxelia"
            width="80"
            style="margin-bottom: 9px;"
          /> -->
          <img
            src="./assets/images/axxelia-logo-smart-small.png"
            alt="axxelia"
            width="80"
          />
        </div>
        <div class="p-col-8 p-ml-2">
          <span class="footer-text" style="font-size: 12px"
            >{{
              capitalize(
                t("we_accelerate_your_business", {}, { locale: localLanguage })
              )
            }}
            © {{currentYear}}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "AppFooter",
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      currentYear: ""
    };
  },
  mounted() {
    this.currentYear = new Date().toLocaleString("de").toString().split(" ")[0].split(".")[2].split(",")[0]

    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
  },
  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
  },
  methods: {
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    }
  }
};
</script>

<style scoped></style>
