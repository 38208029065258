<template>
  <section
    class="p-d-flex p-flex-row vue-responsive-videobg main-container"
    ref="vidbg"
  >
    <!-- <VideoPoster
      v-if="current.poster || poster"
      :poster="current.poster || poster"
    /> -->

    <video autoplay muted loop class="main-video">
      <!-- <source src="../assets/videos/axxalon-hypercubes.mp4" type="video/mp4" /> -->
      <source
        src="../../assets/videos/axxalon-hypercubes-cutted.mp4"
        type="video/mp4"
      />
    </video>
    <!-- <VideoPlayer
      ref="player"
      :src="current.src"
      :muted="muted"
      :loop="loop"
      :preload="preload"
      :plays-when="playsWhen"
      :playback-rate="playbackRate"
      :transition="transition"
      :object-fit="objectFit"
      @ready="playVideo"
      @playing="$emit('playing')"
      @error="$emit('error')"
      @loading="$emit('loading')"
      @ended="$emit('ended')"
    /> -->

    <!-- <VideoOverlay v-if="overlay" :overlay="overlay" /> -->

    <div class="videobg-content">
      <slot />
    </div>
  </section>
</template>

<script>
// import props from "../core/props.js";
// import VideoPlayer from "./VideoPlayer.vue";
// import VideoPoster from "./VideoPoster.vue";
// import VideoOverlay from "./VideoOverlay.vue";

// import resize from "../core/resize.js";

export default {
  props: {
    language: { Type: String },
    languageCode: { Type: String }
  }
  // mixins: [resize],
  // emits: ["playing", "error", "loading", "ended", "ready"],
  // components: {
  //   VideoOverlay,
  // },
  // computed: {
  //   player() {
  //     return this.$refs.player;
  //   },
  // },
  // methods: {
  //   playVideo() {
  //     this.$emit("ready");
  //     if (!this.current.autoplay) return;
  //     this.player.play();
  //   },
  // },
};
</script>

<style scoped>
.vue-responsive-videobg {
  background: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  outline: none;
}
.vue-responsive-videobg .videobg-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  /* margin-top: -20px; */
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.88),
    rgba(251, 148, 158, 0.22),
    rgba(251, 148, 158, 0.42)
  ); */
}

.main-container {
  width: 100%;
  margin: 0;
  /* background: linear-gradient(-90deg, #8cdbe9, #25528a); */
}
.main-video {
  object-fit: fill;
  width: 100vw;
  height: 83vh;
}
</style>
