<template>
  <div
    :class="
      currentStepNumber == stepNumberDefinition
        ? 'p-d-flex p-jc-center info-box-light-selected-red cutted-t-left'
        : 'p-d-flex p-jc-center info-box-light cutted-t-left p-ml-2'
    "
    style="width: 110px; height: 20px"
  >
    <span
      ><b style="font-size: 11px">{{ stepName }}</b
      ><b class="axxalon-a-red" style="font-size: 11px">{{ stepCode }}</b></span
    >
  </div>
</template>

<script>
export default {
  props: {
    currentStepNumber: { type: Number },
    stepNumberDefinition: { type: Number },
    stepName: { type: String },
    stepCode: { type: String }
  }
};
</script>

<style lang="scss" scoped></style>
