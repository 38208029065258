export default {
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anmelden"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrieren"])},
  "subtitle_powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["powered by "])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
  "heuristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heuristik"])},
  "users_total_runs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer insgesamt läuft"])},
  "lead_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchlaufzeitverkürzung"])},
  "lead_dash_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchlaufzeitverkürzung"])},
  "avg_lead_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ø Durchlaufzeitverkürzung"])},
  "avg_lead_dash_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ø Durchlaufzeitverkürzung"])},
  "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liefertreue"])},
  "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohne"])},
  "with_axxalon_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit axxalon-plan"])},
  "sign_in_with_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich mit Anmeldeinformationen an"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwort"])},
  "dont_have_account_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keinen Account?"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "register_for_the_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich für die Experience"])},
  "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits ein Konto?"])},
  "we_accelerate_your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we accelerate your business"])},
  "field_cannot_be_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieses Feld darf nicht leer sein!"])},
  "not_valid_number_for_time_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine gültige Nummer für das Zeitfenster"])},
  "please_insert_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bitte geben Sie eine gültige E-Mail Adresse ein!"])},
  "the_filename_cannot_contain_white_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der Dateiname darf keine Leerzeichen enthalten"])},
  "please_do_not_use_dots_or_file_termination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bitte nicht '.' oder Dateierweiterung verwenden."])},
  "the_password_need_at_least_eigth_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["das Passwort muss mindestens 8 Zeichen lang sein!"])},
  "run_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung ausführen"])},
  "a_optimization_for_the_selected_file_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Optimierung für die ausgewählte Datei ist bereits vorhanden!"])},
  "please_select_or_import_csv_file_to_run_a_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine CSV-Datei aus, oder importieren Sie sie, um eine Optimierung durchzuführen"])},
  "enable_gpu_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPU verwenden"])},
  "gpu_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPU nicht verfügbar"])},
  "select_a_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Optimierung aus"])},
  "no_optimizations_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Optimierungen verfügbar"])},
  "select_imported_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine importierte CSV-Datei aus"])},
  "no_files_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Dateien importiert"])},
  "are_you_sure_you_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie löschen möchten?"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
  "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei löschen"])},
  "delete_selected_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählte Datei löschen"])},
  "no_csv_file_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine CSV-Datei ausgewählt"])},
  "system_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemsprache"])},
  "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
  "csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Datei"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "only_csv_files_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur CSV-Dateien sind erlaubt!"])},
  "csv_file_is_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die CSV-Datei überschreitet die Größenbeschränkung von 500Kb."])},
  "import_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Datei importieren"])},
  "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistent für CSV-Dateien"])},
  "jobs_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUFTRÄGE"])},
  "shifts_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHICHTEN"])},
  "resources_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESSOURCEN"])},
  "operations_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBEITSGÄNGE"])},
  "welcome_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich willkommen! Dieser Assistent führt Sie durch den Vorgang des korrekten Ladens der CSV-Dateien. Befolgen Sie die Schritte und überprüfen Sie, ob alle Dateien das richtige Format haben."])},
  "first_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuerst müssen Sie die CSV-Datei mit den Aufträgen importieren. Bitte erstellen und laden Sie eine Datei gemäß der folgenden Vorlage."])},
  "first_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Auftragsdatei wurde korrekt validiert! Jetzt können Sie mit dem nächsten Schritt fortfahren."])},
  "first_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei konnte nicht validiert werden! Bitte überprüfen Sie, ob die Eingabedatei der Vorlage entspricht und ob alle Daten korrekt sind."])},
  "second_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als nächstes müssen Sie Schichtmodelle als CSV-Datei importieren. Bitte erstellen und laden Sie eine Datei gemäß der folgenden Vorlage."])},
  "second_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schichtendatei wurde korrekt validiert! Jetzt können Sie mit dem nächsten Schritt fortfahren."])},
  "second_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei konnte nicht validiert werden! Bitte überprüfen Sie, ob die Eingabedatei der Vorlage entspricht und ob alle Daten korrekt sind."])},
  "third_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren Sie nun die CSV-Datei für Ressourcen. Bitte erstellen und laden Sie eine Datei gemäß der folgenden Vorlage."])},
  "third_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ressourcendatei wurde korrekt validiert! Jetzt können Sie mit dem nächsten Schritt fortfahren."])},
  "third_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei konnte nicht validiert werden! Bitte überprüfen Sie, ob die Eingabedatei der Vorlage korrekt entspricht und ob alle Daten korrekt sind."])},
  "fourth_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und schließlich importieren Sie die Arbeitsgänge CSV-Datei. Bitte erstellen und laden Sie eine Datei gemäß der folgenden Vorlage."])},
  "fourth_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dateien wurden erfolgreich hochgeladen und validiert! Jetzt können Sie den Assistenten verlassen, damit die Dateien verarbeitet werden können. Bitte geben Sie einen Namen für die Dateiverbindung an."])},
  "fourth_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei konnte nicht validiert werden! Bitte überprüfen Sie, ob die Eingabedatei der Vorlage entspricht und ob alle Daten korrekt sind."])},
  "fifth_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitüberschneidungen gefunden! Bitte beachten Sie, dass dies zu kürzeren Durchlaufzeiten für die Rohdaten und daher zu falschen Berechnungen der Metriken führen kann."])},
  "fifth_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dateien wurden erfolgreich hochgeladen und validiert! Jetzt können Sie den Assistenten verlassen, damit die Dateien verarbeitet werden können."])},
  "compound_filename_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BITTE GEBEN SIE EINEN NAMEN FÜR DIE DATEI AN"])},
  "name_for_the_files_compound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name für die Datei"])},
  "time_window_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEITFENSTER"])},
  "time_window_explanation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NACH DER OPTIMIERUNG, DIE ARBEITSGÄNGE, DIE"])},
  "time_window_explanation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENDET"])},
  "time_window_explanation_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NACH DEM ZEITFENSTER WERDEN "])},
  "time_window_explanation_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTFERNT"])},
  "time_window_explanation_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUS DEM ENDERGEBNIS"])},
  "time_window_observation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEACHTEN SIE, DASS DIE ROTEN ARBEITSGÄNGE DIE SCHWELLE ERREICHEN UND AUSGEGEBEN WERDEN"])},
  "number_of_days_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Tage"])},
  "generate_file_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATEI WIRD ERZEUGT, BITTE WARTEN..."])},
  "step_button_names_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "step_button_names_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "next_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "finish_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird bearbeitet..."])},
  "shift_times_into_the_future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLANUNG IN DIE ZUKUNFT VERSCHIEBEN"])},
  "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis"])},
  "we_recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihr Datensatz mit sehr präzisen Anfangs- und Enddaten arbeitet, empfehlen wir, die Daten im Originalzustand zu belassen. Andernfalls wäre die beste Alternative die Rückwärtsverteilung, gefolgt von der Vorwärtsverteilung als letzte Option."])},
  "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODUS FÜR FORTGESCHRITTENE"])},
  "simple_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINFACHER MODUS"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROH"])},
  "optimized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIMIERT"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATEN"])},
  "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBEITSGÄNGE"])},
  "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINHEITEN"])},
  "lead_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUCHLAUFZEIT"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAGE"])},
  "avg_lead_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durschn. DLZ"])},
  "units_explanation_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Zahl kann von der ursprünglichen Zahl der Vorgänge abweichen, da der axxalon-Plan die Vorgänge auf mehrere Schichten aufteilt, was oft zu mehr oder weniger Ausführungsblöcken führen kann."])},
  "no_datetime_warning_piece_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEINE"])},
  "no_datetime_warning_piece_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START"])},
  "no_datetime_warning_piece_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UND"])},
  "no_datetime_warning_piece_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENDDATEN"])},
  "no_datetime_warning_piece_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AN DENEN DIE GANTT-TABELLE GENERIERT WURDE, ODER DATEN SIND"])},
  "no_datetime_warning_piece_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRÜHER ALS HEUTE"])},
  "no_metrics_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUS DEM GLEICHEN GRUND KÖNNEN DIE METRIKEN NICHT BERECHNET WERDEN, ABER SIE KÖNNEN DIE OPTIMIERUNG NOCH DURCHFÜHREN."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUCHE"])},
  "search_tutorial_piece_A_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINFÜGEN"])},
  "search_tutorial_piece_A_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTION_ORDER_NUMBER"])},
  "search_tutorial_piece_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UM ALLE ARBEITSGÄNGE AUS EINEM AUFTRAG ZU FINDEN."])},
  "search_tutorial_observation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIES WIRD ALLE ARBEITSGÄNGE HERVORHEBEN, DIE ZU AUFTRAG 444 GEHÖREN"])},
  "search_tutorial_piece_B_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINFÜGEN"])},
  "search_tutorial_piece_B_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTION_ORDER_NUMBER"])},
  "search_tutorial_piece_B_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPERATION_SEQUENCE_NUMBER"])},
  "search_tutorial_piece_B_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "search_tutorial_piece_B_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINEN SPEZIFISCHEN ARBEITSGANG FINDEN."])},
  "search_tutorial_observation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIES WIRD DEN ARBEITSGANG 444#10 HERVORHEBEN"])},
  "viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANSICHT"])},
  "viewport_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAGE"])},
  "delivery_reliability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIEFERTREUE"])},
  "maximum_viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximales Ansichtsfenster!"])},
  "minimum_viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimales Ansichtsfenster!"])},
  "limit_achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit erreicht!"])},
  "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen Sie nach dem OP-JOB-Code"])},
  "time_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEITFENSTER"])},
  "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAHMEN"])},
  "email_already_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene E-Mail Adresse ist bereits registriert! Bitte wählen Sie eine andere"])},
  "no_optimization_for_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Optimierungen für diese Datei."])},
  "a_optimization_already_exists_for_this_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die ausgewählte CSV-Datei ist bereits eine Optimierung vorhanden."])},
  "empty_header_check_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerer Header. Bitte überprüfen Sie, ob die Spaltennamen der Kopfzeile korrekt sind."])},
  "incorrect_header_check_columns_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falscher Header. Bitte überprüfen Sie, ob die Spaltennamen der Kopfzeile korrekt sind."])},
  "incorrect_header_check_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falscher Header. Bitte überprüfen Sie, ob die Anzahl der Kopfspalten korrekt ist."])},
  "incorrect_number_of_columns_check_correctness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anzahl von Spalten. Bitte überprüfen Sie, ob die Spalten korrekt sind."])},
  "incorrect_number_of_columns_check_missing_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anzahl von Spalten. Bitte überprüfen Sie, ob ein Feld fehlt."])},
  "incorrect_number_of_columns_check_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anzahl von Spalten. Bitte überprüfen Sie, ob die Spaltenanzahl korrekt ist."])},
  "incorrect_date_hour_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Datum/Uhrzeit Format, das Format muss 'JJJJ-MM-TT hh:mm' sein. Bitte überprüfen Sie Ihre Datei."])},
  "incorrect_date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Datumsformat, das Format muss 'JJJJ-MM-TT hh:mm' sein. Bitte überprüfen Sie Ihre Datei."])},
  "incorrect_time_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Zeitformat, das Format muss 'JJJJ-MM-TT hh:mm' sein. Bitte überprüfen Sie Ihre Datei."])},
  "something_wrong_with_dates_check_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas mit den Datumswerten stimmt nicht, das Format mus 'JJJJ-MM-TT hh:mm' sein. Bitte überprüfen Sie Ihre Datei auf Leerzeichen oder andere Probleme."])},
  "quantity_must_be_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mengenfeld muss ein ganzzahliger Wert sein."])},
  "impossible_dependencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzulässige Abhängigkeiten gefunden. Einige Aufträge hängen von anderen Aufträgen ab, die nicht in der Auftragsdatei aufgeführt sind."])},
  "empty_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabedaten scheinen falsch oder leer zu sein, bitte überprüfen Sie es!"])},
  "wrong_number_of_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anzahl von Feldern. Bitte überprüfen Sie, ob mehr als nötig vorhanden ist."])},
  "wrong_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anzahl von Spalten. Bitte überprüfen Sie, ob die Header-Spalten korrekt sind."])},
  "incorrect_day_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Wochentagsformat. Die einzigen zulässigen Codes sind ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']."])},
  "incorrect_hour_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Stundenformat. Bitte überprüfen und beheben Sie es."])},
  "all_should_be_filled_or_none_should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Felder 'Start' und 'Ende' sollten entweder ALLE oder KEINES ausgefüllt sein."])},
  "operation_with_unregistered_production_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgang mit unbekanntem Fertigungsauftrag in Auftragsdatei gefunden! Bitte registrieren Sie die Fertigungsauftragsnummer in der Auftragsdatei."])},
  "operation_with_unregistered_resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgang mit unbekannter Ressource! Bitte deklarieren Sie die zu verwendenden Ressourcen in der Datei Resources!"])},
  "setup_time_must_be_float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Feldwert für die Rüstzeit sollte eine Zahl mit Dezimalpunkt sein."])},
  "operation_time_must_be_float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Feldwert für die Arbeitszeit sollte eine Zahl mit Dezimalpunkt sein."])},
  "prompt_dates_earlier_than_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei einigen Daten wurde festgestellt, dass sie in der Vergangenheit liegen. Das Gantt-Diagramm kann nicht gezeichnet werden, und die Metriken werden nicht berechnet. Möchten Sie die Aufträge mit HEUTE als Bezugspunkt in die Zukunft planen?"])},
  "unused_declared_production_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Auftragsdatei wurde eine Fertigungsauftragsnummer angegeben, aber es gibt keinen Arbeitsgang mit dieser Auftragsnummer in der Arbeitsgangdatei!"])},
  "operations_where_found_overlap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden sich überschneidende Arbeitsgänge gefunden"])},
  "hide_show_resources_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressourcen ausblenden/anzeigen"])},
  "hide_show_time_axis_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitachse ausblenden/anzeigen"])},
  "sort_by_order_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach Reihenfolge"])},
  "expand_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramm erweitern"])},
  "link_top_and_bottom_charts_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung von oberem und unterem Diagramm"])},
  "restart_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme neu zeichnen"])},
  "turn_grid_on_off_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitter ein-/ausschalten"])},
  "turn_shifts_on_off_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schichten ein-/ausschalten"])},
  "activate_global_scaling_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Skalierung aktivieren"])},
  "snap_data_on_viewport_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten im Ansichtsfenster fangen"])},
  "exit_snap_data_on_viewport_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fangen der Daten im Ansichtsfenster beenden"])},
  "decrease_chart_scale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrammskala verkleinern"])},
  "increase_chart_scale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrammskala vergrößern"])},
  "walk_left_on_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach links bewegen"])},
  "walk_right_on_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach rechts bewegen"])},
  "data_management_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATENVERWALTUNG"])},
  "original_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten im Original beibehalten"])},
  "backward_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückwärtsverteilung"])},
  "forward_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorwärtsverteilung"])},
  "original_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten Sie die Startdaten der Arbeitsgänge wie in den CSV-Dateien bei und richten Sie die diese lediglich innerhalb der verfügbaren Schichten aus"])},
  "original_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies hält die Daten am nächsten an der ursprünglichen Eingabe"])},
  "backward_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legt die Endtermine der Aufträge anhand der bereitgestellten Arbeitsgangdaten in den CSV-Dateien fest. Dies geschieht, indem das Enddatum des letzten Vorgangs eines Auftrags gleich dem Enddatum des Auftrags gesetzt wird. Behält die Starttermine der anderen Vorgänge bei, die in CSV-Dateien bereitgestellt werden. Nach diesem Schritt werden die Vorgänge ab heute auf die verfügbaren Schichten verteilt"])},
  "backward_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das realistischste Ergebnis, unter der Annahme, dass die Produktion heute beginnen würde"])},
  "forward_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilt die Vorgänge auf die verfügbaren Schichten, wobei die Eingangsreihenfolge aus den CSV-Dateien verwendet wird"])},
  "forward_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies simuliert ein Szenario, in dem die Arbeitsgänge ausgeführt werden, so wie sie in das System eingespeist werden"])}
}