<template>
  <Dialog
    :header="headerText"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
    class="cutted-trinity-variable"
    style="--notchSize: 15px; opacity: 0.9"
  >
    <div class="confirmation-content p-d-flex p-flex-row">
      <i :class="confirmationContentIcon" style="font-size: 2rem;" />
      <span style="word-wrap: break-word;">
        {{ bodyTextLeftHalf }}
        <b v-if="specialContentText != null" style="color: limegreen">{{
          specialContentText
        }}</b>
        {{ bodyTextRightHalf }}</span
      >
    </div>
    <template #footer>
      <Button
        :label="cancelButtonLabel"
        :icon="cancelButtonIcon"
        @click="closeConfirmation"
        :class="cancelButtonClass"
      />
      <Button
        v-if="mode == 'prompt'"
        :label="confirmButtonLabel"
        :icon="confirmButtonIcon"
        @click="confirm"
        :class="confirmButtonClass"
      />
    </template>
  </Dialog>
</template>

<script>
// import AxxalonTooltip from "@/components/Axxalon/AxxalonTooltip.vue";
export default {
  props: {
    mode: { type: String, default: "prompt" },
    showConfirmDialog: { type: Boolean, default: false },
    bodyTextLeftHalf: { type: String, default: "Some content applies over" },
    specialContentText: { type: String, default: " a special value" },
    bodyTextRightHalf: { type: String, default: " and finishes with this." },
    headerText: { type: String, default: "HEADER" },
    mainButtonClass: {
      type: String,
      default: "control-button-wide cutted-b-right p-ml-2"
    },
    mainButtonIcon: { type: String },
    mainButtonLabel: { type: String },
    cancelButtonClass: { type: String, default: "p-button-text" },
    cancelButtonIcon: { type: String, default: "pi pi-times" },
    cancelButtonLabel: { type: String, default: "No" },
    confirmButtonClass: { type: String, default: "p-button-text" },
    confirmButtonIcon: { type: String, default: "pi pi-check" },
    confirmButtonLabel: { type: String, default: "Yes" },
    confirmationContentIcon: {
      type: String,
      default: "pi pi-exclamation-triangle p-mr-3"
    },
    tooltip: { type: Boolean, default: false },
    tooltipArray: { type: Array, default: new Array("", "") },
    tooltipSwitchKey: { type: Boolean },
    tooltipDirection: { type: String, default: "bottom" },
    disableMainButtonSwitchKey: { type: Boolean },
    codeToRunInsideIfConfirmed: { type: Function, default: null },
    codeToRunInsideIfCanceled: { type: Function, default: null }
  },
  data() {
    return {
      displayConfirmation: false
    };
  },
  components: {
    // AxxalonTooltip
  },
  watch: {
    showConfirmDialog: function() {
      this.displayConfirmation = true;
    }
  },
  methods: {
    openConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
      if (this.codeToRunInsideIfCanceled != null) {
        this.codeToRunInsideIfCanceled();
      }
    },
    confirm() {
      this.codeToRunInsideIfConfirmed();
      this.displayConfirmation = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
