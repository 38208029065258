<template>
  <div class="card summary dark-panel">
    <div class="main-container">
      <slot class="main-image" style="width: 100px;"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
// .main-container {
//   width: 100%;
//   margin: 0;
// }
// .main-image {
//   object-fit: fill;
//   width: 100vw;
//   height: 80vh;
// }
</style>
