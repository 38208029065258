export default {
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign in"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
  "subtitle_powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["powered by "])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "heuristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heuristics"])},
  "users_total_runs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users total runs"])},
  "lead_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lead time reduction"])},
  "lead_dash_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead-Time Reduction"])},
  "avg_lead_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avg. lead time reduction"])},
  "avg_lead_dash_time_reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. Lead-Time Reduction"])},
  "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery"])},
  "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["without"])},
  "with_axxalon_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with axxalon-plan"])},
  "sign_in_with_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign in with credentials"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "dont_have_account_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["don't have an account?"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "register_for_the_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register for the experience"])},
  "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["already have an account?"])},
  "we_accelerate_your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we accelerate your business"])},
  "field_cannot_be_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field cannot be empty!"])},
  "not_valid_number_for_time_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not a valid number for Time Window"])},
  "please_insert_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please insert a valid email!"])},
  "the_filename_cannot_contain_white_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the filename cannot contain white spaces"])},
  "please_do_not_use_dots_or_file_termination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please do not add '.' or file type termination."])},
  "the_password_need_at_least_eigth_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the password should have at least 8 characters!"])},
  "run_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Optimization"])},
  "a_optimization_for_the_selected_file_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A optimization for the selected file already exist!"])},
  "please_select_or_import_csv_file_to_run_a_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select or import CSV file to run a optimization"])},
  "enable_gpu_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable GPU processing"])},
  "gpu_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPU not available"])},
  "select_a_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a optimization"])},
  "no_optimizations_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No optimizations available"])},
  "select_imported_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select imported CSV"])},
  "no_files_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No files imported"])},
  "are_you_sure_you_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])},
  "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete File"])},
  "delete_selected_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected file"])},
  "no_csv_file_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No CSV file selected"])},
  "system_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Language"])},
  "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV file"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "only_csv_files_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only CSV files are allowed!"])},
  "csv_file_is_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CSV file exceed the limit of size of 500Kb."])},
  "import_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import CSV file"])},
  "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV FILES WIZARD"])},
  "jobs_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOBS"])},
  "shifts_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHIFTS"])},
  "resources_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESOURCES"])},
  "operations_step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPERATIONS"])},
  "welcome_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! This wizard will guide you through the process of loading the CSV files correctly, please follow the steps and check if all files are in the valid format."])},
  "first_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First you need to import the Jobs CSV file. Please create and load a file following the template below."])},
  "first_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Jobs file was correctly validated! Now you can move to the next step."])},
  "first_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file was not validated! Please check if the input file follows correctly the template and if all data is correct."])},
  "second_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second you need to import the Shifts CSV file. Please create and load a file following the template below."])},
  "second_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Shifts file was correctly validated! Now you can move to the next step."])},
  "second_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file was not validated! Please check if the input file follows correctly the template and if all data is correct."])},
  "third_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now please import the Resources CSV file. Please create and load a file following the template below."])},
  "third_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Resources file was correctly validated! Now you can move to the next step."])},
  "third_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file was not validated! Please check if the input file follows correctly the template and if all data is correct."])},
  "fourth_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And finally import the Operations CSV file. Please create and load a file following the template below."])},
  "fourth_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All files were successfully uploaded and validated! Now you can finish the wizard and wait for the files to be processed. Please give a name for the file compound."])},
  "fourth_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file was not validated! Please check if the input file follows correctly the template and if all data is correct."])},
  "fifth_panel_message_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time overlaps founded! Please note that it may cause shorter lead times for the raw data and incorrect calculations of the metrics."])},
  "fifth_panel_message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All files were successfully uploaded and validated! Now you can finish the wizard and wait for the files to be processed."])},
  "compound_filename_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE GIVE A NAME FOR THE FILE COMPOUND"])},
  "name_for_the_files_compound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name for the files compound"])},
  "time_window_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIME WINDOW"])},
  "time_window_explanation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFTER THE OPTIMIZATION, THE OPERATIONS THAT"])},
  "time_window_explanation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENDS"])},
  "time_window_explanation_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFTER THE TIME WINDOW ARE"])},
  "time_window_explanation_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMOVED"])},
  "time_window_explanation_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FROM THE FINAL RESULT"])},
  "time_window_observation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERVE THAT THE RED OPERATIONS REACH THE THRESHOLD AND WILL BE DISCARTED"])},
  "number_of_days_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of DAYS"])},
  "generate_file_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERATING FILE, PLEASE WAIT..."])},
  "step_button_names_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "step_button_names_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "next_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "finish_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
  "shift_times_into_the_future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHIFT TIMES INTO THE FUTURE"])},
  "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint"])},
  "we_recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your dataset is have it's operation with very precise start and end dates we recommend to keep the data as original, otherwise the best alternative would be to use the Backwards distribution followed by the Forward distribution as last option."])},
  "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADVANCED MODE"])},
  "simple_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMPLE MODE"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAW"])},
  "optimized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIMIZED"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA"])},
  "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPERATIONS"])},
  "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNITS"])},
  "lead_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEAD TIME"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAYS"])},
  "avg_lead_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG. LEAD TIME"])},
  "units_explanation_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number might differ from the original number of operations because axxalon plan split operations across shifts, the result of this process often can result more or less execution blocks."])},
  "no_datetime_warning_piece_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
  "no_datetime_warning_piece_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START"])},
  "no_datetime_warning_piece_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AND"])},
  "no_datetime_warning_piece_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["END"])},
  "no_datetime_warning_piece_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATES WHERE FOUND TO GENERATE THE GANTT CHART, OR DATES ARE"])},
  "no_datetime_warning_piece_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EARLIER THAN TODAY"])},
  "no_metrics_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BY THE SAME REASON THE METRICS CANNOT BE CALCULATED, BUT YOU CAN STILL RUN THE OPTIMIZATION."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "search_tutorial_piece_A_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSERT"])},
  "search_tutorial_piece_A_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTION_ORDER_NUMBER"])},
  "search_tutorial_piece_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO FIND ALL OPERATIONS FROM A JOB."])},
  "search_tutorial_observation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THIS WILL HIGHLIGHT ALL OPERATIONS THAT BELONGS TO JOB 444"])},
  "search_tutorial_piece_B_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSERT"])},
  "search_tutorial_piece_B_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTION_ORDER_NUMBER"])},
  "search_tutorial_piece_B_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPERATION_SEQUENCE_NUMBER"])},
  "search_tutorial_piece_B_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO"])},
  "search_tutorial_piece_B_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIND A SPECIFIC OPERATION."])},
  "search_tutorial_observation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THIS WILL HIGHLIGHT THE SPECIFIC 444#10 OPERATION"])},
  "viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIEWPORT"])},
  "viewport_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAYS"])},
  "delivery_reliability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DELIVERY RELIABILITY"])},
  "maximum_viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum viewport!"])},
  "minimum_viewport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum viewport!"])},
  "limit_achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit achieved!"])},
  "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search OP-JOB code"])},
  "time_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIME WINDOW"])},
  "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCALE"])},
  "email_already_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inserted email is already registered! Please choose another one"])},
  "no_optimization_for_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No optimizations for this file."])},
  "a_optimization_already_exists_for_this_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A optimization already exist for the selected CSV file."])},
  "empty_header_check_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Header. Please check if the Header column names are correct."])},
  "incorrect_header_check_columns_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Header. Please check if the Header column names are correct."])},
  "incorrect_header_check_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Header. Please check if the Header columns quantity are correct."])},
  "incorrect_number_of_columns_check_correctness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Number of Columns. Please check if the columns are correct."])},
  "incorrect_number_of_columns_check_missing_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Number of Columns. Please check if some field are missing."])},
  "incorrect_number_of_columns_check_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Number of Columns. Please check if the columns quantity are correct."])},
  "incorrect_date_hour_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Date Hour Format, the format must be 'yyyy-MM-dd hh:mm'. Please check your file."])},
  "incorrect_date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Date Format, the format must be 'yyyy-MM-dd hh:mm'. Please check your file."])},
  "incorrect_time_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Time Format, the format must be 'yyyy-MM-dd hh:mm'. Please check your file."])},
  "something_wrong_with_dates_check_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something wrong with the dates, the format must be 'yyyy-MM-dd hh:mm'. Please check for spaces or other problems in your file."])},
  "quantity_must_be_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity field must be an Integer value."])},
  "impossible_dependencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible dependencies founded. Some jobs depends on other jobs that are not listed in jobs file."])},
  "empty_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input data seems wrong or empty, please check it!"])},
  "wrong_number_of_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong number of fields. Please check if there is more than necessary."])},
  "wrong_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong Number of Columns. Please check if the Header columns are correct."])},
  "incorrect_day_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Day of Week Format. The only allowed codes are ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']."])},
  "incorrect_hour_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Hour Format. Please check and fix it."])},
  "all_should_be_filled_or_none_should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Start' and 'End' fields should ALL be filled or NONE should be filled."])},
  "operation_with_unregistered_production_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation with unregistered Production Order on jobs file encountered! Please register the Production Order Number in the jobs file."])},
  "operation_with_unregistered_resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation with unregistered Resource! Please declare the resources to be used!"])},
  "setup_time_must_be_float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Time field value should be a number with decimal point."])},
  "operation_time_must_be_float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Time field value should be a number with decimal point."])},
  "prompt_dates_earlier_than_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some dates where found to be earlier than today, the gantt chart cannot be rendered and the metrics will not be computed. Do you want to shift the dates into the future using TODAY as reference point?"])},
  "unused_declared_production_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Production Order number was declared in jobs file, but there is no operation using this Production Order in operations file!"])},
  "operations_where_found_overlap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations overlaps where found between"])},
  "hide_show_resources_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide/Show Resources"])},
  "hide_show_time_axis_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide/Show Time Axis"])},
  "sort_by_order_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by Order"])},
  "expand_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand Chart"])},
  "link_top_and_bottom_charts_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Top and Bottom Charts"])},
  "restart_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart Charts"])},
  "turn_grid_on_off_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn grid ON/OFF"])},
  "turn_shifts_on_off_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn Shifts ON/OFF"])},
  "activate_global_scaling_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Global Scaling"])},
  "snap_data_on_viewport_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snap Data on Viewport"])},
  "exit_snap_data_on_viewport_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Snaped Data"])},
  "decrease_chart_scale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease Chart Scale"])},
  "increase_chart_scale_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase Chart Scale"])},
  "walk_left_on_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walk Left on Chart"])},
  "walk_right_on_chart_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walk Right on Chart"])},
  "data_management_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA MANAGEMENT"])},
  "original_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Data as Original"])},
  "backward_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backward Distribution"])},
  "forward_distribution_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward Distribution"])},
  "original_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the operations start dates as in the CSV files, aligning the operations only within the available shifts"])},
  "original_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This keep the data closest to the original input"])},
  "backward_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the jobs end dates as the provided due dates in CSV files, this is done setting the end date of the last operation of a job equal to the job due date. Keeps the start dates from the other operations provided in CSV files. After this step the operations are distributed in the available shifts starting today"])},
  "backward_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most realistic result, assuming the production would start today"])},
  "forward_data_distribution_tutorial_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributes the operations into the available shifts, using the entrance order from the CSV files"])},
  "forward_data_distribution_tutorial_observation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This simulate the scenario where the operations are executed as it is fed into the system"])}
}