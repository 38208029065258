<template>
  <div class="p-d-flex p-flex-row">
    <div
      v-if="processing"
      :style="processing ? 'visibility: visible' : 'visibility: hidden'"
      class="animated-processing p-d-flex p-mr-2 p-as-center"
    >
      <span
        class="p-d-flex p-ai-center"
        style="color: grey; font-size: 12px; font-weight: bold;"
      >
        {{ overrideText ? overrideText : processingString }}
      </span>
    </div>
    <ProgressSpinner
      :style="{ width: inputWidth, height: inputHeight }"
      v-if="processing"
      class="p-d-flex p-ai-center p-mr-2 menubar-spinner animated-processing"
      strokeWidth="8"
      fill="#EEEEEE"
      animationDuration=".5s"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  props: {
    processing: { type: Boolean },
    overrideText: { type: String },
    inputWidth: { type: String },
    inputHeight: { type: String }
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      processingString: this.t("processing", {}, { locale: "de" }).toUpperCase()
    };
  },
  mounted() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.processingString = this.t(
      "processing",
      {},
      { locale: this.localLanguage }
    ).toUpperCase();
  },
  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");
    this.processingString = this.t(
      "processing",
      {},
      { locale: this.localLanguage }
    ).toUpperCase();
  }
};
</script>

<style lang="scss" scoped></style>
