import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("./components/Axxalon/AxxalonDashboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/:catchAll(.*)",
    component:Home
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("user");
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next("/");
  }
  next();
});

export default router;
