<template>
  <div class="p-d-flex p-flex-column p-jc-center no-caret p-mb-2">
    <p
      class="p-mt-4"
      :style="
        success == null
          ? 'font-size: 16px; color: #eb1d24'
          : warning
          ? 'font-size: 16px; color: goldenrod'
          : success
          ? 'font-size: 16px; color: limegreen'
          : 'font-size: 16px; color: deeppink'
      "
    >
      <b>
        {{ panelText }}
      </b>
    </p>
    <p
      v-if="errorHint"
      class="no-caret"
      :style="
        success == null
          ? 'font-size: 16px; color: #eb1d24'
          : warning
          ? 'font-size: 16px; color: goldenrod'
          : success
          ? 'font-size: 16px; color: limegreen'
          : 'font-size: 16px; color: deeppink'
      "
    >
      <b style="color: color: chocolate; margin: 0px">
        {{ hintText }}: <a style="color: black">{{ errorHint }}</a>
      </b>
    </p>
    <slot class="no-caret"></slot>
  </div>
</template>

<script>
export default {
  props: {
    panelText: { type: String },
    errorHint: { type: String },
    success: { type: Boolean, default: null },
    warning: { type: Boolean, default: null },
    hintText: { type: String }
  }
};
</script>

<style lang="scss" scoped></style>
