<template>
  <div class="layout-topbar nav-bar-color">
    <div
      class="layout-menu-button no-pointer-cursor no-caret"
      @click="onMenuToggle"
    >
      <!-- <img
        src="./assets/images/axxalon-plan-experience-white-fit.png"
        style="height: 25px; width: auto"
      /> -->
      <!-- <img
        class="no-caret"
        src="./assets/images/axxalon-plan-experience-blue-fit.png"
        style="height: 26px; width: auto"
      /> -->
      <img
        class="no-caret"
        src="./assets/images/axxalon-plan-experience-RED-LOGO-blue-fit.png"
        style="height: 30px; width: auto"
      />
    </div>
    <div class="layout-topbar-icons">
      <!-- <span class="layout-topbar-search">
				<InputText type="text" placeholder="Search" />
				<span class="layout-topbar-search-icon pi pi-search"></span>
			</span> -->
      <!-- <button class="p-link">
				<span class="layout-topbar-item-text">Events</span>
				<span class="layout-topbar-icon pi pi-calendar"></span>
				<span class="layout-topbar-badge">5</span>
			</button> -->
      <div class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-row">
          <!-- NOT CURRENTLY USED, CHECK THE NEED FOR NEXT VERSIONS  -->
          <!-- <span
            class="p-as-center no-caret p-mr-2"
            style="font-size: 8px; color: black"
            >{{ advancedMode ? modeStringArray[0] : modeStringArray[1] }}
          </span>
          <InputSwitch
            class="p-as-center no-caret"
            style="height:15px"
            v-model="advancedMode"
            @change="onModeChange"
          /> -->

          <!-- OLD CODE -->
          <!-- <button class="p-link">
          <span class="layout-topbar-item-text">Settings</span>
          <span class="layout-topbar-icon pi pi-cog top-bar-right-icons"></span>
        </button> -->
          <button class="p-link" @click="toggle">
            <span class="layout-topbar-item-text">User</span>
            <span
              class="layout-topbar-icon pi pi-user top-bar-right-icons "
            ></span>
          </button>
        </div>
      </div>

      <Menu ref="menu" :model="items" :popup="true" />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      modeStringArray: new Array(
        this.t("advanced_mode", {}, { locale: "de" }).toUpperCase(),
        this.t("simple_mode", {}, { locale: "de" }).toUpperCase()
      ),
      advancedMode: false,
      items: null
    };
  },
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },
  mounted() {
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });

    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.modeStringArray = new Array(
      this.t("advanced_mode", {}, { locale: this.localLanguage }).toUpperCase(),
      this.t("simple_mode", {}, { locale: this.localLanguage }).toUpperCase()
    );

    this.logoutMenuString = this.t(
      "logout",
      {},
      { locale: this.localLanguage }
    );

    this.items = [
      {
        label: this.t("logout", {}, { locale: this.localLanguage }),
        icon: "pi pi-sign-out",
        command: () => {
          this.emitter.emit("showGlass", false);
          this.$store.dispatch("logout", this.$router);
        }
      }
    ];
  },
  beforeUpdate() {
    this.localLanguage =
      localStorage.getItem("language") == null
        ? this.$store.state.language
        : localStorage.getItem("language");

    this.modeStringArray = new Array(
      this.t("advanced_mode", {}, { locale: this.localLanguage }).toUpperCase(),
      this.t("simple_mode", {}, { locale: this.localLanguage }).toUpperCase()
    );

    this.logoutMenuString = this.t(
      "logout",
      {},
      { locale: this.localLanguage }
    );

    this.items = [
      {
        label: this.t("logout", {}, { locale: this.localLanguage }),
        icon: "pi pi-sign-out",
        command: () => {
          this.emitter.emit("showGlass", false);
          this.$store.dispatch("logout", this.$router);
        }
      }
    ];
  },
  methods: {
    onModeChange() {
      this.$emit("modeChange", this.advancedMode);
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    }
  }
};
</script>

<style lang="scss">
.top-bar-right-icons {
  color: #25528a;
}
.nav-bar-color {
  background: linear-gradient(-90deg, #fcfcfc, #cdd6e5);
}
</style>
