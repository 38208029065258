<template>
  <div class="p-grid">
    <!-- <div class="p-d-flex p-d-row">

    </div> -->
    <div
      class="p-col-12 p-xl-6 p-lg-12 p-md-12 p-fluid"
      style="background-color: rgba(0,0,0,0)"
    >
      <div
        class="image-container no-caret p-ml-6 media-title-margin-top"
        style="margin-top: 20%"
      >
        <!-- <img
          class="no-drag brand-image first"
          alt="user header"
          src="../assets/images/axxalon-plan-experience.png"
        /> -->
        <img
          class="no-drag brand-image first"
          alt="user header"
          src="../assets/images/axxalon-plan-experience-red.png"
        />

        <div
          class="information-text no-caret p-d-flex p-flex-column p-jc-center second p-md-4 poweredby"
          style="width: 100%"
        >
          <span
            >{{
              capitalize(t("subtitle_powered", {}, { locale: localLanguage }))
            }}<b class="enhanced-red">Google OR-Tools</b></span
          >
          <span
            >{{ t("and", {}, { locale: localLanguage }) }}
            <b class="enhanced-red"
              >HyperIHDG
              {{
                capitalize(t("heuristics", {}, { locale: localLanguage }))
              }}</b
            ></span
          >
        </div>
      </div>
    </div>

    <div class="p-col-12 p-xl-6 p-lg-12 p-md-12 p-fluid">
      <div
        class="glass-panel p-d-flex"
        style="background-color: rgba(0,0,0,0); opacity: 0;"
      >
        <div
          class="p-grid p-d-flex p-as-center cutted-trinity-variable no-caret greensugar-gradient-minus90dg-transparent box-shadow-glass media-glass"
          style="width: 95%; height: 90%; --notchSize: 15px; margin-top: 20%; margin-left: -10%"
        >
          <div class="p-col-6 p-d-flex" style="background-color: rgba(0,0,0,0)">
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex p-flex-row">
                <div
                  class="p-col-1"
                  style="padding: 0px; background-color: rgba(0,0,0,0)"
                >
                  <div
                    class="animated-banner-1 p-d-flex p-ml-4 cutted-b-right-var p-jc-center"
                    style="--notchSize: 5px; font-weight: bold;  width: 30px; height:30px; opacity: 0.7 !important; background-color: white;"
                  >
                    <span class="p-as-center"><a>K</a>1</span>
                  </div>
                </div>
                <div
                  class="p-col-6 p-xl-4 p-lg-6 p-md-4 p-d-flex"
                  style="padding: 0px; background-color: rgba(0,0,0,0)"
                >
                  <div
                    class="animated-banner-2 p-d-flex cutted-t-right-var p-jc-center wrapper"
                    style="--notchSize: 5px; font-weight: bold; min-width: 180px !important;  width:200px; height:30px; opacity: 0.7 !important; background-color: white;"
                  >
                    <span
                      id="usersTotalRunsText"
                      class="animated-text-1 animated-text p-as-center p-ml-2"
                    ></span>
                  </div>
                  <div
                    class="animated-banner-3 p-d-flex cutted-t-right-var p-jc-center p-ml-2 p-mr-4 wrapper"
                    style="--notchSize: 5px; font-weight: bold;  width:80px; height:30px; opacity: 0.7 !important; background-color: white;"
                  >
                    <span class="p-as-center" id="runsCount"></span>
                  </div>
                </div>

                <div class="p-d-flex p-flex-column">
                  <div
                    class="p-col-1"
                    style="padding: 0px; background-color: rgba(0,0,0,0)"
                  >
                    <div
                      class="animated-banner-4 p-d-flex cutted-b-right-var p-jc-center"
                      style="--notchSize: 5px; font-weight: bold;  width: 30px; height:30px; opacity: 0.7 !important; background-color: white;"
                    >
                      <span class="p-as-center"><a>K</a>2</span>
                    </div>
                  </div>
                  <div
                    class="p-col-1 p-mt-2"
                    style="padding: 0px; background-color: rgba(0,0,0,0)"
                  >
                    <div
                      class="animated-banner-7 p-d-flex cutted-b-right-var p-jc-center"
                      style="--notchSize: 5px; font-weight: bold;  width: 30px; height:30px; opacity: 0.7 !important; background-color: white;"
                    >
                      <span class="p-as-center"><a>K</a>3</span>
                    </div>
                  </div>
                  <div
                    class="p-col-1 p-mt-2"
                    style="padding: 0px; background-color: rgba(0,0,0,0)"
                  >
                    <div
                      class="animated-banner-10 p-d-flex cutted-b-right-var p-jc-center"
                      style="--notchSize: 5px; font-weight: bold;  width: 30px; height:30px; opacity: 0.7 !important; background-color: white;"
                    >
                      <span class="p-as-center"><a>K</a>4</span>
                    </div>
                  </div>
                </div>

                <div
                  class="p-col-6 p-d-flex"
                  style="padding: 0px; background-color: rgba(0,0,0,0)"
                >
                  <div class="p-d-flex p-flex-column">
                    <div class="p-d-flex p-flex-row">
                      <div
                        class="animated-banner-5 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper media-animated-banners"
                        style="--notchSize: 5px; font-weight: bold;  width:250px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span
                          id="leadTimeReductionText"
                          class="animated-text-2 animated-text p-as-center"
                        ></span>
                      </div>
                      <div
                        class="animated-banner-6 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper"
                        style="--notchSize: 5px; font-weight: bold; width:60px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span class="p-as-center" id="leadTimeReduction"></span>
                      </div>
                    </div>

                    <div class="p-d-flex p-flex-row p-mt-2">
                      <div
                        class="animated-banner-8 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper"
                        style="--notchSize: 5px; font-weight: bold;  width:250px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span
                          id="avgLeadTimeReductionText"
                          class="animated-text-3 animated-text p-as-center"
                        ></span>
                      </div>
                      <div
                        class="animated-banner-9 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper"
                        style="--notchSize: 5px; font-weight: bold;  width:60px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span
                          class="p-as-center"
                          id="avgLeadTimeReduction"
                        ></span>
                      </div>
                    </div>
                    <div class="p-d-flex p-flex-row p-mt-2">
                      <div
                        class="animated-banner-11 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper"
                        style="--notchSize: 5px; font-weight: bold;  width:250px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span
                          id="deliveryText"
                          class="animated-text-4 animated-text p-as-center"
                        ></span>
                      </div>
                      <div
                        class="animated-banner-12 p-d-flex cutted-t-right-var p-jc-center p-ml-2 wrapper"
                        style="--notchSize: 5px; font-weight: bold;  width:60px; height:30px; opacity: 0.7 !important; background-color: white;"
                      >
                        <span class="p-as-center" id="delivery"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="p-d-flex p-flex-row p-jc-center media-kleingedruckt-chart-container"
                style="width: 100%"
              >
                <div
                  class="p-col-12 p-d-flex p-as-center p-mt-4 media-kleingedruckt-chart"
                  style="padding: 0px; margin-top: 0%; height: 80%; width: 100%; margin-left: 20%; background-color: rgba(0,0,0,0)"
                >
                  <Chart
                    class="animated-chart p-d-flex"
                    style="width: 100%;"
                    type="bar"
                    :data="multiAxisData"
                    :options="multiAxisOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin.js";
import APIService from "@/service/APIService";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();

    return { t, locale };
  },
  props: {
    language: { type: Object, required: true },
    languageCode: { type: String }
  },
  data() {
    return {
      globalTime: null,
      reanimate: false,
      globalAnimation: null,
      glassAnimationOnProgress: false,
      localLanguage: "de",
      showGlass: true,
      totalRuns: 0,
      axxalonPlanLeadTime: 0,
      axxalonPlanLeadTimeReduction: 0,
      axxalonPlanAvgLeadTime: 0,
      axxalonPlanAvgLeadTimeReduction: 0,
      axxalonPlanDeliveryReliability: 0,
      crudeLeadTime: 0,
      crudeAvgLeadTime: 0,
      crudeDeliveryReliability: 0,
      multiAxisData: null,
      multiAxisOptions: null,
      dummy: 0
    };
  },
  watch: {
    language: function(lang) {
      this.localLanguage = lang.code;
      this.multiAxisData = this.getChartData();
      this.multiAxisOptions = this.getChartOptions();
      this.refreshGlass();
    }
  },
  mounted() {
    this.globalTime = new Date();
    if (this.language[0] == null) {
      this.localLanguage = localStorage.getItem("language");
    } else {
      this.localLanguage = this.language[0].code;
    }

    APIService.api.getKleigedrucktData().then(response => {
      this.totalRuns = response.data.totalRuns;
      this.axxalonPlanLeadTime = response.data.axxalonPlanLeadTime;
      this.axxalonPlanLeadTimeReduction =
        response.data.axxalonPlanLeadTimeReduction;

      this.axxalonPlanAvgLeadTime = response.data.axxalonPlanAvgLeadTime;
      this.axxalonPlanAvgLeadTimeReduction =
        response.data.axxalonPlanAvgLeadTimeReduction;

      this.axxalonPlanDeliveryReliability =
        response.data.axxalonPlanDeliveryReliability;
      this.crudeLeadTime = response.data.crudeLeadTime;
      this.crudeAvgLeadTime = response.data.crudeAvgLeadTime;
      this.crudeDeliveryReliability = response.data.crudeDeliveryReliability;
      this.multiAxisData = this.getChartData();
      this.multiAxisOptions = this.getChartOptions();

      this.maxValue = 0;
      if (this.crudeLeadTime > this.axxalonPlanLeadTime) {
        this.maxValue = this.crudeLeadTime;
      } else {
        this.maxValue = this.axxalonPlanLeadTime;
      }

      this.animateLogo();
      this.animateGlassPanel();
    });
  },
  created() {
    // this.animateGlassPanel();
  },
  unmount() {
    let tl = gsap.timeline();
    tl.to(".first", {
      opacity: 0,
      duration: 1,
      ease: "Expo.easeOut"
    }).to(".second", { opacity: 0, duration: 2, ease: "Expo.easeOut" });
  },
  methods: {
    capitalize(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },
    getChartData() {
      return {
        labels: [
          this.capitalize(
            this.t("lead_time", {}, { locale: this.localLanguage })
          ),
          this.capitalize(
            this.t("avg_lead_time", {}, { locale: this.localLanguage })
          ),
          this.capitalize(
            this.t("delivery", {}, { locale: this.localLanguage })
          ) + " %"
        ],
        datasets: [
          {
            label: this.t(
              "without",
              {},
              { locale: this.localLanguage }
            ).toUpperCase(),
            backgroundColor: [
              "rgba(255,255,255, 0.75)",
              "rgba(255,255,255, 0.75)",
              "rgba(255,255,255, 0.75)"
            ],
            yAxisID: "y-axis-1",
            data: [
              this.crudeLeadTime.toFixed(2),
              this.crudeAvgLeadTime.toFixed(2),
              (this.crudeDeliveryReliability * 100).toFixed(2)
            ]
          },
          {
            label: this.t(
              "with_axxalon_plan",
              {},
              { locale: this.localLanguage }
            ).toUpperCase(),
            backgroundColor: [
              "rgba(43,154,243, 0.75)",
              "rgba(43,154,243, 0.75)",
              "rgba(43,154,243, 0.75)"
            ],
            yAxisID: "y-axis-1",
            data: [
              this.axxalonPlanLeadTime.toFixed(2),
              this.axxalonPlanAvgLeadTime.toFixed(2),
              (this.axxalonPlanDeliveryReliability * 100).toFixed(2)
            ]
          }
        ]
      };

      // return multiAxisData;
    },
    getChartOptions() {
      return {
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: true
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "left",
              id: "y-axis-1",
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                min: 0,
                max: this.maxValue
              }
            }
          ]
        }
      };

      // return multiAxisOptions;
    },
    animateLogo() {
      let tl = gsap.timeline();
      const logoSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed
        : 2.5;
      const subtitleSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed + 1
        : 3.5;

      tl.from(
        ".first",
        {
          css: { scale: 0.9, opacity: 0 },
          duration: 4,
          ease: "expo.out"
        },
        logoSpeed
      );
      tl.from(
        ".second",
        {
          x: 300,
          opacity: 0,
          duration: 1,
          ease: "Power1.easeInOut"
        },
        subtitleSpeed
      );
    },

    animateLettersOnly() {
      if (document.getElementById("usersTotalRunsText"))
        document.getElementById("usersTotalRunsText").innerHTML = "";

      if (document.getElementById("leadTimeReductionText"))
        document.getElementById("leadTimeReductionText").innerHTML = "";

      if (document.getElementById("avgLeadTimeReductionText"))
        document.getElementById("avgLeadTimeReductionText").innerHTML = "";

      if (document.getElementById("deliveryText"))
        document.getElementById("deliveryText").innerHTML = "";

      let totalRunsText =
        this.t("users_total_runs", {}, { locale: this.localLanguage }) + ": ";
      totalRunsText = totalRunsText.toLocaleUpperCase();

      let leadTimeReductionText =
        this.t("lead_time_reduction", {}, { locale: this.localLanguage }) +
        ": ";
      leadTimeReductionText = leadTimeReductionText.toLocaleUpperCase();

      let avgLeadTimeReductionText =
        this.t("avg_lead_time_reduction", {}, { locale: this.localLanguage }) +
        ": ";
      avgLeadTimeReductionText = avgLeadTimeReductionText.toLocaleUpperCase();

      let deliveryText =
        this.t("delivery", {}, { locale: this.localLanguage }) + ": ";
      deliveryText = deliveryText.toLocaleUpperCase();

      let endTime = new Date();
      var timeDiff = endTime - this.globalTime; //in ms
      // strip the ms
      timeDiff /= 1000;

      // get seconds
      var elapsedSeconds = Math.round(timeDiff);

      let startTime = 12.0 - elapsedSeconds;
      if (startTime < 0.0) startTime = 0.0;

      let tl = gsap.timeline();
      tl.add("glass")
        //////////////////////////////////
        // ANIMATING FIRST ROW LEFT SIDE
        .to(
          ".animated-text-1",
          {
            duration: 0.5,
            text: totalRunsText
          },
          startTime + 0.5
        )
        .to(
          ".animated-text-2",
          {
            duration: 0.5,
            text: leadTimeReductionText
          },
          startTime + 1.0
        )
        .to(
          ".animated-text-3",
          {
            duration: 0.5,
            text: avgLeadTimeReductionText
          },
          startTime + 1.5
        )
        .to(
          ".animated-text-4",
          {
            duration: 0.5,
            text: deliveryText
          },
          startTime + 2.0
        );

      // .from(
      //   ".animated-chart",
      //   {
      //     scaleX: 0,
      //     opacity: 0.0,
      //     duration: 0.7,
      //     ease: "Expo.easeOut"
      //   },
      //   delayTime + 9.0
      // );
    },
    refreshGlass() {
      if (document.getElementById("usersTotalRunsText"))
        document.getElementById("usersTotalRunsText").innerHTML = "";

      if (document.getElementById("leadTimeReductionText"))
        document.getElementById("leadTimeReductionText").innerHTML = "";

      if (document.getElementById("avgLeadTimeReductionText"))
        document.getElementById("avgLeadTimeReductionText").innerHTML = "";

      if (document.getElementById("deliveryText"))
        document.getElementById("deliveryText").innerHTML = "";

      let tl = gsap.timeline();
      tl.add("glass")
        .to(".glass-panel", {
          scaleY: 0,
          duration: 1.5,
          ease: "Expo.easeOut"
        })

        //////////////////////////////////
        // ANIMATING FIRST ROW LEFT SIDE
        .to(".animated-banner-1", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })
        .to(".animated-banner-2", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-3", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })
        //////////////////////////////////
        // ANIMATING FIRST ROW RIGHT SIDE
        .to(".animated-banner-4", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-5", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-6", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        ///////////////////////////////////
        // ANIMATING SECOND ROW RIGHT SIDE
        .to(".animated-banner-7", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-8", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-9", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        ///////////////////////////////////
        // ANIMATING THIRD ROW RIGHT SIDE
        .to(".animated-banner-10", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-11", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-banner-12", {
          scaleX: 0,
          opacity: 0,
          duration: 0,
          ease: "Expo.easeOut"
        })

        .to(".animated-chart", {
          scaleX: 0,
          opacity: 0.0,
          duration: 0,
          ease: "Expo.easeOut"
        })
        .then(() => {
          this.animateLettersOnly();
          // this.animateGlassPanel();
          tl.to(".glass-panel", {
            scaleY: 1,
            duration: 1.5,
            ease: "Expo.easeIn"
          })
            .to(".animated-banner-1", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })
            .to(".animated-banner-2", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-3", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-4", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-5", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-6", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-7", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-8", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-9", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            ///////////////////////////////////
            // ANIMATING THIRD ROW RIGHT SIDE
            .to(".animated-banner-10", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-11", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-banner-12", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            })

            .to(".animated-chart", {
              scaleX: 1,
              opacity: 1,
              duration: 0,
              ease: "Expo.easeIn"
            });
          // this.animateGlassPanel();
        });
    },
    animateGlassPanel() {
      this.glassAnimationOnProgress = true;

      const glassPanelSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed - 5
        : 5.0;

      const runsCountSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed - 5
        : 7.0;

      const leadTimeReductionSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed - 5
        : 9.0;

      const avgLeadTimeReductionSpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed - 5
        : 11.0;

      const deliverySpeed = this.$route.params.newSpeed
        ? this.$route.params.newSpeed - 5
        : 13.0;

      let runsCount = { val: 0 },
        runsNewVal = this.totalRuns;
      let leadTimeReduction = { val: 0 },
        leadTimeReductionNewVal = this.axxalonPlanLeadTimeReduction * 100;
      let avgLeadTimeReduction = { val: 0 },
        avgLeadTimeReductionNewVal = this.axxalonPlanAvgLeadTimeReduction * 100;
      let delivery = { val: 0 },
        deliveryNewVal = this.axxalonPlanDeliveryReliability * 100;

      if (document.getElementById("runsCount"))
        document.getElementById("runsCount").innerHTML = 0;

      if (document.getElementById("leadTimeReduction"))
        document.getElementById("leadTimeReduction").innerHTML = 0;

      if (document.getElementById("avgLeadTimeReduction"))
        document.getElementById("avgLeadTimeReduction").innerHTML = 0;

      if (document.getElementById("delivery"))
        document.getElementById("delivery").innerHTML = 0;

      gsap.registerPlugin(TextPlugin);
      let tl = gsap.timeline();

      let totalRunsText =
        this.t("users_total_runs", {}, { locale: this.localLanguage }) + ": ";
      totalRunsText = totalRunsText.toLocaleUpperCase();

      let leadTimeReductionText =
        this.t("lead_time_reduction", {}, { locale: this.localLanguage }) +
        ": ";
      leadTimeReductionText = leadTimeReductionText.toLocaleUpperCase();

      let avgLeadTimeReductionText =
        this.t("avg_lead_time_reduction", {}, { locale: this.localLanguage }) +
        ": ";
      avgLeadTimeReductionText = avgLeadTimeReductionText.toLocaleUpperCase();

      let deliveryText =
        this.t("delivery", {}, { locale: this.localLanguage }) + ": ";
      deliveryText = deliveryText.toLocaleUpperCase();

      tl.add("glass")
        .from(
          ".glass-panel",
          {
            scaleY: 0,
            duration: 1.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed
        )
        .to(
          ".glass-panel",
          {
            opacity: 1,
            duration: 1.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed
        )

        //////////////////////////////////
        // ANIMATING FIRST ROW LEFT SIDE
        .from(
          ".animated-banner-1",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 0.5
        )
        .from(
          ".animated-banner-2",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 1.0
        )

        .to(
          ".animated-text-1",
          {
            duration: 0.5,
            text: totalRunsText
          },
          glassPanelSpeed + 1.5
        )
        .from(
          ".animated-banner-3",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 1.5
        )

        .to(
          runsCount,
          1.5,
          {
            val: runsNewVal,
            // opacity: 1,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("runsCount"))
                document.getElementById(
                  "runsCount"
                ).innerHTML = runsCount.val.toFixed(0);
            }
          },
          runsCountSpeed
        )

        //////////////////////////////////
        // ANIMATING FIRST ROW RIGHT SIDE
        .from(
          ".animated-banner-4",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 2.0
        )

        .from(
          ".animated-banner-5",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 2.5
        )

        .to(
          ".animated-text-2",
          {
            duration: 0.5,
            text: leadTimeReductionText
          },
          glassPanelSpeed + 3.0
        )

        .from(
          ".animated-banner-6",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 3.5
        )

        .to(
          leadTimeReduction,
          1.5,
          {
            val: leadTimeReductionNewVal,
            // opacity: 1,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("leadTimeReduction"))
                document.getElementById("leadTimeReduction").innerHTML =
                  leadTimeReduction.val.toFixed(2) + "%";
            }
          },
          leadTimeReductionSpeed
        )

        ///////////////////////////////////
        // ANIMATING SECOND ROW RIGHT SIDE
        .from(
          ".animated-banner-7",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 4.0
        )

        .from(
          ".animated-banner-8",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 4.5
        )

        .to(
          ".animated-text-3",
          {
            duration: 0.5,
            text: avgLeadTimeReductionText
          },
          glassPanelSpeed + 5.0
        )

        .from(
          ".animated-banner-9",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 5.5
        )

        .to(
          avgLeadTimeReduction,
          1.5,
          {
            val: avgLeadTimeReductionNewVal,
            // opacity: 1,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("avgLeadTimeReduction"))
                document.getElementById("avgLeadTimeReduction").innerHTML =
                  avgLeadTimeReduction.val.toFixed(2) + "%";
            }
          },
          avgLeadTimeReductionSpeed
        )

        ///////////////////////////////////
        // ANIMATING THIRD ROW RIGHT SIDE
        .from(
          ".animated-banner-10",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 6.0
        )

        .from(
          ".animated-banner-11",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 6.5
        )

        .to(
          ".animated-text-4",
          {
            duration: 0.5,
            text: deliveryText
          },
          glassPanelSpeed + 7.0
        )

        .from(
          ".animated-banner-12",
          {
            scaleX: 0,
            opacity: 0,
            duration: 0.5,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 7.5
        )

        .to(
          delivery,
          1.5,
          {
            val: deliveryNewVal,
            // opacity: 1,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("delivery"))
                document.getElementById("delivery").innerHTML =
                  delivery.val.toFixed(2) + "%";
            }
          },
          deliverySpeed
        )
        .from(
          ".animated-chart",
          {
            scaleX: 0,
            opacity: 0.0,
            duration: 0.7,
            ease: "Expo.easeOut"
          },
          glassPanelSpeed + 9.0
        )
        .then(() => {
          // if (this.reanimate) {
          //   this.animateLettersOnly();
          //   this.multiAxisData = this.getChartData();
          //   this.multiAxisOptions = this.getChartOptions();
          //   this.reanimate = false;
          this.glassAnimationOnProgress = false;
          // }
        });

      // .fromTo(
      //   ".cursor",
      //   { autoAlpha: 0, x: -10 },
      //   {
      //     autoAlpha: 1,
      //     duration: 0.5,
      //     repeat: -1,
      //     ease: "SteppedEase.config(1)"
      //   }
      // );
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}
.animated-text {
  padding: 0 10px 0 0;
  margin: 0;
  color: dodgerblue;
}

.cursor {
  color: #ed8e1c;
  margin: 0;
  display: inline-block;
}
.main-column {
  height: 100%;
  width: 50%;
  outline: none;
}
.image-container {
  width: 75%;
  margin: 0 auto;
}
.brand-image {
  width: 100%;
  height: auto;
}
.enhanced {
  color: #f99d1b;
}

.enhanced-red {
  color: #ff494f;
}

.information-text {
  font-size: 25px;
  margin-left: 60px;
}
.experience-background-image {
  height: 100%;
  width: 100%;
}
</style>
